import React, { useMemo, FC } from 'react'
import { CreateUserForm } from './components/UserGeneralForm'
import { RoutedTabPane } from '@/components/RoutedTabs'
import { HeaderWithTabs } from '@/components/layouts/HeaderWithTabs'
import { useNavigate } from 'react-router'

export const CreateUserView: FC = () => {
  const nav = useNavigate()
  const tabs: RoutedTabPane[] = useMemo(
    () => [
      {
        name: 'General',
        path: '',
        render: () => <CreateUserForm onSaved={user => nav(`/users/${user.id}`)} />
      }
    ],
    [nav]
  )

  return <HeaderWithTabs header='New user' tabs={tabs} pathRoot='/users/new' fitToContainer={true} />
}
