import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  margin-bottom: 1em;
  display: flex;
  flex-flow: row wrap;

  .ant-btn {
    margin: 2px;
    min-width: 155px;
  }
`
