import React, { FC } from 'react'
import styled from 'styled-components'
import { ColumnConfig } from '../columns'

interface ValContainerProps {
  $errors: string[]
  $number: boolean
  $noValue: boolean
}

const ValContainer = styled.span<ValContainerProps>`
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  ${(props: ValContainerProps) => (props.$errors.length ? 'color: red;' : '')}
  ${(props: ValContainerProps) => (props.$number ? 'text-align: right' : '')}
  ${(props: ValContainerProps) => (props.$noValue ? 'opacity: 0.5;' : '')}
`

interface TextCellProps {
  value: string
  errors: string[]
  number: boolean
  noValue: boolean
}

export const TextCell = (props: TextCellProps) => {
  const { value, errors, noValue, number } = props
  const title = errors.length ? errors.join(' ') : value
  return (
    <ValContainer title={title} $noValue={noValue} $number={number} $errors={errors}>
      {value}
    </ValContainer>
  )
}

interface CellFormatterProps {
  value: string
  columnConfig: ColumnConfig
  errors: string[]
}

export const CellFormatter: FC<CellFormatterProps> = ({ value, columnConfig, errors }) => {
  const formattedValue = !value && errors.length ? errors.join(' ') : columnConfig.formatter ? columnConfig.formatter(value) : value
  return <TextCell value={formattedValue} errors={errors} number={Boolean(columnConfig.number)} noValue={value === ''} />
}
