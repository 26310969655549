import React, { FC } from 'react'
import { GetReminder_reminder } from '@/queries/_gen_/GetReminder'
import Container from '@/components/layouts/Container'
import { EventsHistory } from '@/components/history/EventsHistory'

export interface ReminderEventsTabProps {
  reminder: GetReminder_reminder
}

export const ReminderEventsTab: FC<ReminderEventsTabProps> = ({ reminder }) => (
  <Container $scrollable={true}>
    <EventsHistory reminderId={reminder.id} debtorId={reminder.debtor.id} />
  </Container>
)
