import CenterAlert from '@/components/CenterAlert'
import React from 'react'
import Fetch from '@/components/Fetch'
import Container from '@/components/layouts/Container'
import { GetInvoiceQuery } from '@/queries/invoices.queries'
import { GetInvoice, GetInvoiceVariables } from '@/queries/_gen_/GetInvoice'
import { FC } from 'react'
import { UpdateInvoiceForm } from './UpdateInvoiceForm'
import { RoutedTabPane } from '@/components/RoutedTabs'
import { InvoiceEventsTab } from './InvoiceEventsTab'
import { InvoiceViewLayout } from './InvoiceViewLayout'

export interface UpdateInvoiceViewProps {
  invoiceId: number
  pathRoot?: string
}

export const UpdateInvoiceView: FC<UpdateInvoiceViewProps> = ({ invoiceId, pathRoot }) => (
  <Fetch<GetInvoice, GetInvoiceVariables> query={GetInvoiceQuery} container={true} variables={{ id: invoiceId }}>
    {data => {
      const invoice = data.invoice
      if (invoice) {
        const tabs: RoutedTabPane[] = [
          {
            name: 'General',
            path: '',
            render: () => (
              <Container>
                <UpdateInvoiceForm invoice={invoice} />
              </Container>
            )
          },
          {
            name: 'History',
            path: '/history',
            render: () => <InvoiceEventsTab invoice={invoice} />
          }
        ]

        return <InvoiceViewLayout pathRoot={pathRoot || `/invoices/${invoiceId}`} header={invoice.document_number} tabs={tabs} />
      } else {
        return <CenterAlert type='error' message='Invoice not found :(' />
      }
    }}
  </Fetch>
)
