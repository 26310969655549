import React, { ReactNode, useCallback, useState, useMemo } from 'react'
import { DocumentNode } from 'graphql'
import { Select } from 'antd'
import { useQuery } from 'react-apollo'
import { SelectValue } from 'antd/lib/select'

export interface BaseItem {
  id: number
}

export interface BaseVariables {
  limit: number
  offset: number
  where: any
  order_by?: unknown
}

export interface BaseResult<T> {
  items: T[]
}

interface GraphqlSelectProps<T extends BaseItem, Variables extends BaseVariables> {
  query: DocumentNode
  renderLabel: (item: T) => ReactNode
  buildSearchVariables: (query: string) => Variables['where']
  maxResults?: number
  valueId?: number
  onChange: (item: T | null) => void
  orderBy?: Variables['order_by']
  className?: string
}

const { Option } = Select

export function GraphqlSelect<T extends BaseItem, Result extends BaseResult<T>, Variables extends BaseVariables>(
  props: GraphqlSelectProps<T, Variables>
): JSX.Element {
  const { query, renderLabel, buildSearchVariables, maxResults, valueId, onChange, orderBy, className } = props

  const [searchQuery, setSearchQuery] = useState('')

  const where = useMemo((): Variables['where'] => {
    const where = buildSearchVariables(searchQuery)
    if (valueId) {
      return {
        _or: [
          where,
          {
            id: { _eq: valueId }
          }
        ]
      }
    }
    return where
  }, [valueId, buildSearchVariables, searchQuery])

  const bag = useQuery<Result, Variables>(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where,
      offset: 0,
      limit: maxResults || 1000,
      order_by: orderBy
    } as Variables
  })

  const onChangeCb = useCallback(
    (chg: SelectValue) => {
      onChange(bag.data?.items.find(i => i.id === chg) || null)
    },
    [onChange, bag]
  )

  return (
    <Select
      onSearch={setSearchQuery}
      value={valueId}
      showSearch={true}
      onChange={onChangeCb}
      loading={bag.loading}
      filterOption={false}
      notFoundContent={searchQuery ? 'No items found' : 'No data'}
      placeholder={bag.error?.message}
      className={className}
    >
      {bag.data?.items.map(item => (
        <Option key={item.id} value={item.id}>
          {renderLabel(item)}
        </Option>
      ))}
    </Select>
  )
}
