import { Casecard } from '@/models/casecard'
import React from 'react'

export const casecardStatusRenderer = (_: string, record: Casecard): JSX.Element => {
  if (record.is_paid) {
    return <span style={{ color: 'green' }}>Paid</span>
  } else {
    return <span style={{ color: 'blue' }}>Unpaid</span>
  }
}
