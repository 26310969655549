import React, { FC, useCallback } from 'react'
import { GetEvents_items_comment } from '@/queries/_gen_/GetEvents'
import { useMutationWithErrorReporting } from '@/hooks/useMutationWithErrorReporting'
import { UpdateComment, UpdateCommentVariables } from '@/queries/_gen_/UpdateComment'
import { UpdateCommentMutation } from '@/queries/events.queries'
import { CommentForm } from './CommentForm'
import message from 'antd/lib/message'

interface UpdateCommentFormProps {
  comment: GetEvents_items_comment
  onSaved: () => void
  onCancel: () => void
}

export const UpdateCommentForm: FC<UpdateCommentFormProps> = props => {
  const { comment, onSaved, onCancel } = props

  const [updateComment, updateCommentResult] = useMutationWithErrorReporting<UpdateComment, UpdateCommentVariables>(UpdateCommentMutation)

  const onSubmit = useCallback(
    (value: string) => {
      updateComment({
        variables: {
          comment_id: comment.id,
          comment: value
        }
      }).then(() => {
        message.success('Comment updated.')
        onSaved()
      })
    },
    [onSaved, comment]
  )

  return (
    <CommentForm
      comment={comment.comment}
      onSubmit={onSubmit}
      onCancel={onCancel}
      loading={updateCommentResult.loading}
      disabled={updateCommentResult.loading}
    />
  )
}
