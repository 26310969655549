import React, { FC } from 'react'
import { FormikField } from './FormikField'

export interface FormikReadOnlyFieldProps {
  name: string
  label: string
  render?: (value: any, row: any) => JSX.Element | string
}

export const FormikReadOnlyField: FC<FormikReadOnlyFieldProps> = ({ name, label, render }) => (
  <FormikField name={name} label={label}>
    {({ field, form }) => (render ? render(field.value, form.values) : String(field.value))}
  </FormikField>
)
