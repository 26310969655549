import { ColumnType } from '@/models/import/xls'
import dateFnsParseDate from 'date-fns/parse'
import formatDate from 'date-fns/format'
import isDateValid from 'date-fns/isValid'
import * as yup from 'yup'
import Decimal from 'decimal.js'
import currencyCodes from 'currency-codes'
import { ImportContext } from '@/models/import/plan'

const dateFormats: string[] = ['yyyy-MM-dd', 'yyyy.MM.dd', 'yyyyMMdd', 'dd/MM/yyyy', 'MM/dd/yy', 'yyyy/MM/dd']

export const parseDate = (value: string): Date | null => {
  for (const dateFormat of dateFormats) {
    try {
      const parsed = dateFnsParseDate(value, dateFormat, new Date())
      if (isDateValid(parsed)) {
        return parsed
      }
    } catch (e) {
      /* */
    }
  }
  return null
}

const rePhoneNumber = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/

export const parseSeparatedItems = (value: string): string[] =>
  value
    .split(/[,;]/)
    .map(x => x.trim())
    .filter(x => !!x)

/* VALIDATORS */
type Validator = (value: string, context: ImportContext) => string[]
const dateValidator: Validator = value => (parseDate(value) ? [] : ['Invalid date. Should be something like: 2019-11-03'])
const amountValidator: Validator = value => {
  try {
    const d = new Decimal(value)
    return d.decimalPlaces() <= 2 ? [] : ['Must have no more than 2 decimal places.']
  } catch (e) {
    return ['Invalid amount. Should be something like: 42.01']
  }
}
const phoneValidator: Validator = value =>
  parseSeparatedItems(value)
    .map(value => (rePhoneNumber.test(value) ? null : `Invalid phone number "${value}". Should be something like: +37012345678`))
    .filter((x): x is string => !!x)
const emailValidator: Validator = value =>
  parseSeparatedItems(value)
    .map(v => (yup.string().email().isValidSync(v) ? null : `Invalid email address "${v}".`))
    .filter((x): x is string => !!x)
const currencyValidator: Validator = value =>
  currencyCodes.code(value.toUpperCase()) ? [] : ['Invalid currency code. Expected something like: "EUR" or "USD" or etc.']

const categoryValidator: Validator = (value, context) =>
  context.clientCategories.find(cat => cat.name === value)
    ? []
    : [`Category not found. Available categories: ${context.clientCategories.map(c => c.name).join(',')}`]

/* FORMATTERS */
type Formatter = (value: string) => string

const dateFormatter: Formatter = value => {
  const dateval = parseDate(value)
  return dateval ? formatDate(dateval, dateFormats[0]) : value
}

const amountFormatter: Formatter = value => {
  const num = Number(value)
  if (!isNaN(num)) {
    return num.toFixed(2)
  }
  return value
}

/* COLUMN CONFIG */

export interface ColumnConfig {
  minWidth?: number
  validators?: Validator[]
  formatter?: Formatter
  number?: boolean
  required?: boolean
  unique?: boolean
}

export const columnTypes: { [key in ColumnType]: ColumnConfig } = {
  [ColumnType.skip]: {},
  [ColumnType.documentNumber]: {
    required: true
  },
  [ColumnType.companyCode]: {},
  [ColumnType.companyName]: {
    required: true
  },
  [ColumnType.documentDate]: {
    validators: [dateValidator],
    formatter: dateFormatter,
    required: true
  },
  [ColumnType.dueDate]: {
    validators: [dateValidator],
    formatter: dateFormatter,
    required: true
  },
  [ColumnType.amountWithoutVat]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    number: true
  },
  [ColumnType.amountWithVat]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    number: true
  },
  [ColumnType.amountPaid]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    number: true
  },
  [ColumnType.vat]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    number: true
  },
  [ColumnType.fullAmount]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    number: true
  },
  [ColumnType.amountOutstanding]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    number: true,
    required: true
  },
  [ColumnType.email]: {
    validators: [emailValidator]
  },
  [ColumnType.email2]: {
    validators: [emailValidator]
  },
  [ColumnType.email3]: {
    validators: [emailValidator]
  },
  [ColumnType.phoneNumber]: {
    validators: [phoneValidator]
  },
  [ColumnType.phoneNumber2]: {
    validators: [phoneValidator]
  },
  [ColumnType.phoneNumber3]: {
    validators: [phoneValidator]
  },
  [ColumnType.address]: {},
  [ColumnType.currency]: {
    validators: [currencyValidator]
  },
  [ColumnType.customerCodeInClientSystem]: {},
  [ColumnType.linePrice]: {
    validators: [amountValidator],
    formatter: amountFormatter,
    number: true,
    required: true
  },
  [ColumnType.lineProductName]: {
    required: true
  },
  [ColumnType.lineQuantity]: {
    number: true
  },
  [ColumnType.lineProductId]: {
    required: true
  },
  [ColumnType.firstName]: {
    required: true
  },
  [ColumnType.lastName]: {
    required: true
  },
  [ColumnType.city]: {
    required: true
  },
  [ColumnType.country]: {
    required: true
  },
  [ColumnType.postCode]: {
    required: true
  },
  [ColumnType.invoiceExtra1]: {},
  [ColumnType.invoiceExtra2]: {},
  [ColumnType.invoiceExtra3]: {},
  [ColumnType.invoiceExtra4]: {},
  [ColumnType.debtorExtra1]: {},
  [ColumnType.debtorExtra2]: {},
  [ColumnType.debtorCategory]: {
    validators: [categoryValidator]
  }
}

export interface RowValues {
  [ColumnType.companyCode]: string | undefined
  [ColumnType.companyName]: string | undefined
  [ColumnType.documentDate]: Date | undefined
  [ColumnType.documentNumber]: string | undefined
  [ColumnType.dueDate]: Date | undefined
  [ColumnType.amountWithoutVat]: Decimal | undefined
  [ColumnType.amountWithVat]: Decimal | undefined
  [ColumnType.amountPaid]: Decimal | undefined
  [ColumnType.vat]: Decimal | undefined
  [ColumnType.amountOutstanding]: Decimal | undefined
  [ColumnType.email]: string | undefined
  [ColumnType.email2]: string | undefined
  [ColumnType.email3]: string | undefined
  [ColumnType.phoneNumber]: string | undefined
  [ColumnType.phoneNumber2]: string | undefined
  [ColumnType.phoneNumber3]: string | undefined
  [ColumnType.address]: string | undefined
  [ColumnType.currency]: string | undefined
  [ColumnType.customerCodeInClientSystem]: string | undefined
  [ColumnType.linePrice]: Decimal | undefined
  [ColumnType.lineQuantity]: Decimal | undefined
  [ColumnType.lineProductName]: string | undefined
  [ColumnType.lineProductId]: string | undefined
  [ColumnType.firstName]: string | undefined
  [ColumnType.lastName]: string | undefined
  [ColumnType.city]: string | undefined
  [ColumnType.country]: string | undefined
  [ColumnType.postCode]: string | undefined
  [ColumnType.invoiceExtra1]: string | undefined
  [ColumnType.invoiceExtra2]: string | undefined
  [ColumnType.invoiceExtra3]: string | undefined
  [ColumnType.invoiceExtra4]: string | undefined
  [ColumnType.debtorExtra1]: string | undefined
  [ColumnType.debtorExtra2]: string | undefined
  [ColumnType.debtorCategory]: string | undefined
  [ColumnType.fullAmount]: Decimal | undefined
}
