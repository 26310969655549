import { Invoice, InvoiceWithId } from '@/models/invoice'
import { Formik } from 'formik'
import React, { FC } from 'react'
import { Form } from '@/components/form/Form'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { FormikDateField } from '@/components/form/FormikDateField'
import { FormMutation } from '@/components/form/FormMutation'
import { UpdateInvoice, UpdateInvoiceVariables } from '@/queries/_gen_/UpdateInvoice'
import { UpdateInvoiceMutation } from '@/queries/invoices.queries'
import { FormikReadOnlyField } from '@/components/form/FormikReadOnlyField'
import { Debtor } from '@/models/debtor'
import { Link } from 'react-router-dom'
import { dateRenderer } from '@/components/table/renderers/dateRenderer'
import { amountRenderer } from '@/components/table/renderers/amountRenderer'
import { yesNoRenderer } from '@/components/table/renderers/yesNoRenderer'
import { FInvoiceFields_invoice_attachments } from '@/queries/_gen_/FInvoiceFields'

interface InvoiceFormProps {
  invoice: Invoice
  onSubmit: (invoice: Invoice) => void
  loading?: boolean
}

const InvoiceForm: FC<InvoiceFormProps> = ({ invoice, loading, onSubmit }) => (
  <Formik<Invoice> initialValues={invoice} onSubmit={onSubmit}>
    {({ dirty, setFieldValue }) => (
      <Form>
        <FormikReadOnlyField name='document_number' label='Document number' />
        <FormikReadOnlyField
          name='debtor'
          label='Company'
          render={(value: Debtor) => <Link to={`/debtors/${value.id}`}>{value.name}</Link>}
        />
        <FormikReadOnlyField name='document_date' label='Document date' render={dateRenderer} />
        <FormikReadOnlyField name='due_date' label='Due date' render={dateRenderer} />
        <FormikReadOnlyField name='amount_outstanding' label='Amount outstanding' render={amountRenderer} />
        <FormikReadOnlyField name='amount_paid' label='Amount paid' render={amountRenderer} />
        <FormikReadOnlyField name='is_paid' label='Is paid' render={yesNoRenderer} />
        <FormikDateField allowClear={true} name='suspend_reminders_until' label='Suspend reminders until' setFieldValue={setFieldValue} />
        <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
          Submit
        </FormSubmitButton>

        <FormikReadOnlyField
          name='invoice_attachments'
          label='Attachments'
          render={(attachments: FInvoiceFields_invoice_attachments[]) => (
            <ul>
              {attachments.map((attachment: FInvoiceFields_invoice_attachments) => (
                <li key={attachment.filename}>
                  <a
                    href={`${__CONFIG__.backendUri}/files/get/${attachment.folder}/${attachment.client_id}/${attachment.filename}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {attachment.orig_filename}
                  </a>
                </li>
              ))}
            </ul>
          )}
        />
      </Form>
    )}
  </Formik>
)

export interface UpdateInvoiceFormProps {
  invoice: InvoiceWithId
}

export const UpdateInvoiceForm: FC<UpdateInvoiceFormProps> = ({ invoice }) => (
  <FormMutation<Invoice, UpdateInvoice, UpdateInvoiceVariables>
    buildVariables={payload => ({
      id: invoice.id,
      payload: {
        suspend_reminders_until: payload.suspend_reminders_until
      }
    })}
    successMessage={data => (data.result && `Invoice ${data.result.returning[0].document_number} updated!`) || '???'}
    mutation={UpdateInvoiceMutation}
  >
    {({ onSubmit, result: { loading } }) => <InvoiceForm invoice={invoice} onSubmit={onSubmit} loading={loading} />}
  </FormMutation>
)
