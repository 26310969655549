import { useQueryParams } from './useQueryParams'
import * as qs from 'query-string'
import { useCallback } from 'react'

export function usePathWithParams(path: string, params?: Record<string, string | null>): string {
  const [existingParams] = useQueryParams()

  const paramsStr = qs.stringify({
    ...existingParams,
    ...(params || {})
  })

  return path + (paramsStr ? `?${paramsStr}` : '')
}

export function usePathWithParamsBuilder(): (path: string, params?: Record<string, string | null>) => string {
  const [existingParams] = useQueryParams()
  return useCallback(
    (path, params) => {
      const paramsStr = qs.stringify({
        ...existingParams,
        ...(params || {})
      })

      return path + (paramsStr ? `?${paramsStr}` : '')
    },
    [existingParams]
  )
}
