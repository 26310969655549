import React from 'react'
import { QueryResult } from 'react-apollo'
import Container from './layouts/Container'
import Spinner from './Spinner'
import Alert from 'antd/lib/alert'
import Loader from './Loader'

export interface QueryContainerProps<TResult> {
  queryResult: QueryResult<TResult, any>
  container?: boolean
  children: (data: TResult) => JSX.Element | null
  loader?: boolean
}

export function QueryContainer<TResult>(props: QueryContainerProps<TResult>) {
  const { children, container, queryResult, loader } = props

  const withContainer = (node: JSX.Element) => (container ? <Container>{node}</Container> : node)

  if (queryResult.loading && !queryResult.data) {
    return withContainer(loader ? <Loader /> : <Spinner size='large' />)
  } else if (queryResult.error) {
    return withContainer(<Alert type='error' message={queryResult.error.message} />)
  } else if (queryResult.data) {
    return children(queryResult.data)
  } else {
    return <></>
  }
}
