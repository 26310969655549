import React, { FC, useEffect, useMemo } from 'react'
import { Creditor, CreditorWithId } from '@/models/creditor'
import { Formik } from 'formik'
import { FormikField } from '@/components/form/FormikField'
import { InsertCreditorMutation, UpdateCreditorMutation } from '@/queries/creditors.queries'
import { InsertCreditor, InsertCreditorVariables } from '@/queries/_gen_/InsertCreditor'
import { FormMutation } from '@/components/form/FormMutation'
import { UpdateCreditor, UpdateCreditorVariables } from '@/queries/_gen_/UpdateCreditor'
import * as R from 'ramda'
import * as yup from 'yup'
import ApolloClient from 'apollo-client'
import { creditorUniquenessValidator } from '../creditorValidators'
import { Form } from '@/components/form/Form'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { CategorySelectFormikField } from '@/components/CategorySelect'
import { useApolloClient } from 'react-apollo'

interface CreditorFormProps {
  creditor: Creditor
  onSubmit: (values: Creditor) => void
  loading?: boolean
  btnLabel?: string
  readOnly?: boolean
}

const emptyCreditor: Creditor = {
  name: '',
  category_id: null
}

const validationSchema = (apollo: ApolloClient<any>, clientId: number, creditorId?: number) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Required!')
      .test(
        'name_unique',
        'Another creditor with this name already exists.',
        creditorUniquenessValidator(apollo, 'name', clientId, creditorId)
      )
  })

const CreditorForm: FC<CreditorFormProps> = ({ creditor, loading, onSubmit, btnLabel, readOnly }) => {
  const client = useCurrentClient()
  const apollo = useApolloClient()
  const vSchema = useMemo(() => validationSchema(apollo, client.id, creditor.id), [apollo, client.id, creditor.id])

  return (
    <Formik<Creditor> initialValues={creditor} onSubmit={onSubmit} validationSchema={vSchema}>
      {({ dirty }) => (
        <Form className='ant-form ant-form-vertical'>
          <FormikField name='name' label='Name' disabled={readOnly} />
          <CategorySelectFormikField name='category_id' allowClear={true} label='Category' clientId={client.id} disabled={readOnly} />
          {!readOnly && (
            <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
              {btnLabel || 'Submit'}
            </FormSubmitButton>
          )}
        </Form>
      )}
    </Formik>
  )
}

interface CreateCreditorFormProps {
  onSaved: (creditor: Creditor) => void
}

export const CreateCreditorForm: FC<CreateCreditorFormProps> = ({ onSaved }) => {
  const client = useCurrentClient()

  return (
    <FormMutation<Creditor, InsertCreditor, InsertCreditorVariables>
      buildVariables={payload => ({ payload: { ...payload, client_id: client.id } })}
      successMessage={data => (data.result && `Creditor ${data.result.returning[0].name} created!`) || '???'}
      mutation={InsertCreditorMutation}
      refetchQueries={['SearchCreditors']}
    >
      {({ onSubmit, result: { loading, data } }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (data && data.result && data.result.returning.length) {
            onSaved(data.result.returning[0])
          }
        }, [data])
        return <CreditorForm creditor={emptyCreditor} onSubmit={onSubmit} loading={loading} btnLabel='Create creditor' />
      }}
    </FormMutation>
  )
}

interface UpdateCreditorFormProps {
  creditor: CreditorWithId
}

export const UpdateCreditorForm: FC<UpdateCreditorFormProps> = ({ creditor }) => (
  <FormMutation<Creditor, UpdateCreditor, UpdateCreditorVariables>
    buildVariables={payload => ({ payload: R.omit(['id'], payload), id: creditor.id })}
    successMessage={data => (data.result && `Creditor ${data.result.returning[0].name} updated!`) || '???'}
    mutation={UpdateCreditorMutation}
    // onSaved={() => emitCreditorUpdated(creditor.id)}
    refetchQueries={['GetReminder', 'GetReminderStats']}
  >
    {({ onSubmit, result: { loading } }) => (
      <CreditorForm creditor={creditor} onSubmit={onSubmit} loading={loading} btnLabel='Update creditor' />
    )}
  </FormMutation>
)

export const ReadOnlyCreditorForm: FC<{ creditor: CreditorWithId }> = ({ creditor }) => (
  <CreditorForm
    creditor={creditor}
    onSubmit={() => {
      /* */
    }}
    readOnly={true}
  />
)
