import React, { FC } from 'react'
import { Form, FieldArray, Formik } from 'formik'
import { FormikField } from '../../form/FormikField'
import Select from 'antd/lib/select'
import Button from 'antd/lib/button'
import { Row, Col } from 'antd/lib/grid'
import styled from 'styled-components'
import { Column, columnNames, selectableColumns } from 'common/models/invoice'
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons'

const FormContainer = styled.div`
  .ant-col-10 + .ant-col-10,
  .ant-col-10 + .ant-col-4 {
    padding-left: 1em;
  }

  .ant-col .ant-form-item {
    margin-bottom: 1em;
  }
`

const FormLabel = styled.label`
  color: black !important;
  padding-bottom: 0.7em !important;
`

const { Option } = Select

const inititalColumns: Column[] = [Column.documentNumber, Column.invoiceDate, Column.dueDate, Column.amountOutstanding]

interface ValuesColumn {
  id: Column
  label: string
}

export interface Values {
  columns: ValuesColumn[]
  totalLabel: string
}

export const defaultValues: Values = {
  columns: inititalColumns.map(col => ({
    id: col,
    label: columnNames[col]
  })),
  totalLabel: 'Total'
}

interface Props {
  onChange: (values: Values) => void
}

export const InvoiceTableConfigForm: FC<Props> = ({ onChange }) => (
  <Formik<Values>
    initialValues={defaultValues}
    onSubmit={() => {
      /* */
    }}
  >
    {({ values, setFieldValue }) => {
      onChange(values) // lol!
      return (
        <Form>
          <FieldArray name='columns'>
            {arrayHelpers => (
              <FormContainer className='ant-form'>
                <Row>
                  <Col span={10}>
                    <FormLabel>Column:</FormLabel>
                  </Col>
                  <Col span={10}>
                    <FormLabel>Label:</FormLabel>
                  </Col>
                  <Col span={4} />
                </Row>
                {values.columns.map((_, idx) => (
                  <Row key={idx}>
                    <Col span={10}>
                      <FormikField name={`columns.${idx}.id`}>
                        {({ field }) => (
                          <Select<string>
                            value={field.value}
                            onChange={val => {
                              setFieldValue(`columns.${idx}.id` as 'columns', val)
                              setFieldValue(`columns.${idx}.label` as 'columns', columnNames[val as Column])
                            }}
                          >
                            {selectableColumns.map(c => (
                              <Option key={c} value={c}>
                                {columnNames[c]}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </FormikField>
                    </Col>
                    <Col span={10}>
                      <FormikField name={`columns.${idx}.label`} />
                    </Col>
                    <Col span={4}>
                      <Button
                        size='small'
                        title='remove'
                        onClick={() => arrayHelpers.remove(idx)}
                        type='link'
                        danger={true}
                        icon={<CloseCircleOutlined />}
                      />
                    </Col>
                  </Row>
                ))}
                <Button
                  icon={<PlusOutlined />}
                  title='Add column'
                  onClick={() => arrayHelpers.insert(values.columns.length, defaultValues.columns[0])}
                />
                <br />
                <br />
                <FormikField name='totalLabel' label='"Total" label' />
              </FormContainer>
            )}
          </FieldArray>
        </Form>
      )
    }}
  </Formik>
)
