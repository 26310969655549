import gql from 'graphql-tag'

export const RemindersTemplateFieldsFragment = gql`
  fragment FTemplateFields on template {
    client_id
    id
    language
    name
    type
    sms_content
    email_subject
    email_content
    reminder_content
    archived
    attachment_filename
  }
`

export const TemplateOptionFieldsFragment = gql`
  fragment FTemplateOptionFields on template {
    id
    name
    language
    type
  }
`

export const SearchRemindersTemplatesQuery = gql`
  query SearchTemplates($limit: Int!, $offset: Int!, $where: template_bool_exp!, $order_by: [template_order_by!]) {
    items: template(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
      ...FTemplateFields
    }
    total: template_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${RemindersTemplateFieldsFragment}
`

export const GetTemplateOptionsQuery = gql`
  query GetTemplateOptions($where: template_bool_exp!) {
    results: template(where: { _and: [$where, { archived: { _eq: false } }] }) {
      ...FTemplateOptionFields
    }
  }
  ${TemplateOptionFieldsFragment}
`

export const InsertTemplateMutation = gql`
  mutation InsertTemplate($payload: template_insert_input!) {
    result: insert_template(objects: [$payload]) {
      returning {
        ...FTemplateFields
      }
    }
  }

  ${RemindersTemplateFieldsFragment}
`

export const UpdateTemplateMutation = gql`
  mutation UpdateTemplate($id: Int!, $payload: template_set_input!) {
    result: update_template(_set: $payload, where: { id: { _eq: $id } }) {
      returning {
        ...FTemplateFields
      }
    }
  }

  ${RemindersTemplateFieldsFragment}
`

export const GetTemplateQuery = gql`
  query GetTemplate($id: Int!) {
    template: template_by_pk(id: $id) {
      ...FTemplateFields
    }
  }
  ${RemindersTemplateFieldsFragment}
`
