import React, { FC, useMemo } from 'react'
import Container from '@/components/layouts/Container'
import { RoutedTabPane } from '@/components/RoutedTabs'
import { useNavigate } from 'react-router'
import { CreateCasecardForm } from './CasecardGeneralForm'
import { CasecardViewLayout } from './CasecardViewLayout'

export interface CreateCasecardViewProps {
  pathRoot?: string
}

export const CreateCasecardView: FC<CreateCasecardViewProps> = ({}) => {
  const nav = useNavigate()
  const tabs: RoutedTabPane[] = useMemo(
    () => [
      {
        name: 'General',
        path: '',
        render: () => (
          <Container>
            <CreateCasecardForm onSaved={casecard => nav(`/casecards/${casecard.id}`)} />
          </Container>
        )
      }
    ],
    [nav]
  )

  return <CasecardViewLayout header='New Case Card' tabs={tabs} pathRoot='/casecards/new' />
}
