import React, { FC, ReactNode, useMemo, useCallback } from 'react'
import { GetReminder_reminder } from '@/queries/_gen_/GetReminder'
import Button from 'antd/lib/button'
import { CancelReminder, CancelReminderVariables } from '@/queries/_gen_/CancelReminder'
import { CancelReminderMutation, MarkReminderAsSentMutation } from '@/queries/reminders.queries'
import { useCurrentUserId } from '@/hooks/useCurrentUsername'
import { ReminderStatus, autoSendableReminderTypes, ReminderType, reminderType2ContactType, reminderTypesWithPDF } from '@/models/reminders'
import { MarkReminderAsSent, MarkReminderAsSentVariables } from '@/queries/_gen_/MarkReminderAsSent'
import { useMutationWithErrorReporting } from '@/hooks/useMutationWithErrorReporting'
import { ButtonsContainer } from '@/components/ButtonsContainer'
import { useSendReminders } from '@/views/reminders/hooks/useSendReminders'
import { StopOutlined, CheckOutlined, RightSquareOutlined, FilePdfOutlined, MailOutlined, MessageOutlined } from '@ant-design/icons'
import { useRole } from '@/hooks/useRole'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { Folder } from 'common/models/files'

export interface ReminderActionButtonsProps {
  reminder: GetReminder_reminder
}
export const ReminderActionButtons: FC<ReminderActionButtonsProps> = props => {
  const { reminder } = props

  const client = useCurrentClient()

  const contactType = reminderType2ContactType[reminder.type as ReminderType]

  const contacts: Array<{ value: string; description: string | null }> = useMemo(
    () => reminder.debtor.debtor_contacts.filter(contact => contact.type === contactType),
    [reminder, contactType]
  )

  const buttons: ReactNode[] = []

  const [cancelReminder, cancelReminderResult] = useMutationWithErrorReporting<CancelReminder, CancelReminderVariables>(
    CancelReminderMutation
  )
  const [markReminderAsSent, markReminderAsSentResult] = useMutationWithErrorReporting<MarkReminderAsSent, MarkReminderAsSentVariables>(
    MarkReminderAsSentMutation
  )

  const loading = cancelReminderResult.loading || markReminderAsSentResult.loading

  const { sendReminders } = useSendReminders()

  const user = useCurrentUserId()

  const { isManager } = useRole()

  const send = useCallback(() => {
    if (reminder.status === ReminderStatus.new || confirm('Are you sure you want to re-send this reminder?')) {
      sendReminders({ id: { _eq: reminder.id } }, 1)
    }
  }, [reminder, sendReminders])
  if (reminder.status === ReminderStatus.new && isManager) {
    buttons.push(
      <Button
        loading={loading}
        disabled={loading}
        icon={<StopOutlined />}
        key='cancel'
        onClick={() => cancelReminder({ variables: { reminder_id: reminder.id, user } })}
      >
        Cancel reminder
      </Button>
    )
  }

  if (!autoSendableReminderTypes.includes(reminder.type as ReminderType) && reminder.status !== ReminderStatus.sent && isManager) {
    buttons.push(
      <Button
        loading={loading}
        disabled={loading}
        icon={<CheckOutlined />}
        key='mark_sent'
        onClick={() => markReminderAsSent({ variables: { reminder_id: reminder.id, user } })}
      >
        Mark as sent
      </Button>
    )
  }

  if (autoSendableReminderTypes.includes(reminder.type as ReminderType) && isManager) {
    buttons.push(
      <Button
        icon={<RightSquareOutlined />}
        loading={reminder.status === ReminderStatus.queued || loading}
        disabled={reminder.status === ReminderStatus.queued || loading || !contacts.length}
        onClick={send}
        key='send_reminder'
      >
        Send reminder
      </Button>
    )
  }

  if (reminder.type !== ReminderType.phone) {
    if (reminderTypesWithPDF.includes(reminder.type as ReminderType)) {
      if (reminder.status === ReminderStatus.sent) {
        buttons.push(
          <Button
            icon={<FilePdfOutlined />}
            href={`${__CONFIG__.backendUri}/files/get/${Folder.reminders}/${client.id}/${reminder.debtor.id}-${reminder.id}.pdf`}
            target='_blank'
            key='download_pdf'
          >
            Download PDF
          </Button>
        )
      } else {
        buttons.push(
          <Button
            icon={<FilePdfOutlined />}
            href={`${__CONFIG__.backendUri}/reminder/gen/${reminder.id}/pdf`}
            target='_blank'
            key='preview_pdf'
          >
            Preview PDF
          </Button>
        )
      }
    }
    if (reminder.type === ReminderType.email) {
      buttons.push(
        <Button
          icon={<MailOutlined />}
          href={`${__CONFIG__.backendUri}/reminder/gen/${reminder.id}/email`}
          target='_blank'
          key='preview_email'
        >
          Preview email
        </Button>
      )
    }
    if (reminder.type === ReminderType.sms) {
      buttons.push(
        <Button
          icon={<MessageOutlined />}
          href={`${__CONFIG__.backendUri}/reminder/gen/${reminder.id}/sms`}
          target='_blank'
          key='preview_sms'
        >
          Preview SMS
        </Button>
      )
    }
  }

  if (buttons.length) {
    return <ButtonsContainer>{buttons}</ButtonsContainer>
  } else {
    return null
  }
}
