import neatCsv from 'neat-csv'
import { UsePromiseValue } from '../usePromise'
import { useMemo } from 'react'
import { usePromise } from '@/hooks/usePromise'
import { logger } from '@/logger'
import Decimal from 'decimal.js'
import currencyData from 'currency-codes/data'
import { parseDate } from '@/views/import/columns'
import formatDate from 'date-fns/format'

export interface PaymentRow {
  currency: string
  date: string
  iban: string
  amount: Decimal
  from: string
  reason: string
}

type Payments = PaymentRow[]

export function useParsePaymentsCSV(file?: File): UsePromiseValue<Payments, Error> {
  const promise: Promise<Payments> | null = useMemo(() => {
    if (file) {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target) {
            resolve(e.target.result as string)
          }
        }
        reader.onerror = (e: ProgressEvent<FileReader>) => {
          logger.error(`Error reading payments csv file, ${e.target}`)
          reject(e)
        }
        setTimeout(() => reader.readAsText(file, 'windows-1250'))
      })
        .then(data =>
          neatCsv(data, {
            separator: ';'
          })
        )
        .then(data => {
          data = data.slice(0, data.length - 1)
          // do some validation

          if (data.find(row => !row.hasOwnProperty(14))) {
            throw new Error('Invalid payment file format - not enough columns.')
          }
          return data.map((row: Record<number, any>): PaymentRow => {
            const currency = row[2].split(' ')[1]
            if (!currencyData.find(d => d.code.toLowerCase() === currency.toLowerCase())) {
              throw new Error(`Could not determine currency: ${row[2]}`)
            }
            const paymentDate = parseDate(row[4])
            if (!parseDate) {
              throw new Error(`Invalid date: ${row[4]}`)
            }
            let amount: Decimal
            try {
              amount = new Decimal(row[8])
            } catch (e) {
              throw new Error(`Invalid amount: ${row[8]}`)
            }

            return {
              currency,
              date: formatDate(paymentDate as Date, 'yyyy-MM-dd'),
              iban: row[10].replace('ACC:', ''),
              amount,
              from: row[9].replace('Od:', ''),
              reason: row[11]
            }
          })
        })
    }
    return null
  }, [file])

  return usePromise(promise)
}
