import React, { FC, useCallback, useMemo } from 'react'
import { CategoryWithId } from '@/models/category'
import { Formik, Form, FormikProps } from 'formik'
import { ShortFormikField } from '@/components/form/FormikField'
import Timeline from 'antd/lib/timeline'
import * as yup from 'yup'
import * as R from 'ramda'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { FormMutation } from '@/components/form/FormMutation'
import { InsertCategory, InsertCategoryVariables } from '@/queries/_gen_/InsertCategory'
import { InsertCategoryMutaton, UpdateCategoryMutation } from '@/queries/categories.queries'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import Button from 'antd/lib/button'
import { CategoryStepFormItem } from './CategoryStepFormItem'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import AntdForm from 'antd/lib/form'
import { FormikFieldError } from '@/components/form/FormError'
import { UpdateCategory, UpdateCategoryVariables } from '@/queries/_gen_/UpdateCategory'
import { CategoryFormValues, CategoryFormStepValue } from './categoryFormModels'
import { FCategoryFields_category_steps } from '@/queries/_gen_/FCategoryFields'
import { ReminderType } from '@/models/reminders'
import { InvoiceTemplateSelect } from '@/components/form/InvoiceTemplateSelect'

const CustomTimeline = styled(Timeline)`
  .ant-timeline-item:last-child .ant-timeline-item-tail {
    display: none;
  }

  .ant-timeline-item {
    padding-bottom: 10px;
  }
`

const AddButton = styled(Button)`
  width: 110px;
`

interface CategoryFormProps {
  isNew: boolean
  values: CategoryFormValues
  loading: boolean
  clientId: number
  onSubmit: (values: CategoryFormValues) => void
}

const emptyCategory: CategoryFormValues = {
  from_email: '',
  from_sms: '',
  name: '',
  category_steps: [],
  invoice_template_id: null,
  invoice_due_days: null,
  stage: 'reminder'
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Required!'),
  from_email: yup.string().email().required('Required!'),
  from_sms: yup.string().required('Required!'),
  invoice_due_days: yup.number().nullable().positive(),
  category_steps: yup
    .array()
    .min(1, 'At least one reminder is required.')
    .of(
      yup.object().test({
        test(value: CategoryFormStepValue, ctx: yup.TestContext) {
          const errors: string[] = []
          const from = Number(value.days_from)
          const to = Number(value.days_to)
          if (!(isNaN(from) || isNaN(to))) {
            if (from < 0 && to > 0) {
              errors.push('invalid "to"')
            } else if (to <= from) {
              errors.push('"to" must be after "from"')
            } else if (to > 0 && from < 1) {
              errors.push('"from" cannot be zero or less')
            }
          } else {
            errors.push('"from" and "to" must be valid numbers')
          }
          if (!value.reminder_type && !(value.reminder_type === ReminderType.phone || value.template_id)) {
            errors.push('Please select reminder type & template')
          }
          if (errors.length) {
            return new yup.ValidationError(
              errors.map(err => new yup.ValidationError(err)),
              value,
              ctx.path
            )
          }
          return true
        }
      } as any)
    )
})

const CategoryForm: FC<CategoryFormProps> = props => {
  const { values: origInitialValues, onSubmit, loading, clientId } = props

  const initialValues = useMemo(() => {
    const sortedAndIndexed = R.sortBy(
      step => step.days_from * 10000000 + step.days_to, // poor man's multi value sort lol
      origInitialValues.category_steps
    )
    return {
      ...origInitialValues,
      category_steps: sortedAndIndexed.map(step => ({
        ...step,
        before: step.days_from < 0
      }))
    }
  }, [origInitialValues])

  return (
    <Formik<CategoryFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ dirty, values, setFieldValue, setFieldTouched }: FormikProps<CategoryFormValues>) => {
        const steps = values.category_steps.map((step, idx) => ({ step, idx }))
        const [before, after] = R.partition(({ step }) => !!step.before, steps)
        return (
          <Form className='ant-form'>
            <div className='ant-form-vertical'>
              <ShortFormikField name='name' label='Name' />
              <ShortFormikField name='from_email' label='Email the reminder will be sent from' />
              <ShortFormikField name='from_sms' label='SMS sender name (Must be approved by service provider)' />
              <ShortFormikField name='invoice_template_id' label='Invoice template'>
                {({ field }) => <InvoiceTemplateSelect value={field.value} onChange={i => setFieldValue(field.name, i?.id || null)} />}
              </ShortFormikField>
              <ShortFormikField
                name='invoice_due_days'
                label='Invoice due in days'
                description='If not explicitly specified, invoice due date will be calculated by adding this many calendar days to document date'
              />
            </div>
            <AntdForm.Item>
              <label>Reminders:</label>
            </AntdForm.Item>
            <CustomTimeline>
              <Timeline.Item dot={<PlusOutlined />}>
                <AddButton
                  onClick={() => {
                    const daysTo = before.length ? Math.min(...before.map(step => step.step.days_from)) - 1 : 0
                    setFieldValue('category_steps', [
                      { days_from: daysTo - 99, days_to: daysTo, template_id: null, before: true },
                      ...values.category_steps
                    ])
                    setFieldTouched('category_steps.0' as 'category_steps', false)
                  }}
                  type='default'
                >
                  Add before
                </AddButton>
              </Timeline.Item>
              {before.map(({ step, idx }) => (
                <CategoryStepFormItem
                  key={idx}
                  index={idx}
                  value={step}
                  before={true}
                  clientId={clientId}
                  onDelete={() => setFieldValue('category_steps', R.remove(idx, 1, values.category_steps))}
                  setFieldValue={setFieldValue as any}
                />
              ))}
              <Timeline.Item color='blue'>Payment deadline</Timeline.Item>
              {after.map(({ step, idx }) => (
                <CategoryStepFormItem
                  key={idx}
                  index={idx}
                  value={step}
                  before={false}
                  clientId={clientId}
                  onDelete={() => setFieldValue('category_steps', R.remove(idx, 1, values.category_steps))}
                  setFieldValue={setFieldValue as any}
                />
              ))}
              <Timeline.Item dot={<PlusOutlined />}>
                <AddButton
                  onClick={() => {
                    const daysFrom = after.length ? Math.max(...after.map(step => step.step.days_to)) + 1 : 1
                    setFieldValue('category_steps', [
                      ...values.category_steps,
                      { days_from: daysFrom, days_to: daysFrom + (daysFrom ? 99 : 100), template_id: null, before: false }
                    ])
                    setFieldTouched('category_steps.0' as 'category_steps', false)
                  }}
                  type='default'
                >
                  Add after
                </AddButton>
              </Timeline.Item>
            </CustomTimeline>
            <FormikFieldError $block={true} name='category_steps' />
            <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
              Save
            </FormSubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}

interface CreateCategoryFormProps {
  onSaved: (category: CategoryWithId) => void
}

export const CreateCategoryForm: FC<CreateCategoryFormProps> = ({ onSaved }) => {
  const client = useCurrentClient()

  const buildVariables = useCallback(
    (values: CategoryFormValues): InsertCategoryVariables => {
      const { category_steps, ...payload } = values
      return {
        payload: {
          ...payload,
          client_id: client.id,
          category_steps: {
            data: category_steps.map(step => R.omit(['before'], step))
          }
        }
      }
    },
    [client]
  )

  return (
    <FormMutation<CategoryFormValues, InsertCategory, InsertCategoryVariables>
      buildVariables={buildVariables}
      successMessage='Category created!'
      onSaved={r => onSaved && r.result && r.result.returning.length && onSaved(r.result.returning[0])}
      mutation={InsertCategoryMutaton}
      refetchQueries={['SearchCategories', 'GetCategoryOptions']}
    >
      {({ onSubmit, result: { loading } }) => (
        <CategoryForm isNew={true} onSubmit={onSubmit} loading={loading} clientId={client.id} values={emptyCategory} />
      )}
    </FormMutation>
  )
}

interface UpdateCategoryFormProps {
  category: CategoryWithId
}

export const UpdateCategoryForm: FC<UpdateCategoryFormProps> = ({ category }) => {
  const client = useCurrentClient()

  const buildVariables = useCallback(
    (values: CategoryFormValues): UpdateCategoryVariables => ({
      category_id: category.id,
      steps: values.category_steps.map(step => ({
        ...R.omit(['before'], step),
        category_id: category.id
      })),
      category: {
        name: values.name,
        from_email: values.from_email,
        from_sms: values.from_sms,
        invoice_template_id: values.invoice_template_id,
        invoice_due_days: values.invoice_due_days || null
      },
      keep_ids: values.category_steps.map(s => s.id).filter(id => !!id) as number[]
    }),
    [category]
  )

  const vals: CategoryFormValues = useMemo(
    () => ({
      global: !category.client_id,
      ...R.omit(['__typename'], category),
      category_steps: category.category_steps.map(step => R.omit<FCategoryFields_category_steps, '__typename'>(['__typename'], step))
    }),
    [category]
  )

  return (
    <FormMutation<CategoryFormValues, UpdateCategory, UpdateCategoryVariables>
      buildVariables={buildVariables}
      successMessage='Category updated!'
      mutation={UpdateCategoryMutation}
    >
      {({ onSubmit, result: { loading } }) => (
        <CategoryForm isNew={false} onSubmit={onSubmit} loading={loading} clientId={client.id} values={vals} />
      )}
    </FormMutation>
  )
}
