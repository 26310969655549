import { useState, useCallback, useMemo } from 'react'
import { usePromise } from './usePromise'
import { fetch } from '@/utils/fetch'

export interface BackendHttpCallBag<T = unknown> {
  response?: Response
  data?: T
  loading: boolean
  error?: Error
}

interface ReqInit<B> extends Omit<RequestInit, 'body'> {
  body?: B
}

export type BackendHttpCallFn<T, B> = (path: string, reqinfo: ReqInit<B>) => Promise<BackendHttpCallResult<T>>

export interface BackendHttpCallResult<T> {
  response: Response
  data?: T
}

export function useBackendHttpCall<T = unknown, B = string>(): [BackendHttpCallFn<T, B>, BackendHttpCallBag<T>] {
  const [promise, setPromise] = useState<Promise<{ response: Response; data?: T }> | null>(null)

  const { result, loading, error } = usePromise(promise)

  const call = useCallback(
    (path: string, reqinit: ReqInit<B>) => {
      const { body, ...rest } = reqinit
      const req: RequestInit = rest
      if (body && typeof body !== 'string') {
        req.body = JSON.stringify(body)
        req.headers = {
          'Content-Type': 'application/json'
        }
      }
      const promise = fetch(__CONFIG__.backendUri + path, req).then(response => {
        if (response.headers.get('Content-Type')?.includes('application/json')) {
          return response.json().then(data => ({ response, data }))
        }
        return { response }
      })
      setPromise(promise)
      return promise
    },
    [setPromise]
  )

  const bag: BackendHttpCallBag<T> = useMemo(
    () => ({
      response: result?.response,
      loading,
      error: error || undefined,
      data: result?.data
    }),
    [result, loading, error]
  )

  return useMemo(() => [call, bag], [call, bag])
}
