import React, { FC } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import currencyData from 'currency-codes/data'

const { Option } = Select

export const CurrencySelect: FC<SelectProps<string>> = ({ value, ...props }) => (
  <Select {...props} value={value || undefined} placeholder='Select a currency' showSearch={true}>
    {currencyData.map(currency => (
      <Option key={currency.code} value={currency.code}>
        {currency.code} - {currency.currency}
      </Option>
    ))}
  </Select>
)
