import React, { FC } from 'react'
import { Drawer } from '@/components/Drawer'
import { UpdateDebtorView } from '../UpdateDebtorView'

export interface UpdateDebtorDrawerProps {
  path: string
  onClose: () => void
  debtorId?: number
}

export const UpdateDebtorDrawer: FC<UpdateDebtorDrawerProps> = props => {
  const { path, onClose, debtorId } = props

  return (
    <Drawer width={720} onClose={onClose} visible={!!debtorId}>
      {debtorId && <UpdateDebtorView pathRoot={path} debtorId={debtorId} />}
    </Drawer>
  )
}
