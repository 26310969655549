import { Event } from '@/models/events'
import { useContext, useEffect } from 'react'
import { EventBusContext } from '@/components/contexts/EventBusContext'

export function useEventSub<T>(event: Event<T>, callback: (arg: T) => void): void {
  const emitter = useContext(EventBusContext)
  useEffect(() => {
    emitter.on(event.eventId, callback)
    return () => {
      emitter.off(event.eventId, callback)
    }
  }, [event, emitter, callback])
}
