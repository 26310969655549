import styled from 'styled-components'

// layout with fixed with Side and flexible width Content

interface PWidth {
  width: number
}

interface PHeight {
  height: number
}

interface POverflow {
  noOverflow?: boolean
}

export const SideWithContent = {
  Wrapper: styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
  `,
  Side: styled.div<PWidth>`
    flex: 0 0 ${props => props.width}px;
    height: 100%;
    border-right: solid 1px gray;
  `,
  Content: styled.div`
    flex: 1;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  `
}

export const HeaderWithContent = {
  Wrapper: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div<PHeight>`
    flex: 0 0 ${(props: PHeight) => props.height}px;
  `,
  Content: styled.div<POverflow>`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${(props: POverflow) => (props.noOverflow ? 'overflow: hidden;' : '')}
  `
}
