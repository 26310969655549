import React, { BaseSyntheticEvent, FC, useCallback, useState } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import { FilterState, useSearchQuery } from '@/hooks/useSearchQuery'
import { SearchDebtors, SearchDebtorsVariables, SearchDebtors_items } from '@/queries/_gen_/SearchDebtors'
import { SearchDebtorsQuery } from '@/queries/debtors.queries'
import { SortByName, useCurrentClientFilterVariables } from '@/hooks/searchHelperHooks'
import { buildFilterVariables } from '@/utils/filterVars'
import { Debtor } from '@/models/debtor'

const { Option } = Select

interface DebtorSelectProps extends SelectProps<string> {
  setDebtor: (debtor: Debtor | undefined) => void
}

export const DebtorSelect: FC<DebtorSelectProps> = ({ value, setDebtor, ...props }) => {
  const [page, setPage] = useState(1)

  const searchBag = useSearchQuery<SearchDebtors_items, SearchDebtorsVariables, SearchDebtors>({
    query: SearchDebtorsQuery,
    extraVariables: useCurrentClientFilterVariables(),
    buildFilterVariables,
    defaultSort: SortByName
  })

  const { /* error, */ loading, fetchMore, fetchingMore, hasMore, items, /* state, */ setState /* total, query, variables */ } = searchBag

  const onScroll = async (event: BaseSyntheticEvent) => {
    const target = event.target

    if (!loading && Math.ceil(target.scrollTop) + target.offsetHeight === target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight)
      hasMore && fetchMore(page)
      setPage((page: number) => page + 1)
    }
  }

  const setFilters = useCallback((filter: FilterState) => setState({ filter }), [setState])

  const onSearch = async (key: string) => {
    setFilters({ query: [key] })
  }

  const onSelect = (value: string) => {
    const debtor = items.find(item => item.id === Number(value))
    setDebtor(debtor)
  }

  return (
    <Select
      {...props}
      value={value || undefined}
      placeholder='Select a Debtor'
      showSearch={true}
      loading={loading && !items.length}
      onPopupScroll={onScroll}
      onSearch={onSearch}
      onSelect={onSelect}
    >
      {items.map(debtor => (
        <Option key={debtor.id} value={debtor.id}>
          {debtor.name}
        </Option>
      ))}
      {(loading || fetchingMore) && (
        <Option key={'loading'} value={'loading'}>
          Loading...
        </Option>
      )}
    </Select>
  )
}
