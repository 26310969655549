import gql from 'graphql-tag'

export const EventFields = gql`
  fragment FEventFields on event {
    reminders {
      reminder_send_request {
        email_recipients
        success_phone_numbers
        status
        error_reason
        error_phone_numbers
        id
      }
      reminder {
        id
        import_file {
          id
          orig_filename
        }
        type
      }
    }
    id
    extra_info
    event_type
    created_at
    creator {
      id
      first_name
      last_name
    }
    comment {
      comment
      id
    }
    debtor {
      id
      name
    }
    invoices {
      invoice {
        id
        document_number
      }
    }
  }
`

export const GetEventsQuery = gql`
  query GetEvents($offset: Int!, $limit: Int!, $where: event_bool_exp!, $order_by: [event_order_by!]) {
    items: event(offset: $offset, limit: $limit, where: $where, order_by: $order_by) {
      ...FEventFields
    }
    total: event_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${EventFields}
`

export const InsertCommentMutation = gql`
  mutation InsertComment($comment: String!, $event_input: event_insert_input!) {
    insert_comment(objects: { comment: $comment, events: { data: [$event_input] } }) {
      returning {
        id
        events {
          ...FEventFields
        }
      }
    }
  }
  ${EventFields}
`

export const InsertCommentMutationV2 = gql`
  mutation InsertCommentV2($event_input: event_insert_input!) {
    insert_event_one(object: $event_input) {
      ...FEventFields
      comment {
        id
      }
    }
  }
  ${EventFields}
`

export const DeleteEventMutation = gql`
  mutation DeleteEvent($event_id: bigint!) {
    update_event(where: { id: { _eq: $event_id } }, _set: { is_deleted: true }) {
      affected_rows
    }
  }
`

export const UpdateCommentMutation = gql`
  mutation UpdateComment($comment_id: bigint!, $comment: String!) {
    update_comment(where: { id: { _eq: $comment_id } }, _set: { comment: $comment }) {
      returning {
        events {
          ...FEventFields
        }
      }
    }
  }
  ${EventFields}
`
