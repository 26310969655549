import React, { FC, useMemo } from 'react'
import Menu from 'antd/lib/menu'
import AntDropdown from 'antd/lib/dropdown'
import { DownOutlined } from '@ant-design/icons'

export interface DropdownOption {
  value: string
  label: string
  selectedLabel?: string
}

export interface DropdownProps {
  options: DropdownOption[]
  value: string
  label?: string
  onChange: (value: string) => void
}

export const Dropdown: FC<DropdownProps> = props => {
  const { options, value, onChange, label } = props

  const menu: React.ReactElement = useMemo(
    () => (
      <Menu>
        {options.map(({ value, label }) => (
          <Menu.Item key={value} onClick={() => onChange(value)}>
            {label}
          </Menu.Item>
        ))}
      </Menu>
    ),
    [options, onChange]
  )

  const currentLabel = useMemo(() => {
    const opt = options.find(option => option.value === value)
    return opt ? opt.selectedLabel ?? opt.label : '???'
  }, [value, options])

  const dropdown = (
    <AntDropdown overlay={menu} trigger={['click']}>
      <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
        {currentLabel} <DownOutlined />
      </a>
    </AntDropdown>
  )

  if (label) {
    return (
      <div className='dropdown'>
        <label>{label}:&nbsp;</label>
        {dropdown}
      </div>
    )
  } else {
    return dropdown
  }
}
