import React, { FC } from 'react'
import {
  RemindersSendRequestSubscription,
  RemindersSendRequestSubscriptionVariables
} from '@/queries/_gen_/RemindersSendRequestSubscription'
import { useSubscription } from 'react-apollo'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import subtractDaysFromDate from 'date-fns/subDays'
import { RemindersSendRequestsSubscription } from '@/queries/reminders.queries'
import { ApolloError } from 'apollo-client'

export interface ContextValue {
  variables: RemindersSendRequestSubscriptionVariables | undefined
  loading: boolean
  data?: RemindersSendRequestSubscription | undefined
  error?: ApolloError | undefined
}

export const RemindersSendRequestsSubContext = React.createContext<ContextValue>({ variables: undefined, loading: true })

const oneDayAgo = subtractDaysFromDate(new Date(), 1)

export const RemindersSendRequestsSubProvider: FC = ({ children }) => {
  const client = useCurrentClient()
  const subBag = useSubscription<RemindersSendRequestSubscription, RemindersSendRequestSubscriptionVariables>(
    RemindersSendRequestsSubscription,
    {
      variables: {
        client_id: client.id,
        created_since: oneDayAgo
      }
    }
  )

  return <RemindersSendRequestsSubContext.Provider value={subBag}>{children}</RemindersSendRequestsSubContext.Provider>
}
