import { CountClientsQuery } from '@/queries/clients.queries'
import { CountClients, CountClientsVariables } from '@/queries/_gen_/CountClients'
import { queryFieldValidator } from '@/utils/form'
import ApolloClient from 'apollo-client'

export const clientUniquenessValidator = (client: ApolloClient<any>, field: string, existingId?: number) =>
  queryFieldValidator<CountClients, CountClientsVariables>(
    client,
    CountClientsQuery,
    value => ({
      where: {
        [field]: { _eq: value },
        ...(existingId ? { id: { _neq: existingId } } : {})
      }
    }),
    r => !(r.result && r.result.aggregate && r.result.aggregate.count)
  )
