import React, { FC, useMemo, useCallback } from 'react'
import { useBackendHttpCall } from '@/hooks/useBackendHttpCall'
import * as yup from 'yup'
import styled from 'styled-components'
import { CenteredCard } from '@/components/CenteredCard'
import { Alert } from 'antd'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { Formik } from 'formik'
import { logger } from '@/logger'
import { Form } from '@/components/form/Form'
import { FormikField } from '@/components/form/FormikField'
import { AuthContainer } from '@/components/layouts/AuthContainer'

const SubmitButton = styled(FormSubmitButton)`
  float: right;
  margin-top: 20px;
`

interface ResetData {
  email: string
}

const initial: ResetData = {
  email: ''
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Must be a valid email').required('Required!')
})

export const ResetPassword: FC = () => {
  const [reset, { response, loading, error, data }] = useBackendHttpCall<{ error: string }, ResetData>()

  const errorMessage = useMemo((): string | null => {
    if (error) {
      return error.message
    }
    if (response && !response.ok) {
      return data?.error || 'Unexpected error. Sorry!'
    }
    return null
  }, [response, error, data])

  const onSubmit = useCallback(
    (values: ResetData) => {
      logger.info(`trying to init password reset for ${values.email}`)
      reset('/auth/init_password_reset', {
        body: values,
        method: 'POST'
      })
    },
    [reset]
  )

  return (
    <AuthContainer>
      <CenteredCard title='InvoRep Password Reset'>
        {!response?.ok && (
          <>
            {errorMessage && <Alert type='error' message={errorMessage} />}
            <p>To reset password, please enter your email address and click "Send password reset link".</p>
            <Formik<ResetData> initialValues={initial} onSubmit={onSubmit} validationSchema={validationSchema}>
              {({ dirty }) => (
                <Form className='ant-form'>
                  <FormikField name='email' placeholder='Email' autoFocus={true} />
                  <SubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
                    Send password reset link
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          </>
        )}
        {response?.ok && <Alert type='success' message='Password reset link sent. Please check your email.' />}
      </CenteredCard>
    </AuthContainer>
  )
}
