import { Modelize, IdField } from './utils'
import { FCasecardFields } from '@/queries/_gen_/FCasecardFields'

export type Casecard = Modelize<Omit<FCasecardFields, 'debtor'>>
export type CasecardWithId = Casecard & IdField

export enum FeeType {
  fixed = 'fixed',
  percentage = 'percentage'
}

export const feeTypeNames: { [key in FeeType]: string } = {
  [FeeType.fixed]: 'Fixed',
  [FeeType.percentage]: 'Percentage'
}

export const feeTypes = Object.values(FeeType)
