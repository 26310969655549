import ApolloClient from 'apollo-client'
import { DocumentNode } from 'graphql'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { logger } from '@/logger'

type Validator = (value?: any) => Promise<boolean>

export function queryFieldValidator<Result, Variables>(
  client: ApolloClient<InMemoryCache>,
  query: DocumentNode,
  getVars: (value: any) => Variables,
  validate: (result: Result) => boolean
): Validator {
  return value => {
    if (!value) {
      return Promise.resolve(true)
    }
    return client
      .query<Result, Variables>({
        query,
        variables: getVars(value),
        fetchPolicy: 'no-cache'
      })
      .then(result => validate(result.data))
      .catch(e => {
        logger.error('Error running validator', e)
        return false
      })
  }
}
