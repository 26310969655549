import styled from 'styled-components'
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'

interface Props {
  $block?: boolean
}

const ErrorSpan = styled.span<Props>`
  display: ${(props: Props) => (props.$block ? 'block' : 'inline')};
  color: red;
`

export const FormError: FC<Props> = props => <ErrorSpan {...props}>{props.children}</ErrorSpan>

export const FormikFieldError: FC<{ name: string } & Props> = ({ name, ...rest }) => (
  <ErrorMessage
    name={name}
    render={message => {
      if (typeof message === 'string') {
        return <FormError {...rest}>{String(message)}</FormError>
      }
      return null
    }}
  />
)
