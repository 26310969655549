import React, { useMemo } from 'react'
import { CreateClientForm } from './components/ClientGeneralForm'
import { RoutedTabPane } from '@/components/RoutedTabs'
import { HeaderWithTabs } from '@/components/layouts/HeaderWithTabs'
import { useNavigate } from 'react-router-dom'

export default () => {
  const nav = useNavigate()
  const tabs: RoutedTabPane[] = useMemo(
    () => [
      {
        name: 'General',
        path: '',
        render: () => <CreateClientForm onSaved={client => nav(`/clients/${client.id}`)} />
      }
    ],
    [nav]
  )

  return <HeaderWithTabs header='New client' tabs={tabs} pathRoot='/clients/new' />
}
