import { OperationVariables, MutationHookOptions, useMutation, MutationTuple } from 'react-apollo'
import { DocumentNode } from 'graphql'
import message from 'antd/lib/message'
import { useEffect } from 'react'

export function useMutationWithErrorReporting<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const mutationBag = useMutation<TData, TVariables>(mutation, options)
  const { error } = mutationBag[1]

  useEffect(() => {
    if (error) {
      message.error(error.message)
    }
  }, [error])

  return mutationBag
}
