export enum stages {
  all = 'all',
  reminder = 'reminder',
  recovery = 'recovery',
  judicial = 'judicial'
}

export function getStage(text: string | undefined): string {
  return text ? (text in stages ? text : stages.all) : stages.all
}
