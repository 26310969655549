import React, { FC, useMemo, useCallback } from 'react'
import { useQuery } from 'react-apollo'
import { EMPTY_LIST } from '@/utils/array'
import { ColumnProps } from 'antd/lib/table'
import { v4 as uuidv4 } from 'uuid'
import { Space } from 'antd'
import Button from 'antd/lib/button'
import message from 'antd/lib/message'
import Container from '@/components/layouts/Container'
import { GetClientApiKeysQuery, ExpireClientApiKeyMutation, InsertClientApiKeyMutation } from '@/queries/clients.queries'
import { dateRenderer } from '@/components/table/renderers/dateRenderer'
import { ScrollableContentTable } from '@/components/table/ScrollableContentTable'
import { GetClientApiKeys, GetClientApiKeysVariables, GetClientApiKeys_keys } from '@/queries/_gen_/GetClientApiKeys'
import { useMutationWithErrorReporting } from '@/hooks/useMutationWithErrorReporting'
import { FClientApiKeyFields } from '@/queries/_gen_/FClientApiKeyFields'
import { PlusOutlined } from '@ant-design/icons'

interface Props {
  key: string
  client: any
}

const ExpireButton: FC<{ clientApiKey: FClientApiKeyFields }> = ({ clientApiKey }) => {
  const [expireKeyMutation, expireKeyResult] = useMutationWithErrorReporting(ExpireClientApiKeyMutation)
  const ExpireKey = useCallback(() => {
    if (!expireKeyResult.loading && confirm('Are you sure you want to expire this key?')) {
      expireKeyMutation({
        variables: {
          id: clientApiKey.id
        }
      }).then(result => {
        if (result.data) {
          message.success('API key maarked as expired')
        }
      })
    }
  }, [expireKeyMutation, expireKeyResult])

  return (
    <Button type='default' size='small' danger onClick={() => ExpireKey()}>
      Expire Key
    </Button>
  )
}

const AddKeyButton: FC<{ clientId: number; variables: GetClientApiKeysVariables }> = ({ clientId, variables }) => {
  const [insertKeyMutation, insertKeyResult] = useMutationWithErrorReporting(InsertClientApiKeyMutation)
  const InsertKey = useCallback(() => {
    if (!insertKeyResult.loading && confirm('Are you sure to add a new api key?')) {
      insertKeyMutation({
        variables: {
          payload: {
            api_key: uuidv4(),
            client_id: clientId
          }
        },
        update: (cache, data) => {
          if (variables) {
            const query = cache.readQuery<GetClientApiKeys, GetClientApiKeysVariables>({ query: GetClientApiKeysQuery, variables })

            if (query && data.data?.result) {
              cache.writeQuery<GetClientApiKeys, GetClientApiKeysVariables>({
                query: GetClientApiKeysQuery,
                variables,
                data: {
                  ...query,
                  keys: [data.data?.result, ...query.keys]
                }
              })
            }
          }
        }
      }).then(result => {
        if (result.data) {
          message.success('API key added.')
        }
      })
    }
  }, [clientId, insertKeyMutation, insertKeyResult])

  return (
    <Button type='primary' icon={<PlusOutlined />} onClick={() => InsertKey()}>
      New API Key
    </Button>
  )
}

export default ({ client }: Props) => {
  const className = 'table'
  const columns: Array<ColumnProps<GetClientApiKeys_keys>> = useMemo(
    () => [
      {
        title: 'API Key',
        key: 'api_key',
        dataIndex: 'api_key',
        sorter: false
      },
      {
        title: 'Created At',
        key: 'created_at',
        dataIndex: 'created_at',
        render: dateRenderer,
        sorter: false
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'is_expired',
        sorter: false,
        render: (isExpired: boolean, record: GetClientApiKeys_keys) => (isExpired ? 'Expired' : <ExpireButton clientApiKey={record} />)
      }
    ],
    []
  )

  const { data, loading } = useQuery<GetClientApiKeys, GetClientApiKeysVariables>(GetClientApiKeysQuery, {
    variables: {
      clientId: client.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const apiKeys = data?.keys ?? EMPTY_LIST

  return (
    <Container>
      <Space direction='vertical' size='middle'>
        <div style={{ textAlign: 'right' }}>
          <AddKeyButton clientId={client.id} variables={{ clientId: client.id }} />
        </div>

        <ScrollableContentTable
          className={className}
          bordered={true}
          columns={columns}
          size={'small'}
          dataSource={apiKeys}
          loading={loading}
          rowKey='id'
          pagination={false}
        />
      </Space>
    </Container>
  )
}
