import React, { FC } from 'react'
import { FormikField } from './FormikField'
import { fromBackendDate, toBackendDate } from '@/utils/date'
import moment from 'moment'
import { DatePicker } from 'antd'

export interface FormikDateFieldProps {
  name: string
  label: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  disabled?: boolean
  allowClear?: boolean
}

export const FormikDateField: FC<FormikDateFieldProps> = ({ name, allowClear, label, disabled, setFieldValue }) => (
  <FormikField name={name} label={label}>
    {({ field }) => {
      const value = typeof field.value === 'string' ? moment(fromBackendDate(field.value)) : field.value
      return (
        <DatePicker
          value={value}
          allowClear={allowClear}
          onChange={val => {
            setFieldValue(name, val ? toBackendDate(val.toDate()) : null)
          }}
          disabled={disabled}
        />
      )
    }}
  </FormikField>
)
