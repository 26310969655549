import React, { FC } from 'react'
import { GetTemplate, GetTemplateVariables } from '@/queries/_gen_/GetTemplate'
import * as R from 'ramda'
import { GetTemplateQuery } from '@/queries/remindersTemplates.queries'
import CenterAlert from '@/components/CenterAlert'
import Fetch from '@/components/Fetch'
import { UpdateReminderTemplateForm } from './components/TemplateReminderForm'
import Container from '@/components/layouts/Container'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { useParams } from 'react-router-dom'

export const UpdateReminderTemplateView: FC = () => {
  const client = useCurrentClient()
  const id = useParams().id
  const templateId = parseInt(id || '0', 10)

  return (
    <Fetch<GetTemplate, GetTemplateVariables>
      key={`template-update-${templateId}`}
      query={GetTemplateQuery}
      container={true}
      variables={{ id: templateId }}
      fetchPolicy='network-only'
    >
      {data => {
        if (data.template) {
          const template = R.omit(['__typename'], data.template)
          return (
            <HeaderWithContent header={template.name}>
              <Container>
                <UpdateReminderTemplateForm key={template.id} client={client} template={template} />
              </Container>
            </HeaderWithContent>
          )
        }
        return <CenterAlert type='error' message='Template not found :(' />
      }}
    </Fetch>
  )
}
