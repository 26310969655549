import React from 'react'
import CardLink from '@/components/CardLink'
import Container from '@/components/layouts/Container'
import styled from 'styled-components'
import { UploadOutlined } from '@ant-design/icons'
import { useRole } from '@/hooks/useRole'

const StyledCard = styled(CardLink)`
  margin: 1em;
  float: left;
`

export default () => {
  const { isManager, isGuest } = useRole()

  const action = isGuest ? 'View' : 'View, create or update'

  return (
    <Container>
      {isManager && (
        <StyledCard title='Clients' to='/clients'>
          <p>{action} clients</p>
        </StyledCard>
      )}
      {isManager && (
        <StyledCard title='Users' to='/users'>
          <p>View, create or update users</p>
        </StyledCard>
      )}
      <StyledCard title='Debtors' to='/debtors'>
        <p>{action} debtors</p>
      </StyledCard>
      {isManager && (
        <StyledCard title='Reminder templates' to='/templates/reminders'>
          <p>View, create or update templates</p>
        </StyledCard>
      )}
      {isManager && (
        <StyledCard title='Categories' to='/categories'>
          <p>View, create or update categories</p>
        </StyledCard>
      )}
      {isManager && (
        <StyledCard
          title={
            <>
              <UploadOutlined /> Import invoices
            </>
          }
          to='/import'
        >
          <p>Import invoices from an excel file</p>
        </StyledCard>
      )}
      {isManager && (
        <StyledCard
          title={
            <>
              <UploadOutlined /> Import payments
            </>
          }
          to='/import_payments'
        >
          <p>Import payments from a csv file</p>
        </StyledCard>
      )}
      <StyledCard title='Invoices' to='/invoices?f_is_paid=false'>
        <p>List of invoices</p>
      </StyledCard>
      <StyledCard title='Reminders' to='/reminders?f_status=new'>
        <p>List of reminders</p>
      </StyledCard>
      <StyledCard title='Reports' to='/reports'>
        <p>Reports</p>
      </StyledCard>
    </Container>
  )
}
