import { GetReminder_reminder } from '@/queries/_gen_/GetReminder'
import React, { FC, useMemo, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Descriptions from 'antd/lib/descriptions'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { ContactType } from '@/models/debtor'
import Alert from 'antd/lib/alert'
import { formatDateTimeReadable } from '@/utils/date'
import { formatDistanceToNow } from 'date-fns'
import { User } from '@/components/User'
import { TemplateLanguage, templateLanguageShortNames } from 'common/models/template'

const DescItem = Descriptions.Item

export interface ReminderDetailsProps {
  reminder: GetReminder_reminder
}

export const ReminderDetails: FC<ReminderDetailsProps> = ({ reminder }) => {
  const emails = useMemo(() => reminder.debtor.debtor_contacts.filter(c => c.type == ContactType.email), [reminder])
  const phoneNumbers = useMemo(() => reminder.debtor.debtor_contacts.filter(c => c.type == ContactType.phone), [reminder])

  const debtorDrawerUrl = usePathWithParams(`/reminders/${reminder.id}/debtor`)
  const debtorDrawerContactsUrl = usePathWithParams(`/reminders/${reminder.id}/debtor/contacts`)

  return (
    <Descriptions bordered={true} column={1} size={'small'}>
      <DescItem label='Company'>
        <Link to={debtorDrawerUrl}>{reminder.debtor.name}</Link>
        <br />
        Company code: {reminder.debtor.company_code || '-'}
        <br />
        VAT code: {reminder.debtor.vat_code || '-'}
        <br />
        Reference code: {reminder.debtor.vat_code || '-'}
        <br />
        Customer code in client system: {reminder.debtor.customer_code_in_client_system || '-'}
        <br />
      </DescItem>

      <DescItem label='Emails'>
        {!!emails.length &&
          emails.map(({ value, description }) => (
            <Fragment key={value}>
              <a href={`mailto:${value}`}>{value}</a>
              {description ? ` ${description}` : ''}
              <br />
            </Fragment>
          ))}
        {!emails.length && (
          <Alert
            type='error'
            message={
              <>
                Company does not have a valid email! &nbsp;
                <Link to={debtorDrawerContactsUrl}>View company contact details</Link>
              </>
            }
          />
        )}
      </DescItem>
      <DescItem label='Phone numbers'>
        {!!phoneNumbers.length &&
          phoneNumbers.map(({ value, description }) => (
            <Fragment key={value}>
              <a href={`callto:${value}`}>{value}</a>
              {description ? ` ${description}` : ''}
              <br />
            </Fragment>
          ))}
        {!phoneNumbers.length && (
          <Alert
            type='error'
            message={
              <>
                Company does not have a valid phone number! &nbsp;
                <Link to={debtorDrawerContactsUrl}>View company contact details</Link>
              </>
            }
          />
        )}
      </DescItem>
      {reminder.template && (
        <DescItem label='Template'>
          <a href={`/templates/reminders/${reminder.template.id}`}>
            {reminder.template.name} ({templateLanguageShortNames[reminder.template.language as TemplateLanguage]})
          </a>
        </DescItem>
      )}
      <DescItem label='Import file'>{reminder.import_file.orig_filename || 'N/A'}</DescItem>
      <DescItem label='Created'>
        <span title={formatDateTimeReadable(reminder.created_at)}>
          {formatDistanceToNow(Date.parse(reminder.created_at), { addSuffix: true })}
        </span>
      </DescItem>
      {reminder.cancelled_at && (
        <DescItem label='Cancelled'>
          <strong>{formatDateTimeReadable(reminder.cancelled_at)}</strong> by{' '}
          <strong>{reminder.cancelled_by_user && <User user={reminder.cancelled_by_user} />}</strong>
        </DescItem>
      )}
      {reminder.completed_at && (
        <DescItem label='Sent'>
          <strong>{formatDateTimeReadable(reminder.completed_at)}</strong> by{' '}
          <strong>{reminder.completed_by_user && <User user={reminder.completed_by_user} />}</strong>
        </DescItem>
      )}
    </Descriptions>
  )
}
