import React from 'react'
import CardLink from '@/components/CardLink'
import Container from '@/components/layouts/Container'
import { PlusOutlined } from '@ant-design/icons'

export default () => (
  <Container>
    <CardLink icon={<PlusOutlined />} title='New' to='/users/new'>
      <p>Add a new user</p>
    </CardLink>
  </Container>
)
