
export enum ContactType {
  email = 'email',
  phone = 'phone',
  address = 'address'
}

export const contactType2Name: { [key in ContactType]: string} = {
  [ContactType.email]: 'Email',
  [ContactType.phone]: 'Phone number',
  [ContactType.address]: 'Address'
}