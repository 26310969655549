import React, { FC, useState } from 'react'
import { useElementDimensions, Dimensions } from '@/hooks/useElementDimensions'

export interface DivWithDimensionsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: (dimensions: Dimensions) => JSX.Element
}

export const DivWithDimensions: FC<DivWithDimensionsProps> = props => {
  const { children, ...divprops } = props
  const [div, setDiv] = useState<HTMLDivElement | null>(null)
  const dimensions = useElementDimensions(div)

  return (
    <div {...divprops} ref={setDiv}>
      {dimensions && children(dimensions)}
    </div>
  )
}
