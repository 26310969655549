import { useCallback, useState, useEffect } from 'react'

export interface Dimensions {
  width: number
  height: number
}

export const useElementDimensions = (element: HTMLElement | null): Dimensions | null => {
  const [dimensions, setDimensions] = useState<Dimensions | null>(null)
  const calcDimensions = useCallback(() => {
    if (element) {
      setDimensions({
        height: element.clientHeight,
        width: element.clientWidth
      })
    }
  }, [element, setDimensions])

  useEffect(() => {
    if (element) {
      if (!dimensions) {
        calcDimensions()
      }
      element.addEventListener('resize', calcDimensions)
      window.addEventListener('resize', calcDimensions)
      return () => {
        element.removeEventListener('resize', calcDimensions)
        window.removeEventListener('resize', calcDimensions)
      }
    }
    return () => {
      /* */
    }
  }, [element, calcDimensions])

  return dimensions
}
