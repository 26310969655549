import React, { FC } from 'react'
import Container from '@/components/layouts/Container'
import { EventsHistory } from '@/components/history/EventsHistory'
import { InvoiceWithId } from '@/models/invoice'

export interface InvoiceEventsTabProps {
  invoice: InvoiceWithId
}

export const InvoiceEventsTab: FC<InvoiceEventsTabProps> = ({ invoice }) => (
  <Container>
    <EventsHistory debtorId={invoice.debtor.id} invoiceId={invoice.id} />
  </Container>
)
