import React, { FC, useMemo } from 'react'
import Breadcrumb from 'antd/lib/breadcrumb'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'

const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: 1.2em;
`

interface StyledSpanProps {
  $active?: boolean
  $disabled?: boolean
}

const StyledSpan = styled.span<StyledSpanProps>`
  ${(props: StyledSpanProps) => (props.$active ? 'font-weight: bold;' : 'font-weight: normal;')}
  ${(props: StyledSpanProps) => (props.$disabled ? 'color: #BFBFBF;' : '')}
`

interface BreadcrumbStyledSpanProps {
  active?: boolean
  disabled?: boolean
  to?: string
  className?: string
}

const BreadcrumbStyledSpan: FC<BreadcrumbStyledSpanProps> = props => {
  const { to, children, active, disabled } = props
  return (
    <StyledSpan $active={active} $disabled={disabled}>
      {to && !active && !disabled ? <Link to={to}>{children}</Link> : <>{children}</>}
    </StyledSpan>
  )
}

interface ImportBreadcrumbsProps {
  hasFile: boolean
}

export const PaymentImportBreadcrumbs: FC<ImportBreadcrumbsProps> = ({ hasFile }) => {
  const path = useLocation().pathname

  const items = useMemo(
    () => [
      <BreadcrumbStyledSpan key='import' active={path === '/import_payments'} to='/import_payments'>
        Upload
      </BreadcrumbStyledSpan>,
      <BreadcrumbStyledSpan key='confirm' active={path === '/import_payments/confirm'} to='/import_payments/confirm' disabled={!hasFile}>
        Confirm
      </BreadcrumbStyledSpan>
    ],
    [path, hasFile]
  )

  return (
    <StyledBreadcrumb separator='>'>
      {items.map((item, idx) => (
        <Breadcrumb.Item key={idx}>{item}</Breadcrumb.Item>
      ))}
    </StyledBreadcrumb>
  )
}
