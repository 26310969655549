import React, { FC, useMemo } from 'react'

import { ColumnProps } from 'antd/lib/table'
import { GetReminder_reminder } from '@/queries/_gen_/GetReminder'
import { amountRenderer } from '@/components/table/renderers/amountRenderer'
import { dateRenderer } from '@/components/table/renderers/dateRenderer'
import { dateSorter } from '@/components/table/sorters/dateSorter'
import { numberSorter } from '@/components/table/sorters/numberSorter'
import { naiveSorter } from '@/components/table/sorters/naiveSorter'
import styled from 'styled-components'
import { calcInvoiceOutstanding } from 'common/utils/invoice'
import Decimal from 'decimal.js'
import { ScrollableContentTable } from '@/components/table/ScrollableContentTable'
import { Link } from 'react-router-dom'
import { usePathWithParamsBuilder } from '@/hooks/usePathWithParams'
import { EditOutlined, MessageOutlined } from '@ant-design/icons'
import { useRole } from '@/hooks/useRole'
import { useQuery } from 'react-apollo'
import { GetDebtorInvoices, GetDebtorInvoicesVariables, GetDebtorInvoices_invoices } from '@/queries/_gen_/GetDebtorInvoices'
import { GetDebtorInvoicesQuery } from '@/queries/invoices.queries'
import { EMPTY_LIST } from '@/utils/array'
import { withSpan } from '@/components/contexts/TraceContext'
export interface ReminderInvoicesTableProps {
  reminder: GetReminder_reminder
  className?: string
  staticHeight?: number
}

const FooterDiv = styled.div`
  text-align: right;
`

const ActionsContainer = styled.div`
  white-space: nowrap;

  & > * + * {
    margin-left: 0.5em;
  }
`

const calcOutstanding = (invoices: GetDebtorInvoices_invoices[]) =>
  Object.entries(calcInvoiceOutstanding(invoices, Decimal)).map(([currency, amount]) => `${amount} ${currency}`)

const Footer: FC<{ invoices: GetDebtorInvoices_invoices[] }> = ({ invoices }) => {
  const totals = useMemo(
    () => ({
      all: calcOutstanding(invoices),
      late: calcOutstanding(invoices.filter(i => !!i.is_late)),
      notlate: calcOutstanding(invoices.filter(i => !i.is_late))
    }),
    [invoices]
  )

  const countLate = invoices.filter(i => !!i.is_late).length
  const countNotLate = invoices.length - countLate
  if (countLate && countNotLate) {
    return (
      <FooterDiv>
        {countLate} late ({totals.late.join(', ')}), {countNotLate} due soon ({totals.notlate.join(', ')}),{' '}
        <strong>
          {invoices.length} total ({totals.all.join(', ')})
        </strong>
      </FooterDiv>
    )
  } else {
    return (
      <FooterDiv>
        <strong>
          {invoices.length} {countLate ? 'late invoices' : 'invoices due soon'} ({totals.all.join(', ')})
        </strong>
      </FooterDiv>
    )
  }
}

export const ReminderInvoicesTable = withSpan<ReminderInvoicesTableProps>(
  'ReminderInvoicesTable',
  props => ({
    'reminder.id': props.reminder.id
  }),
  props => {
    const { reminder, className, staticHeight } = props

    const { isManager } = useRole()

    const buildPath = usePathWithParamsBuilder()

    const { data, loading, error } = useQuery<GetDebtorInvoices, GetDebtorInvoicesVariables>(GetDebtorInvoicesQuery, {
      variables: {
        debtor_id: reminder.debtor.id
      },
      fetchPolicy: 'cache-and-network'
    })

    const invoices = data?.invoices ?? EMPTY_LIST

    const columns: Array<ColumnProps<GetDebtorInvoices_invoices>> = useMemo(
      () => [
        ...(isManager
          ? [
              {
                title: '',
                key: 'editcol',
                dataIndex: 'id',
                render: (invoiceId: number, record: GetDebtorInvoices_invoices) => (
                  <ActionsContainer>
                    <Link to={buildPath(`/reminders/${reminder.stage}/${reminder.id}/invoices/${invoiceId}`)} title='Edit invoice'>
                      <EditOutlined />
                    </Link>
                    <Link
                      to={buildPath(`/reminders/${reminder.stage}/${reminder.id}/invoices/${invoiceId}/history`)}
                      title='Invoice comments'
                    >
                      ({record.events_aggregate.aggregate?.count})
                      <MessageOutlined />
                    </Link>
                  </ActionsContainer>
                ),
                width: 75
              }
            ]
          : []),
        {
          title: 'Doc. no',
          key: 'docno',
          dataIndex: 'document_number',
          sorter: naiveSorter('document_number')
        },
        {
          title: 'Date',
          key: 'docdate',
          dataIndex: 'document_date',
          render: dateRenderer,
          sorter: dateSorter('document_date')
        },
        {
          title: 'Due date',
          key: 'duedate',
          dataIndex: 'due_date',
          render: (date: string, record: GetDebtorInvoices_invoices) => {
            if (record.is_late) {
              return <span style={{ color: 'red', fontWeight: 'bold' }}>{dateRenderer(date)}</span>
            }
            return dateRenderer(date)
          },
          sorter: dateSorter('due_date')
        },
        ...(invoices.find(i => i.amount_with_vat)
          ? [
              {
                title: 'Amt. w. tax',
                key: 'amount_with_vat',
                dataIndex: 'amount_with_vat',
                render: amountRenderer,
                align: 'right' as const,
                sorter: numberSorter('amount_with_vat')
              }
            ]
          : []),
        ...(invoices.find(i => i.amount_wo_vat)
          ? [
              {
                title: 'Amt. w/o tax',
                key: 'amount_wo_vat',
                dataIndex: 'amount_wo_vat',
                render: amountRenderer,
                align: 'right' as const,
                sorter: numberSorter('amount_wo_vat')
              }
            ]
          : []),
        {
          title: 'Amt. Outstanding',
          key: 'amount_outstanding',
          dataIndex: 'amount_outstanding',
          render: amountRenderer,
          align: 'right',
          sorter: numberSorter('amount_outstanding')
        },
        {
          title: 'Extra 1',
          key: 'extra_1',
          dataIndex: 'extra_1',
          sorter: naiveSorter('extra_1')
        },
        {
          title: 'Extra 2',
          key: 'extra_2',
          dataIndex: 'extra_2',
          sorter: naiveSorter('extra_2')
        },
        {
          title: 'Extra 3',
          key: 'extra_3',
          dataIndex: 'extra_3',
          sorter: naiveSorter('extra_3')
        },
        {
          title: 'Extra 4',
          key: 'extra_4',
          dataIndex: 'extra_4',
          sorter: naiveSorter('extra_4')
        }
      ],
      [reminder.id, buildPath, invoices, isManager]
    )

    return (
      <ScrollableContentTable
        className={className}
        bordered={true}
        columns={columns}
        size={'small'}
        dataSource={invoices}
        loading={loading}
        rowKey='id'
        pagination={false}
        footer={() => <Footer invoices={invoices} />}
        staticHeight={staticHeight}
        locale={{
          emptyText: error?.message ?? 'no unpaid invoices found'
        }}
      />
    )
  }
)
