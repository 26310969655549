import gql from 'graphql-tag'

const CategoryFieldsFragment = gql`
  fragment FCategoryImportFields on category {
    id
    name
    invoice_due_days
    category_steps {
      id
      days_from
      days_to
      template {
        id
        type
        language
        name
      }
      reminder_type
    }
  }
`

const ImportFileColumnConfigFieldsFragment = gql`
  fragment FImportFileColumnConfigFields on import_file_column_config {
    config
    id
  }
`

export const GetImportContextQuery = gql`
  query GetImportContext($client_id: Int!) {
    clientCategories: category(where: { client_id: { _eq: $client_id }, name: {} }) {
      ...FCategoryImportFields
    }
  }
  ${CategoryFieldsFragment}
`

export const GetExistingDebtorsQuery = gql`
  query GetExistingDebtors($debtor_company_codes: [String!]!, $debtor_names: [String!]!, $client_id: Int!) {
    debtor(
      where: { client_id: { _eq: $client_id }, _or: [{ company_code: { _in: $debtor_company_codes } }, { name: { _in: $debtor_names } }] }
    ) {
      id
      name
      company_code
      category_id
      extra_1
      extra_2
      customer_code_in_client_system
      debtor_contacts {
        id
        value
        type
      }
    }
  }
`

export const GetExistingDebtorsAndInvoicesQuery = gql`
  query GetExistingDebtorsAndInvoices(
    $client_id: Int!
    $debtor_company_codes: [String!]!
    $debtor_names: [String!]!
    $invoice_document_numbers: [String!]!
  ) {
    debtor(
      where: { client_id: { _eq: $client_id }, _or: [{ company_code: { _in: $debtor_company_codes } }, { name: { _in: $debtor_names } }] }
    ) {
      id
      name
      company_code
      suspend_reminders_until
      are_reminders_suspended
      invoices(where: { document_number: { _in: $invoice_document_numbers } }) {
        id
        document_number
        suspend_reminders_until
        is_sending_suspended
        amount_paid
        amount_wo_vat
        currency
        amount_with_vat
        amount_outstanding
        document_date
        document_number
        due_date
        vat
        is_paid
        extra_1
        extra_2
        is_late
      }
      category {
        ...FCategoryImportFields
      }
    }
    client_by_pk(id: $client_id) {
      id
      category {
        ...FCategoryImportFields
      }
    }
  }
  ${CategoryFieldsFragment}
`

export const InsertImportFileMutation = gql`
  mutation InsertImportFile(
    $bucket_filename: String!
    $client_id: Int!
    $import_options: jsonb!
    $orig_filename: String!
    $raw_data: jsonb!
    $import_type: String!
  ) {
    insert_import_file(
      objects: [
        {
          bucket_filename: $bucket_filename
          client_id: $client_id
          import_options: $import_options
          orig_filename: $orig_filename
          raw_data: $raw_data
          import_type: $import_type
        }
      ]
    ) {
      returning {
        id
      }
    }
  }
`

export const ImportCancelRemindersMutation = gql`
  mutation ImportCancelReminders($client_id: Int!, $user: uuid!) {
    cancelled_reminders: update_reminder(
      where: { client_id: { _eq: $client_id }, status: { _in: ["new", "error"] } }
      _set: { status: "cancelled", cancelled_at: "now()", cancelled_by: $user }
    ) {
      affected_rows
    }
  }
`

export const ImportMarkInvoicesAsPaidMutation = gql`
  mutation ImportMarkInvoicesAsPaid($client_id_2: Int!, $existing_invoice_ids: [Int!]!) {
    marked_as_paid_invoices: update_invoice(
      where: { debtor: { client_id: { _eq: $client_id_2 } }, id: { _nin: $existing_invoice_ids }, is_paid: { _eq: false } }
      _set: { is_paid: true }
    ) {
      affected_rows
    }
  }
`

export const ImportInsertDebtorsMutation = gql`
  mutation ImportInsertDebtors($insert_debtors: [debtor_insert_input!]!) {
    imported_debtors: insert_debtor(objects: $insert_debtors) {
      affected_rows
    }
  }
`

export const ImportInsertInvoicesMutation = gql`
  mutation ImportInsertInvoices($insert_invoices: [invoice_insert_input!]!) {
    imported_invoices: insert_invoice(
      objects: $insert_invoices
      on_conflict: {
        constraint: invoice_debtor_id_document_number_key
        update_columns: [
          amount_paid
          amount_wo_vat
          currency
          amount_with_vat
          amount_outstanding
          document_date
          document_number
          due_date
          vat
          is_paid
          extra_1
          extra_2
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const ImportInsertRemindersMutation = gql`
  mutation ImportInsertReminders($insert_reminders: [reminder_insert_input!]!) {
    imported_reminders: insert_reminder(objects: $insert_reminders) {
      affected_rows
    }
  }
`

export const ImportInsertDebtorContactsMutation = gql`
  mutation ImportInsertDebtorContacts($insert_contacts: [debtor_contact_insert_input!]!) {
    imported_contacts: insert_debtor_contact(objects: $insert_contacts) {
      affected_rows
    }
  }
`

export const ImportSetClientNextInvoiceNumberMutation = gql`
  mutation ImportUpdateClientSeriesNumber($client_id_3: Int!, $next_invoice_number: Int!) {
    update_client_by_pk(pk_columns: { id: $client_id_3 }, _set: { next_invoice_number: $next_invoice_number }) {
      next_invoice_number
      id
    }
  }
`

export const ImportUpdateDebtorMutation = gql`
  mutation ImportUpdateDebtor($update_debtor_debtor_id: Int!, $update_debtor_payload: debtor_set_input!) {
    update_debtor_by_pk(pk_columns: { id: $update_debtor_debtor_id }, _set: $update_debtor_payload) {
      id
    }
  }
`
export const GetImportFileColumnConfigQuery = gql`
  query GetImportFileColumnConfig($client_id: Int!, $column_count: Int!) {
    import_file_column_config(where: { client_id: { _eq: $client_id }, column_count: { _eq: $column_count } }, limit: 1) {
      ...FImportFileColumnConfigFields
    }
  }
  ${ImportFileColumnConfigFieldsFragment}
`

export const UpsertImportFileColumnConfigMutation = gql`
  mutation UpsertImportFileColumnConfig($config: jsonb!, $cc_client_id: Int!, $column_count: Int!) {
    insert_import_file_column_config_one(
      object: { config: $config, client_id: $cc_client_id, column_count: $column_count }
      on_conflict: { constraint: import_file_column_config_client_id_column_count_key, update_columns: config }
    ) {
      ...FImportFileColumnConfigFields
    }
  }
  ${ImportFileColumnConfigFieldsFragment}
`
