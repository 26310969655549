import React, { FC, useMemo, useCallback, useEffect } from 'react'
import { useQuery } from 'react-apollo'
import { GetTemplateOptions, GetTemplateOptionsVariables } from '@/queries/_gen_/GetTemplateOptions'
import { GetTemplateOptionsQuery } from '@/queries/remindersTemplates.queries'
import Cascader, { CascaderOptionType, CascaderValueType } from 'antd/lib/cascader'
import * as R from 'ramda'
import { TemplateLanguage, templateLanguageNames, templateLanguageShortNames } from '@/models/templates'
import { reminderTypes, reminderTypeNames, ReminderType } from '@/models/reminders'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

export interface ReminderTypeSelectValue {
  reminderType: ReminderType
  templateId: number | null
}

export type ReminderTypeSelectOnChange = (value: ReminderTypeSelectValue | null) => void

interface ReminderTypeSelectProps {
  value: ReminderTypeSelectValue | null
  className?: string
  size?: SizeType
  clientId?: number
  onChange: ReminderTypeSelectOnChange
}

export const ReminderTypeSelect: FC<ReminderTypeSelectProps> = props => {
  const { value, onChange, clientId, className, size } = props

  const variables: GetTemplateOptionsVariables = useMemo(() => {
    if (clientId) {
      return {
        where: {
          _or: [{ client_id: { _eq: props.clientId } }, { client_id: { _is_null: true } }]
        }
      }
    }
    return {
      where: {
        client_id: { _is_null: true }
      }
    }
  }, [props.clientId])

  const { loading, data, error } = useQuery<GetTemplateOptions, GetTemplateOptionsVariables>(GetTemplateOptionsQuery, {
    variables,
    fetchPolicy: 'network-only'
  })

  const results = data && data.results

  const options: CascaderOptionType[] = useMemo(() => {
    if (results) {
      return reminderTypes.map(type => {
        const languages = R.uniq(results.filter(r => r.type === type).map(r => r.language))
        return {
          label: reminderTypeNames[type],
          value: type,
          disabled: type !== ReminderType.phone && !languages.length,
          children: languages.map(lang => {
            const templates = results.filter(r => r.language === lang && r.type === type)
            return {
              label: templateLanguageNames[lang as TemplateLanguage],
              value: lang,
              disabled: !templates.length,
              children: templates.map(r => ({
                label: r.name,
                value: String(r.id)
              }))
            }
          })
        }
      })
    } else {
      return []
    }
  }, [results])

  const theValue: string[] | undefined = useMemo(() => {
    if (value && value.reminderType && results) {
      const { reminderType, templateId } = value
      const template = templateId ? results.find(r => r.id === templateId) : null
      if (template) {
        return [reminderType, template.language, String(template.id)]
      } else {
        return [reminderType]
      }
    }
    return undefined
  }, [results, JSON.stringify(value)])

  const onChangeCallback = useCallback(
    (values: CascaderValueType): void => {
      if (values && values.length) {
        onChange({
          reminderType: values[0] as ReminderType,
          templateId: values.length >= 2 ? Number(values[2]) : null
        })
      }
    },
    [onChange]
  )

  // if value has been selected that doesn't exist, unset it. in case user untoggled global
  useEffect(() => {
    if (value && data && data.results && !theValue) {
      onChange(null)
    }
  }, [theValue, JSON.stringify(value), data && data.results])

  const displayRender = useCallback((labels: string[], selected?: CascaderOptionType[]): React.ReactNode => {
    if (selected && selected.length) {
      if (selected.length > 2) {
        return `${labels[0]} / ${templateLanguageShortNames[selected[1].value as TemplateLanguage]} / ${labels[2]}`
      } else {
        return labels[0]
      }
    }
    return null
  }, [])

  return (
    <Cascader
      className={className}
      size={size}
      value={theValue}
      options={options}
      expandTrigger='hover'
      displayRender={displayRender}
      placeholder={loading ? 'Loading...' : error ? 'error :(' : 'Select notification'}
      onChange={onChangeCallback}
    />
  )
}
