import React, { FC } from 'react'
import { FormikFieldProps, FormikField } from './FormikField'
import Switch from 'antd/lib/switch'

export type FormikSwitchFieldProps = Omit<FormikFieldProps, 'children'>

export const FormikSwitchField: FC<FormikSwitchFieldProps> = props => (
  <FormikField {...props}>
    {({ field, form }) => (
      <Switch key={String(field.value)} defaultChecked={field.value} onChange={checked => form.setFieldValue(props.name, checked)} />
    )}
  </FormikField>
)
