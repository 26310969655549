import React, { FC, useCallback } from 'react'
import { SearchUsers_items, SearchUsersVariables, SearchUsers } from '@/queries/_gen_/SearchUsers'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { ItemLink, InfiniteList } from '@/components/infinite_list/InfiniteList'
import List from 'antd/lib/list'
import { useSearchQuery, BuildFilterVariablesFn } from '@/hooks/useSearchQuery'
import { InfListQueryFilter } from '@/components/infinite_list/InfListQueryFilter'
import { SearchUsersQuery } from '@/queries/users.queries'
import { InfListAddButton } from '@/components/infinite_list/InfListAddButton'
import { Route, Routes } from 'react-router'
import { SideWithContent as L } from '@/components/layouts/CustomLayouts'
import UsersIndexView from './UsersIndexView'
import { order_by } from '@/queries/_gen_global'
import { takeFirst } from '@/utils/array'
import { CreateUserView } from './CreateUserView'
import { UpdateUserView } from './UpdateUserView'
import { Tag } from 'antd'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

const UTag = styled(Tag)`
  float: right;
`

export const UsersListItem: FC<{ user: SearchUsers_items }> = ({ user }) => (
  <ItemLink to={usePathWithParams(`/users/${user.id}`)}>
    <List.Item.Meta
      title={
        <>
          {`${user.first_name} ${user.last_name}`}
          {user.is_admin && <UTag color='#fe00fe'>admin</UTag>}
          {!user.is_active && <UTag color='#f50'>inactive</UTag>}
        </>
      }
      description={
        <>
          {user.email}
          <br />
          {!user.is_admin && !!user.user_roles.length && `${user.user_roles.map(r => r.client.name).join(', ')}`}
        </>
      }
    />
  </ItemLink>
)

const defaultSort = {
  field: 'first_name',
  order: order_by.asc
}

const UsersList = () => {
  const _id = useParams().id
  const selectedItemId = !_id || _id === 'new' ? undefined : _id
  const buildFilterVariables: BuildFilterVariablesFn<SearchUsersVariables> = useCallback(filters => {
    const query = (takeFirst(filters.query) || '').trim()
    if (query) {
      return {
        where: {
          _or: query
            .split(' ')
            .filter(x => !!x)
            .map(part => [
              {
                first_name: {
                  _ilike: `%${part}%`
                }
              },
              {
                last_name: {
                  _ilike: `%${part}%`
                }
              },
              {
                email: {
                  _like: `%${part}%`
                }
              },
              {
                user_roles: {
                  client: {
                    name: {
                      _like: `%${part}%`
                    }
                  }
                }
              }
            ])
            .flat()
        }
      }
    }
    return {}
  }, [])

  const searchBag = useSearchQuery<SearchUsers_items, SearchUsersVariables, SearchUsers>({
    query: SearchUsersQuery,
    buildFilterVariables,
    defaultSort
  })

  const newUserUrl = usePathWithParams('/users/new')

  return (
    <InfiniteList
      searchBag={searchBag}
      selectedItemId={selectedItemId}
      renderItem={user => <UsersListItem user={user} />}
      filtersComponent={InfListQueryFilter}
      footerComponent={() => <InfListAddButton label='New user' to={newUserUrl} />}
    />
  )
}

export const UsersView: FC = () => (
  <L.Wrapper>
    <L.Side width={300}>
      <Routes>
        <Route path='/:id?' element={<UsersList />} />
      </Routes>
    </L.Side>
    <L.Content>
      <Routes>
        <Route path='/' element={<UsersIndexView />} />
        <Route path='/new' element={<CreateUserView />} />
        <Route path='/:id' element={<RoutedUpdateUserView />} />
      </Routes>
    </L.Content>
  </L.Wrapper>
)

const RoutedUpdateUserView = () => {
  const userId = useParams().id!
  return <UpdateUserView userId={userId} pathRoot={`/users/${userId}`} />
}
