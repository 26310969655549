import React, { useMemo, useCallback, FC } from 'react'
import { ColumnProps } from 'antd/lib/table'
import Button from 'antd/lib/button'
import { SearchCasecardsQuery } from '@/queries/casecards.queries'
import { SearchCasecards, SearchCasecardsVariables, SearchCasecards_items } from '@/queries/_gen_/SearchCasecards'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { GaphqlTable } from '@/components/table/GraphqlTable'
import { TextFilter } from '@/components/table/filters/TextFilter'
import { BuildFilterVariablesFn } from '@/hooks/useSearchQuery'
import Container from '@/components/layouts/Container'
import { amountRenderer } from '@/components/table/renderers/amountRenderer'
import { yesNoRenderer } from '@/components/table/renderers/yesNoRenderer'
import styled from 'styled-components'
import { Route, Routes, useNavigate, useParams, Link } from 'react-router-dom'
import { UpdateCasecardDrawer } from '@/views/casecards/components/UpdateCasecardDrawer'
import { usePathWithParams, usePathWithParamsBuilder } from '@/hooks/usePathWithParams'
import { EditOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons'
import { casecardStatusRenderer } from '@/components/table/renderers/casecardStatusRenderer'
import { useRole } from '@/hooks/useRole'
import { withSpan } from '@/components/contexts/TraceContext'
import { ButtonsContainer } from '@/components/ButtonsContainer'
import { CreateCasecardDrawer } from './components/CreateCasecardDrawer'
import { Debtor } from '@/models/debtor'
import { Casecard } from '@/models/casecard'
import { feeTypeRenderer } from '@/components/table/renderers/feeTypeRenderer'

const ActionsContainer = styled.div`
  white-space: nowrap;

  & > * + * {
    margin-left: 0.5em;
  }
`

export const CasecardsView = withSpan(
  'CasecardsView',
  () => ({}),
  () => {
    const baseUrl = usePathWithParams('/casecards')
    const buildPath = usePathWithParamsBuilder()
    const nav = useNavigate()
    const { isManager } = useRole()

    const columns: ColumnProps<SearchCasecards_items>[] = useMemo(
      () => [
        ...(isManager
          ? [
              {
                title: '',
                key: 'editcol',
                dataIndex: 'id',
                render: (casecardId: number) => (
                  <ActionsContainer>
                    <Link to={buildPath(`/casecards/${casecardId}`)} title='View Casecard'>
                      <EyeOutlined />
                    </Link>
                    <Link to={buildPath(`/casecards/${casecardId}/edit`)} title='Edit Casecard'>
                      <EditOutlined />
                    </Link>
                  </ActionsContainer>
                )
              }
            ]
          : []),
        {
          title: '',
          key: 'status',
          dataIndex: 'id',
          render: casecardStatusRenderer
        },
        {
          title: 'Case no',
          key: 'caseno',
          dataIndex: 'case_number',
          sorter: true,
          filterDropdown: TextFilter
        },
        {
          title: 'Customer',
          key: 'debtor',
          dataIndex: 'debtor',
          sorter: true,
          sortKey: 'debtor.name',
          render: (debtor: Debtor) => <Link to={`/debtors/${debtor.id}`}>{debtor.name}</Link>,
          filterDropdown: TextFilter
        },
        {
          title: 'Amt. Outstanding',
          key: 'amount_outstanding',
          dataIndex: 'amount_outstanding',
          align: 'right',
          render: amountRenderer,
          sorter: true
        },
        {
          title: 'Penalty',
          key: 'penalty_amount',
          dataIndex: 'penalty_amount',
          align: 'right',
          render: (value: string, record: Casecard) => feeTypeRenderer(value, record, 'penalty_type'),
          sorter: true
        },
        {
          title: 'Commission',
          key: 'commission_amount',
          dataIndex: 'commission_amount',
          align: 'right',
          render: (value: string, record: Casecard) => feeTypeRenderer(value, record, 'commission_type')
        },
        {
          title: 'Paid',
          key: 'is_paid',
          dataIndex: 'is_paid',
          filters: [
            { text: 'Yes', value: 'true' },
            { text: 'No', value: 'false' }
          ],
          sorter: true,
          render: yesNoRenderer
        }
      ],
      []
    )

    const client = useCurrentClient()

    const extraVariables: Partial<SearchCasecardsVariables> = useMemo(
      () => ({
        where: {
          debtor: {
            client_id: {
              _eq: client.id
            }
          }
        }
      }),
      [client]
    )

    const buildFilterVariables: BuildFilterVariablesFn<SearchCasecardsVariables> = useCallback(filters => {
      const retv: Partial<SearchCasecardsVariables> = {
        where: {
          ...(filters.debtor
            ? {
                debtor: {
                  name: {
                    _ilike: `%${filters.debtor}%`
                  }
                }
              }
            : {}),
          ...(filters.docno
            ? {
                document_number: {
                  _ilike: `%${filters.docno}%`
                }
              }
            : {}),
          ...(filters.is_paid && filters.is_paid.length === 1
            ? {
                is_paid: {
                  _eq: filters.is_paid[0] === 'true'
                }
              }
            : {})
        }
      }
      return retv
    }, [])

    return (
      <HeaderWithContent header='Case Cards'>
        <Container>
          <ButtonsContainer>
            <Button icon={<FilePdfOutlined />} href={'/casecards/new'} key='new_case_card'>
              New Case Card
            </Button>
          </ButtonsContainer>
          <GaphqlTable<SearchCasecards_items, SearchCasecardsVariables, SearchCasecards>
            columns={columns}
            query={SearchCasecardsQuery}
            extraVariables={extraVariables}
            buildFilterVariables={buildFilterVariables}
            emptyText='No Case Cards Found'
          />
        </Container>
        <Routes>
          <Route path='/new' element={<CreateCasecardDrawer path='' onClose={() => nav(baseUrl)} />} />
          <Route path='/:id/edit' element={<RoutedUpdateCasecardDrawer onClose={() => nav(baseUrl)} readOnly={false} />} />
          <Route path='/:id/*' element={<RoutedUpdateCasecardDrawer onClose={() => nav(baseUrl)} readOnly={true} />} />
        </Routes>
      </HeaderWithContent>
    )
  }
)

const RoutedUpdateCasecardDrawer: FC<{ onClose: () => void; readOnly: boolean }> = ({ onClose, readOnly }) => {
  const casecardId = Number(useParams().id!)
  return <UpdateCasecardDrawer onClose={onClose} path={`/casecards/${casecardId}`} casecardId={casecardId} readOnly={readOnly} />
}
