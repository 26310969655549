import React, { FC, useMemo, useCallback } from 'react'
import { InvoiceTemplate, InvoiceTemplateWithId } from '@/models/templates'
import { Formik, Form } from 'formik'
import { ShortFormikField, FormikField } from '@/components/form/FormikField'
import { ClientWithId } from '@/models/client'
import * as yup from 'yup'
import * as R from 'ramda'
import { LangaugeSelect } from './LanguageSelect'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { FormMutation } from '@/components/form/FormMutation'
import { TinymceInvoiceDocumentEditor } from '@/components/tinymce/TinymceEditor'
import { InsertInvoiceTemplate, InsertInvoiceTemplateVariables } from '@/queries/_gen_/InsertInvoiceTemplate'
import { InsertInvoiceTemplateMutation, UpdateInvoiceTemplateMutation } from '@/queries/invoicesTemplates.queries'
import { UpdateInvoiceTemplateVariables } from '@/queries/_gen_/UpdateInvoiceTemplate'
import Switch from 'antd/lib/switch'
import ApolloClient from 'apollo-client'
import { archivedInvoiceTemplateValidator } from '../templateValidators'
import { useApolloClient } from 'react-apollo'

// @TODO check that template with such name/language already exists
const validationSchema = (apollo: ApolloClient<any>, templateId?: number) =>
  yup.object().shape({
    name: yup.string().required('Required!'),
    language: yup.string().required('Required!'),
    content: yup.string().nullable(false),
    ...(templateId
      ? {
          archived: yup
            .boolean()
            .test(
              'archived',
              'Template cannot be archived: it is assigned to a category.',
              archivedInvoiceTemplateValidator(apollo, templateId)
            )
        }
      : {})
  })

type Values = Omit<InvoiceTemplate, 'client_id'>

interface TemplateFormProps {
  isNew: boolean
  values: Values
  loading: boolean
  client?: ClientWithId
  onSubmit: (values: Values) => void
}

const emptyTemplate: Values = {
  archived: false,
  language: '',
  name: '',
  content: ''
}

const TemplateInvoiceForm: FC<TemplateFormProps> = ({ values: vals, isNew, loading, onSubmit }) => {
  const apolloClient = useApolloClient()
  const vSchema = useMemo(() => validationSchema(apolloClient, vals.id), [apolloClient, vals.id])

  return (
    <Formik<Values> initialValues={vals} onSubmit={onSubmit} validationSchema={vSchema}>
      {({ setFieldValue, dirty }) => (
        <Form className='ant-form ant-form-vertical'>
          {!isNew && (
            <ShortFormikField name='archived' label='Archived'>
              {({ field }) => <Switch disabled={isNew} checked={field.value} onChange={checked => setFieldValue('archived', checked)} />}
            </ShortFormikField>
          )}
          <ShortFormikField name='name' label='Name' />
          <ShortFormikField name='language' label='Language'>
            {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ({ field: { onChange, ...fieldProps } }) => (
                <LangaugeSelect {...fieldProps} disabled={!isNew} onChange={value => setFieldValue('language', value)} />
              )
            }
          </ShortFormikField>
          <FormikField name='content' label='content'>
            {() => (
              <TinymceInvoiceDocumentEditor initialValue={vals.content || ''} onChange={content => setFieldValue('content', content)} />
            )}
          </FormikField>

          <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
            Save
          </FormSubmitButton>
        </Form>
      )}
    </Formik>
  )
}

interface CreateInvoiceTemplateFormProps {
  onSaved: (template: InvoiceTemplateWithId) => void
  client: ClientWithId
}

export const CreateInvoiceTemplateForm: FC<CreateInvoiceTemplateFormProps> = ({ client, onSaved }) => {
  const buildVariables = useCallback(
    (values: Values): InsertInvoiceTemplateVariables => {
      const { ...payload } = values
      return {
        payload: {
          ...payload,
          client_id: client.id
        }
      }
    },
    [client]
  )

  return (
    <FormMutation<Values, InsertInvoiceTemplate, InsertInvoiceTemplateVariables>
      buildVariables={buildVariables}
      successMessage='Template created!'
      onSaved={r => onSaved && r.result && r.result.returning.length && onSaved(r.result.returning[0])}
      mutation={InsertInvoiceTemplateMutation}
      refetchQueries={['GetInvoiceTemplateOptions', 'SearchInvoiceTemplates']}
    >
      {({ onSubmit, result: { loading } }) => (
        <TemplateInvoiceForm isNew={true} client={client} onSubmit={onSubmit} loading={loading} values={emptyTemplate} />
      )}
    </FormMutation>
  )
}

interface UpdateTemplateFormProps {
  client: ClientWithId
  template: InvoiceTemplateWithId
}

export const UpdateInvoiceTemplateForm: FC<UpdateTemplateFormProps> = ({ client, template }) => {
  const buildVariables = useCallback(
    (vals: Values): UpdateInvoiceTemplateVariables => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...payload } = vals
      return {
        payload: {
          ...R.omit(['language', 'client_id'], payload)
        },
        id: template.id
      }
    },
    [template.id]
  )

  const values = useMemo(
    () => ({
      ...template
    }),
    [template]
  )

  return (
    <FormMutation<Values, InsertInvoiceTemplate, InsertInvoiceTemplateVariables>
      buildVariables={buildVariables}
      successMessage='Template updated!'
      mutation={UpdateInvoiceTemplateMutation}
    >
      {({ onSubmit, result: { loading } }) => (
        <TemplateInvoiceForm isNew={false} client={client} onSubmit={onSubmit} loading={loading} values={values} />
      )}
    </FormMutation>
  )
}
