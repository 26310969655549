import { TemplateType } from "./template"
import { ContactType } from './contact'

export enum ReminderStatus {
  new = 'new',
  queued = 'queued',
  sent = 'sent',
  cancelled = 'cancelled',
  suspended = 'suspended',
  error = 'error'
}

export const reminderStatusNames: { [key in ReminderStatus]: string } = {
  [ReminderStatus.new]: 'New',
  [ReminderStatus.sent]: 'Sent',
  [ReminderStatus.queued]: 'Queued',
  [ReminderStatus.cancelled]: 'Cancelled',
  [ReminderStatus.suspended]: 'Suspended',
  [ReminderStatus.error]: 'Error'
}


export enum ReminderType {
  email = 'email',
  sms = 'sms',
  mail = 'mail',
  phone = 'phone'
}

export enum ReminderPeriod {
  late = 'late',
  notLate = 'not_late',
  all = 'all'
}

// wether three can be two reminders of this type for a client: one for late invoices and one for not late invoices
export const reminderType2HasPeriod: Record<ReminderType, boolean> = {
  [ReminderType.email]: true,
  [ReminderType.sms]: true,
  [ReminderType.phone]: false,
  [ReminderType.mail]: false
}

export const reminderTypesWithPDF = [ReminderType.email, ReminderType.mail]

export const reminderType2ContactType: { [key in ReminderType]: ContactType} = {
  [ReminderType.email]: ContactType.email,
  [ReminderType.sms]: ContactType.phone,
  [ReminderType.phone]: ContactType.phone,
  [ReminderType.mail]: ContactType.address
} 

export const autoSendableReminderTypes: ReminderType[] = [ReminderType.email, ReminderType.sms]

export const reminderTypes = Object.values(ReminderType)

export const reminderTypeNames: { [key in ReminderType]: string } = {
  [ReminderType.email]: 'Email',
  [ReminderType.sms]: 'SMS',
  [ReminderType.mail]: 'Mail',
  [ReminderType.phone]: 'Phone'
}

export const reminderType2TemplateType: { [key in ReminderType]: TemplateType | null } = {
  [ReminderType.email]: TemplateType.email,
  [ReminderType.sms]: TemplateType.sms,
  [ReminderType.mail]: TemplateType.mail,
  [ReminderType.phone]: null
}

export const templateType2ReminderType: { [key in TemplateType]: ReminderType } = {
  [TemplateType.email]: ReminderType.email,
  [TemplateType.sms]: ReminderType.sms,
  [TemplateType.mail]: ReminderType.mail
}


export enum ReminderSendRequestStatus {
  new = 'new',
  sent = 'sent',
  error = 'error'
}