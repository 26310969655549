export function arrayrepl<T>(array: T[], index: number, value: T): T[] {
  return Object.assign([], array, { [index]: value })
}
export function matrixrepl<T>(matrix: T[][], rowIndex: number, columnIndex: number, value: T): T[][] {
  return arrayrepl(matrix, rowIndex, arrayrepl(matrix[rowIndex], columnIndex, value))
}

export function formatArrayReadable(arr: string[]): string {
  return arr.map(item => `"${item}"`).join(', ')
}

export function makeItArray<T>(val: T | T[]): T[] {
  return Array.isArray(val) ? val : [val]
}

export const EMPTY_LIST: any[] = []

export const takeFirst = <T>(items: T | T[] | null | undefined, defaultValue?: T): T | null => {
  if (Array.isArray(items)) {
    return items.length ? items[0] : defaultValue === undefined ? null : defaultValue
  }
  if (items === undefined || items === null) {
    return defaultValue === undefined ? null : defaultValue
  }
  return items
}

export function removeNullish<A>(items: Array<A | null | undefined>): A[] {
  return items.filter((item): item is A => !!(item ?? false))
}
