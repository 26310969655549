import React, { FC } from 'react'
import { SearchReminders_items as Reminder } from '@/queries/_gen_/SearchReminders'
import List from 'antd/lib/list'
import Avatar from 'antd/lib/avatar'
import styled from 'styled-components'
import emailIcon from '@/images/email.svg'
import smsIcon from '@/images/sms.svg'
import { ReminderType, ReminderStatus } from '@/models/reminders'
import { ReminderStatusTag } from './ReminderStatusTag'
import { doesReminderHaveContacts } from '../reminter-utils'
import { formatDateTimeReadable } from '@/utils/date'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Checkbox } from 'antd'

const ListItemMeta = styled(List.Item.Meta)`
  .ant-avatar {
    background: none;
    color: black;
    border-radius: 0;
    position: relative;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 25px;
      height: 25px;
    }
  }
`

const ContactsMissingMsg = styled.span`
  color: red;
  margin-left: 1em;
`

const TsSpan = styled.span`
  display: block;
  color: #bfbfbf;
  font-size: 60%;
`

interface ReminderListItemProps {
  reminder: Reminder
}

const reminderType2Avatar: { [key in ReminderType]: React.ReactNode } = {
  [ReminderType.mail]: <Avatar size='large' icon={<MailOutlined />} />,
  [ReminderType.email]: <Avatar size='large' src={emailIcon} />,
  [ReminderType.sms]: <Avatar size='large' src={smsIcon} />,
  [ReminderType.phone]: <Avatar size='large' icon={<PhoneOutlined />} />
}

export const ReminderListItem: FC<ReminderListItemProps> = ({ reminder }) => {
  const hasContacts = doesReminderHaveContacts(reminder)

  return (
    <ListItemMeta
      avatar={
        <>
          <Checkbox value={reminder.id} />
          {reminderType2Avatar[reminder.type as ReminderType]}
        </>
      }
      title={
        <>
          {reminder.debtor.name}
          <ReminderStatusTag $floatRight={true} $reminderStatus={reminder.status as ReminderStatus} />
        </>
      }
      description={
        <>
          {!hasContacts && <ContactsMissingMsg>contacts missing!</ContactsMissingMsg>}
          <TsSpan>
            Created: {formatDateTimeReadable(reminder.created_at)}{' '}
            {reminder.updated_at && `Updated: ${formatDateTimeReadable(reminder.updated_at)}`}
          </TsSpan>
        </>
      }
    />
  )
}
