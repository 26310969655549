import React, { useMemo } from 'react'
import { CreateCreditorForm } from './components/CreditorGeneralForm'
import { CreditorViewLayout } from './components/CreditorViewLayout'
import { RoutedTabPane } from '@/components/RoutedTabs'
import Container from '@/components/layouts/Container'
import { useNavigate } from 'react-router'

export default () => {
  const nav = useNavigate()
  const tabs: RoutedTabPane[] = useMemo(
    () => [
      {
        name: 'General',
        path: '',
        render: () => (
          <Container>
            <CreateCreditorForm onSaved={creditor => nav(`/creditors/${creditor.id}`)} />
          </Container>
        )
      }
    ],
    [nav]
  )

  return <CreditorViewLayout header='New Creditor' tabs={tabs} pathRoot='/creditors/new' />
}
