import * as R from 'ramda'

export function emptyToNull<T extends Record<string, string | number | boolean | undefined | null>>(values: T): T {
  return R.mapObjIndexed(val => (val === '' || val === undefined ? null : val), values) as T
}

export function filterKeys<V>(obj: Record<string, V>, fn: (key: string, value: V) => boolean): Record<string, V> {
  return Object.keys(obj)
    .filter(key => fn(key, obj[key]))
    .reduce((newObj, key) => ({ ...newObj, [key]: obj[key] }), {})
}

export function mapObj<V, V2 = V>(
  obj: Record<string, V>,
  keyfn: (key: string) => string,
  vfn?: (value: V, key: string) => V2
): Record<string, V2> {
  return Object.keys(obj).reduce((newObj, key) => ({ ...newObj, [keyfn(key)]: vfn ? vfn(obj[key], key) : obj[key] }), {})
}

// turns 'foo.bar.baz' into { foo: { bar: { baz: order_direction }}}
export function dotNotationToNested<T>(path: string, value: T): unknown {
  return path
    .split('.')
    .reverse()
    .reduce<any>(
      (ordr, field) => ({
        [field]: ordr
      }),
      value
    )
}
// omitRecord({ a: 1, b: 1 }, { a: 1, b: 2, c: 3}) => { b: 2, c: 3 }
export function omitRecord<V>(omit: Record<string, V>, target: Record<string, V>): Record<string, V> {
  return filterKeys(target, key => !R.equals(target[key], omit[key]))
}

// replaceIntersectingValues({ a: 1, b: 1 }, null, { a: 1, b: 2, c: 3}) => { a: null, b: 2, c: 3 }
export function replaceIntersectingValues<V>(replace: Record<string, V>, value: V, target: Record<string, V>): Record<string, V> {
  return mapObj(
    target,
    k => k,
    (v, k) => (R.equals(replace[k], v) ? value : v)
  )
}
