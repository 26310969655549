import React, { FC, useEffect } from 'react'
import { useProfile } from '@/hooks/useProfile'
import { useBackendHttpCall } from '@/hooks/useBackendHttpCall'
import { ErrorMessage } from '@/components/ErrorMesssage'
import { logger } from '@/logger'
import Spinner from '@/components/Spinner'
import Container from '@/components/layouts/Container'
import { Navigate } from 'react-router-dom'

export const Logout: FC = () => {
  const profile = useProfile()
  const [logout, { error, response }] = useBackendHttpCall()

  useEffect(() => {
    if (profile) {
      logger.info(`logging out ${profile.id} ${profile.email}`)
      logout('/auth/logout', { method: 'POST' })
    }
  }, [profile, logout])

  useEffect(() => {
    if (response?.ok) {
      window.location.reload()
    }
  }, [response])

  if (error) {
    return (
      <Container>
        <ErrorMessage error={error} />
      </Container>
    )
  } else if (!profile) {
    return <Navigate to='/login' state={{ message: 'You have successfully logged out!' }} />
  } else {
    return (
      <Container $fitToContainer={true}>
        <Spinner tip='Logging you out...' noDelay={true} />
      </Container>
    )
  }
}
