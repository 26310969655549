import React, { FC, useMemo, useCallback } from 'react'
import { useBackendHttpCall } from '@/hooks/useBackendHttpCall'
import * as yup from 'yup'
import styled from 'styled-components'
import { CenteredCard } from '@/components/CenteredCard'
import { Alert } from 'antd'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { Formik } from 'formik'
import { logger } from '@/logger'
import { Form } from '@/components/form/Form'
import { FormikField } from '@/components/form/FormikField'
import { AuthContainer } from '@/components/layouts/AuthContainer'
import { passwordValidator } from './users/components/UserGeneralForm'
import { useParams, Navigate } from 'react-router-dom'

const SubmitButton = styled(FormSubmitButton)`
  float: right;
  margin-top: 20px;
`

interface ResetData {
  password: string
}

const initial: ResetData = {
  password: ''
}

const validationSchema = yup.object().shape({
  password: passwordValidator.required('Required!')
})

export const ResetPasswordComplete: FC = () => {
  const token = useParams().token!
  const [reset, { response, loading, error, data }] = useBackendHttpCall<{ error: string }, ResetData & { token: string }>()

  const errorMessage = useMemo((): string | null => {
    if (error) {
      return error.message
    }
    if (response && !response.ok) {
      return data?.error || 'Unexpected error. Sorry!'
    }
    return null
  }, [response, error, data])

  const onSubmit = useCallback(
    (values: ResetData) => {
      logger.info('trying to set new password')
      reset('/auth/reset_password', {
        body: {
          ...values,
          token
        },
        method: 'POST'
      })
    },
    [reset, token]
  )

  if (response?.ok) {
    return <Navigate to='/login' state={{ message: 'Password successfully changed!' }} />
  }

  return (
    <AuthContainer>
      <CenteredCard title='InvoRep Password Reset'>
        {!response?.ok && (
          <>
            {errorMessage && <Alert type='error' message={errorMessage} />}
            <p>Please enter new password and click "Change password"</p>
            <Formik<ResetData> initialValues={initial} onSubmit={onSubmit} validationSchema={validationSchema}>
              {({ dirty }) => (
                <Form className='ant-form'>
                  <FormikField name='password' placeholder='New password' type='password' autoFocus={true} />
                  <SubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
                    Change password
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          </>
        )}
      </CenteredCard>
    </AuthContainer>
  )
}
