import React from 'react'
import tinymce, { Editor, EditorAPI } from 'tinymce'
import ReactDOM from 'react-dom'
import { MarginsPluginConfigForm } from './MarginsPluginConfigForm'

export const MARGINS_PLUGIN_ID = 'margins'

const MOUNT_ID = `${MARGINS_PLUGIN_ID}_plugin_mount`

const RE_BODY_STYLE = /<body (style="padding.*").*>/

export const getContentWithMargins = (editor: Editor) => {
  let content = editor.getContent()
  // I don't know why, but here we get body with old styles, not what's in the content editable window...
  // so gotta remove the old styles
  const match = RE_BODY_STYLE.exec(content)
  if (match && match[1]) {
    content = content.replace(match[1], '')
  }
  return content.replace('<body', `<body style="${editor.getBody().style.cssText}"`)
}

function MarginsPlugin(editor: Editor) {
  const data = {
    values: {
      top: 1,
      left: 1,
      right: 1,
      bottom: 1
    }
  }
  editor.once('init', () => {
    const body = editor.getBody()
    if (body.style.paddingLeft) {
      data.values.left = Number(body.style.paddingLeft.replace('cm', ''))
    } else {
      body.style.paddingLeft = `${data.values.left}cm`
    }
    if (body.style.paddingRight) {
      data.values.right = Number(body.style.paddingRight.replace('cm', ''))
    } else {
      body.style.paddingRight = `${data.values.right}cm`
    }
    if (body.style.paddingTop) {
      data.values.top = Number(body.style.paddingTop.replace('cm', ''))
    } else {
      body.style.paddingTop = `${data.values.top}cm`
    }
    if (body.style.paddingBottom) {
      data.values.bottom = Number(body.style.paddingBottom.replace('cm', ''))
    } else {
      body.style.paddingBottom = `${data.values.bottom}cm`
    }
  })

  const openDialog = () => {
    const dialog = editor.windowManager.open({
      title: 'Margins',
      body: {
        type: 'panel',
        items: [
          {
            type: 'htmlpanel', // component type
            html: `<div id="${MOUNT_ID}"></div>`
          }
        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close'
        },
        {
          type: 'submit',
          text: 'Save',
          primary: true
        }
      ],
      onSubmit(api: EditorAPI) {
        const body = editor.getBody()
        const paddingValue = `${data.values.top}cm ${data.values.right}cm ${data.values.bottom}cm ${data.values.left}cm`
        editor.dom.setStyle(body, 'padding', paddingValue)
        body.dataset.mceStyle = paddingValue
        api.close()
        editor.fire('change')
      }
    })

    const mount = document.getElementById(MOUNT_ID)
    ReactDOM.render(<MarginsPluginConfigForm margins={data.values} onChange={values => (data.values = values)} />, mount)
    return dialog
  }

  // Add a button that opens a window
  editor.ui.registry.addButton(MARGINS_PLUGIN_ID, {
    text: 'Margins',
    onAction() {
      // Open window
      openDialog()
    }
  })

  // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
  editor.ui.registry.addMenuItem(MARGINS_PLUGIN_ID, {
    text: 'Margins',
    onAction() {
      // Open window
      openDialog()
    }
  })
}

tinymce.PluginManager.add(MARGINS_PLUGIN_ID, MarginsPlugin)
