import React, { FC } from 'react'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import Container from '@/components/layouts/Container'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { CreateInvoiceTemplateForm } from '@/views/templates/components/TemplateInvoiceForm'
import { useNavigate } from 'react-router'

export const CreateInvoiceTemplateView: FC = () => {
  const nav = useNavigate()
  return (
    <HeaderWithContent header='New template'>
      <Container>
        <CreateInvoiceTemplateForm onSaved={template => nav(`/templates/invoices/${template.id}`)} client={useCurrentClient()} />
      </Container>
    </HeaderWithContent>
  )
}
