import React, { ReactNode, FC, useMemo, useCallback } from 'react'
import AntdTabs, { TabsProps } from 'antd/lib/tabs'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { usePathWithParamsBuilder } from '@/hooks/usePathWithParams'
import styled from 'styled-components'
import { backgroundColor } from '@/style-vars'

export interface RoutedTabPane {
  name: string
  path: string
  render: () => ReactNode
}

export interface RoutedTabsProps extends Omit<TabsProps, 'activeKey' | 'onChange' | 'children'> {
  panes: RoutedTabPane[]
  pathRoot: string
  fitToContainer?: boolean
  className?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UnstyledRoutedTabs: FC<RoutedTabsProps> = ({ panes, pathRoot, fitToContainer, ...tabsProps }) => {
  const nav = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const activeKey = useMemo(() => {
    const key = pathname.replace(pathRoot, '').split('/').slice(0, 2).join('/')
    if (!panes.find(p => p.path === key)) {
      return ''
    }
    return key
  }, [pathname, pathRoot, panes])

  const pathWithParamsBuilder = usePathWithParamsBuilder()

  const onChange = useCallback(
    (newActiveKey: string) => nav(pathWithParamsBuilder(pathRoot + newActiveKey)),
    [nav, pathRoot, pathWithParamsBuilder]
  )

  if (!panes.find(p => p.path === activeKey)) {
    return <Navigate to={pathWithParamsBuilder(pathRoot + panes[0].path)} />
  }

  return (
    <AntdTabs {...tabsProps} activeKey={activeKey} onChange={onChange} destroyInactiveTabPane={true}>
      {panes.map(({ name, path, render }) => (
        <AntdTabs.TabPane tab={name} key={path}>
          {render()}
        </AntdTabs.TabPane>
      ))}
    </AntdTabs>
  )
}

export const RoutedTabs = styled(UnstyledRoutedTabs)`
  ${(props: RoutedTabsProps) =>
    props.type === 'card'
      ? `
    .ant-tabs-nav-container {
      background-color: ${backgroundColor};
    }

    .ant-tabs-bar {
      border-color: white;
    }

    .ant-tabs-tab {
      border-color: white !important;
    }
  `
      : ''}
  ${(props: RoutedTabsProps) =>
    props.fitToContainer
      ? `
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    & > .ant-tabs-content-holder > .ant-tabs-content {
      flex: 1;
      display: flex;
      height: 100%;
      flex-direction: column;
      overflow: hidden;
      & > .ant-tabs-tabpane-active {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

  `
      : ''}
`
