import { IdField } from '../utils'
import { GetExistingDebtorsAndInvoices_debtor_category_category_steps_template } from '@/queries/_gen_/GetExistingDebtorsAndInvoices'
import { ReminderPeriod, ReminderType } from 'common/models/reminder'
import { debtor_contact_insert_input, invoice_line_item_insert_input, invoice_insert_input } from '@/queries/_gen_global'
import { ColumnType, SheetFormat } from './xls'
import { FCategoryImportFields } from '@/queries/_gen_/FCategoryImportFields'

export type ContactFields = Pick<debtor_contact_insert_input, 'value' | 'city' | 'country' | 'post_code' | 'type'>

export type InvoiceLineItemFields = Pick<invoice_line_item_insert_input, 'name' | 'price' | 'product_id' | 'quantity'>

export interface InvoiceFields
  extends Pick<
    invoice_insert_input,
    | 'id'
    | 'document_number'
    | 'amount_outstanding'
    | 'due_date'
    | 'amount_wo_vat'
    | 'amount_with_vat'
    | 'vat'
    | 'document_date'
    | 'amount_paid'
    | 'currency'
    | 'invoice_type'
    | 'is_paid'
    | 'extra_1'
    | 'extra_2'
    | 'extra_3'
    | 'extra_4'
    | 'full_amount'
  > {
  invoice_line_items: InvoiceLineItemFields[]
}

type ExistingInvoiceFields = InvoiceFields & IdField

export interface ImportOptions {
  importInvoices: boolean
  generateReminders: boolean
  markOldInvoicesAsPaid: boolean
}

export const defaultImportOptions: ImportOptions = {
  importInvoices: true,
  generateReminders: true,
  markOldInvoicesAsPaid: true
}

export const sheetFormat2ImportOptions: { [key in SheetFormat]: ImportOptions } = {
  [SheetFormat.bd]: defaultImportOptions,
  [SheetFormat.silva]: defaultImportOptions,
  [SheetFormat.simple]: defaultImportOptions,
  [SheetFormat.simpleNoHeader]: defaultImportOptions,
  [SheetFormat.uprent]: defaultImportOptions,
  [SheetFormat.custom]: defaultImportOptions,
  [SheetFormat.sgb]: {
    importInvoices: true,
    generateReminders: false,
    markOldInvoicesAsPaid: false
  }
}

export interface ReminderFields {
  template: GetExistingDebtorsAndInvoices_debtor_category_category_steps_template | null
  reminder_type: ReminderType
  reminder_period: ReminderPeriod
  period_days_to: number
  period_days_from: number
  is_suspended: boolean
}

type PlanDebtor = {
  // @TODO are we gonna import more debtor fields?
  name: string
  company_code: string | null
}

export interface NewDebtor extends PlanDebtor {
  contacts: ContactFields[]
  invoices: InvoiceFields[]
  reminders: ReminderFields[]
  category_id?: number
}

export interface UpdateDebtor extends PlanDebtor, IdField {
  new_contacts: ContactFields[]
  new_invoices: InvoiceFields[]
  update_invoices: ExistingInvoiceFields[]
  new_reminders: ReminderFields[]
  update_fields: {
    customer_code_in_client_system?: string
    category_id?: number
    extra_1?: string
    extra_2?: string
    company_code?: string
  }
}

export interface Plan {
  import_options: ImportOptions
  new_debtors: NewDebtor[]
  update_debtors: UpdateDebtor[]
  next_invoice_number: number
  update_column_types?: ColumnType[]
}

export function isSameDebtor(
  debtor1: { name: string; company_code?: string | null },
  debtor2: { name: string; company_code?: string | null }
): boolean {
  return debtor1.company_code && debtor2.company_code ? debtor1.company_code === debtor2.company_code : debtor1.name === debtor2.name
}

export interface ImportContext {
  clientCategories: FCategoryImportFields[]
}
