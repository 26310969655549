import React, { useMemo } from 'react'
import { CreateDebtorForm } from './components/DebtorGeneralForm'
import { DebtorViewLayout } from './components/DebtorViewLayout'
import { RoutedTabPane } from '@/components/RoutedTabs'
import Container from '@/components/layouts/Container'
import { useNavigate } from 'react-router'

export default () => {
  const nav = useNavigate()
  const tabs: RoutedTabPane[] = useMemo(
    () => [
      {
        name: 'General',
        path: '',
        render: () => (
          <Container>
            <CreateDebtorForm onSaved={debtor => nav(`/debtors/${debtor.id}`)} />
          </Container>
        )
      }
    ],
    [nav]
  )

  return <DebtorViewLayout header='New debtor' tabs={tabs} pathRoot='/debtors/new' />
}
