import React, { FC, useMemo, useState } from 'react'
import { Dropdown, DropdownOption } from '../Dropdown'
import dateFnsParseDate from 'date-fns/parse'
import formatDate from 'date-fns/format'
import isDateValid from 'date-fns/isValid'
import { DatePicker } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import { RangePickerProps } from 'antd/lib/date-picker'
import { isMonday, isSunday, subDays } from 'date-fns'

const { RangePicker } = DatePicker

const InvisibleRangePicker = styled(RangePicker)`
  visibility: hidden;
  height: 0;
  width: 0;
  margin-left: -50px;
`

export interface WhenFilterProps {
  value: string | null
  onChange: (value: string | null) => void
}

const DATE_FMT = 'yyyy-MM-dd'

interface DateRange {
  from: Date
  to: Date
}

const parseCustomRange = (value: string): DateRange | undefined => {
  if (value.includes('_')) {
    const [fromStr, toStr] = value.split('_')
    const from = dateFnsParseDate(fromStr, DATE_FMT, new Date())
    const to = dateFnsParseDate(toStr, DATE_FMT, new Date())
    if (isDateValid(from) && isDateValid(to)) {
      return { from, to }
    }
  }
  return undefined
}

const formatCustomRange = (range: DateRange): string => {
  const fromStr = formatDate(range.from, DATE_FMT)
  const toStr = formatDate(range.to, DATE_FMT)
  if (fromStr === toStr) {
    return fromStr
  }
  return `${fromStr} - ${toStr}`
}

// @TODO in distant future:
// make start of week configurable. Not everyone starts on monday...
export const valueToDateRange = (value: string): DateRange | undefined => {
  if (value === 'today') {
    return {
      from: new Date(),
      to: new Date()
    }
  } else if (value === 'yesterday') {
    return {
      from: subDays(new Date(), 1),
      to: subDays(new Date(), 1)
    }
  } else if (value === 'thisweek') {
    const to = new Date()
    let from = to
    while (!isMonday(from)) {
      from = subDays(from, 1)
    }
    return {
      from,
      to
    }
  } else if (value === 'lastweek') {
    let to = new Date()
    let from = to
    if (isSunday(to)) {
      to = subDays(to, 7)
      from = subDays(to, 6)
    } else {
      while (!isSunday(to)) {
        to = subDays(to, 1)
      }
      from = subDays(to, 6)
    }
    return {
      from,
      to
    }
  } else if (value.includes('_')) {
    return parseCustomRange(value)
  }
  return undefined
}

export const WhenFilter: FC<WhenFilterProps> = ({ value, onChange }) => {
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false)

  const customRange: DateRange | undefined = useMemo(() => {
    if (value && value.includes('_')) {
      return parseCustomRange(value)
    }
    return undefined
  }, [value])

  const dateOptions = useMemo(
    (): DropdownOption[] => [
      {
        value: 'alltime',
        label: 'All time'
      },
      {
        value: 'today',
        label: 'Today'
      },
      {
        value: 'yesterday',
        label: 'Yesterday'
      },
      {
        value: 'thisweek',
        label: 'This week'
      },
      {
        value: 'lastweek',
        label: 'Last week'
      },
      {
        value: 'custom',
        label: 'Custom...',
        selectedLabel: customRange && !isRangePickerOpen ? formatCustomRange(customRange) : undefined
      }
    ],
    [customRange, isRangePickerOpen]
  )

  const onDDChange = (value: string) => {
    if (value === 'custom') {
      setIsRangePickerOpen(true)
    } else {
      onChange(value)
    }
  }

  const onRangeChange: RangePickerProps['onChange'] = values => {
    const from = values?.[0]
    const to = values?.[1]
    if (from && to) {
      onChange(`${formatDate(from.toDate(), DATE_FMT)}_${formatDate(to.toDate(), DATE_FMT)}`)
    }
    setIsRangePickerOpen(false)
  }

  return (
    <>
      <Dropdown
        label='When'
        options={dateOptions}
        value={customRange || isRangePickerOpen ? 'custom' : value || 'alltime'}
        onChange={onDDChange}
      />
      <InvisibleRangePicker
        value={customRange ? [moment(customRange.from), moment(customRange.to)] : null}
        open={isRangePickerOpen}
        onOpenChange={setIsRangePickerOpen}
        onChange={onRangeChange}
      />
    </>
  )
}
