import React, { ReactNode } from 'react'
import Layout from 'antd/lib/layout'
import styled from 'styled-components'
import { Sidebar } from '@/components/Sidebar'

interface Props {
  children: ReactNode
}

const TopLevelLayout = styled(Layout)`
  height: 100vh;
`

const Content = styled(Layout.Content)`
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export default ({ children }: Props) => (
  <TopLevelLayout>
    <>
      <Sidebar />
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </>
  </TopLevelLayout>
)
