import XLSX, { WorkSheet, utils, WorkBook } from 'xlsx'
import { Sheet, RawWorkbook, RawSheet, SheetFormat, CurrentWorkbook, SheetColumn, ColumnType, SheetConfig } from '@/models/import/xls'
import { columnTypes, RowValues, parseDate } from '@/views/import/columns'
import * as R from 'ramda'
import Decimal from 'decimal.js'
import { arrayrepl } from './array'
import { logger } from '@/logger'
import { ImportContext } from '@/models/import/plan'
import ApolloClient from 'apollo-client'
import { GetImportFileColumnConfigQuery } from '@/queries/import.queries'
import { GetImportFileColumnConfig, GetImportFileColumnConfigVariables } from '@/queries/_gen_/GetImportFileColumnConfig'

export function parseXLSFile(file: File): Promise<XLSX.WorkBook> {
  /*
      @TODO
      This POC level, to make it prod ready:
      1) handle errors
      2) move xls parsing to worker thread
      3) eat cake
      4) test with all browsers / multiple xls versions
  */
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      try {
        const rawData = e.target.result
        resolve(XLSX.read(rawData, { type: 'binary', cellText: false, dateNF: 'YYYY-MM-DD', cellDates: true }))
      } catch (e) {
        logger.error('Error parsing xls file', e as Error)
        reject(e)
      }
    }
    reader.onerror = (e: ProgressEvent) => {
      logger.error(`Error reading xls file, ${e}`)
      reject(e)
    }
    setTimeout(() => reader.readAsBinaryString(file), 100)
  })
}

function removeNullUnicodeChars(x: unknown): unknown {
  if (typeof x === 'string') {
    return x.replace(/\0/g, '')
  }
  return x
}

export function parseXLSJSSheet(sheet: WorkSheet, name: string): RawSheet {
  const data = utils.sheet_to_json(sheet, { header: 1, raw: false, dateNF: 'YYYY-MM-DD' })
  const rows = Array.from(data as unknown[][])
    .filter(row => row && row.find(x => x !== undefined && x !== null))
    .map(row => Array.from(row || []))
  const maxRowLength = rows.reduce((maxlen, row) => Math.max(maxlen, row.length), 0)
  return {
    name,
    rows: rows.map(row => R.range(0, maxRowLength).map(idx => removeNullUnicodeChars(row[idx]))) // pad each row to longest row
  }
}

export function parseXLSJSWorkbook(workbook: WorkBook): RawWorkbook {
  return {
    sheets: workbook.SheetNames.slice()
      .sort()
      .map(sheetName => parseXLSJSSheet(workbook.Sheets[sheetName], sheetName))
  }
}

const label2ColumnType: Record<string, ColumnType> = {
  'Invoice No.': ColumnType.documentNumber,
  InvcDt: ColumnType.documentDate,
  DueDt: ColumnType.dueDate,
  // 'Payer, company ID': ColumnType.companyCode,  // not realy company code, should be reference id?
  'Company name': ColumnType.companyName,
  'Invc total excl tax': ColumnType.amountWithoutVat,
  'VAT/tax, BCU': ColumnType.vat,
  'Invoice total incl tax': ColumnType.amountWithVat,
  OutstAmt: ColumnType.amountOutstanding,
  'Due date': ColumnType.dueDate,
  Currency: ColumnType.currency,
  'EUR Dr': ColumnType.amountOutstanding,
  'Short name': ColumnType.companyName,
  'Doc. date': ColumnType.documentDate,
  Number: ColumnType.documentNumber,
  Nr: ColumnType.documentNumber,
  Company: ColumnType.companyName,
  'Invoice Date': ColumnType.documentDate,
  'Payment Due': ColumnType.dueDate, // trailing space not a typo, fixes a bug when switching from SILVA to BD, lolz
  Remaining: ColumnType.amountOutstanding,
  'Business EMail': ColumnType.email,
  Sum: ColumnType.amountWithVat,
  'Company Accounting System NR': ColumnType.customerCodeInClientSystem
}

export function simpleExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  /*
  simple sheet with header, no transform.
  tries to guess column type based on label
  */
  const columnTypesUsed = new Set<ColumnType>()
  const columnTypes: ColumnType[] = (sheet.rows.length ? sheet.rows[0] : []).map(c => {
    const label = String(c)
    let columnType = ColumnType.skip
    const columnTypeGuess = label2ColumnType[label]
    if (columnTypeGuess && !columnTypesUsed.has(columnTypeGuess)) {
      columnType = columnTypeGuess
      columnTypesUsed.add(columnTypeGuess)
    }
    return columnType
  })

  return customExtractor(sheet, columnTypes)
}

export function simpleExtractorNoHeader(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  return customExtractor(sheet, [], true)
}

export function customExtractor(sheet: RawSheet, columnTypes: ColumnType[], noHeaders = false): [SheetColumn[], unknown[][]] {
  // pad out columns up to number of values in longest row
  const maxColumns = sheet.rows.reduce((len, row) => Math.max(len, row.length), 0)

  const columns: SheetColumn[] = (sheet.rows.length ? sheet.rows[0] : []).map((c, idx) => {
    const label = noHeaders ? '?' : String(c)
    return { label, type: columnTypes[idx] || ColumnType.skip }
  })

  while (columns.length < maxColumns) {
    columns.push({
      label: `Column ${columns.length + 1}`,
      type: ColumnType.skip
    })
  }
  const rows = noHeaders ? sheet.rows : sheet.rows.slice(1)
  return [columns, rows]
}

export function SILVAExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  // hardcoded columns
  const columns = [
    { label: 'Dokumento Nr.', type: ColumnType.documentNumber },
    { label: 'Kliento kodas', type: ColumnType.companyCode },
    { label: 'Kliento pav.', type: ColumnType.companyName },
    { label: 'Išraš. data', type: ColumnType.documentDate },
    { label: 'Mok.data', type: ColumnType.dueDate },
    { label: 'Vėlavimo dienos', type: ColumnType.skip },
    { label: 'Skola', type: ColumnType.amountOutstanding }
  ]

  // and invoices are rows with 7 real values
  const rows = sheet.rows.map(row => row.filter(val => val !== undefined)).filter(row => row.length === 7 && row[0] !== columns[0].label)
  return [columns, rows]
}
export function UPRentExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  // Order id	Subscription id	Ledger	Product id	Product description	User id	First name	Last name	Personal number	Adress	City	Land	Zipcode	Telephone	Email	Total	Text	Approved	Reason	Invoice date

  const columns = [
    { label: 'Kliento pavadinimas', type: ColumnType.companyName },
    { label: 'n/a', type: ColumnType.skip },
    { label: 'Dokumento Nr.', type: ColumnType.documentNumber },
    { label: 'Dok.data', type: ColumnType.documentDate },
    { label: 'Mok.data', type: ColumnType.dueDate },
    { label: 'Debetas', type: ColumnType.amountWithVat },
    { label: 'Kreditas', type: ColumnType.amountPaid },
    { label: 'Dokumento suma', type: ColumnType.amountOutstanding },
    { label: 'Vėlav. d.', type: ColumnType.skip }
  ]

  return [columns, sheet.rows.slice(1)]
}

export function BDExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  const columns = [
    { label: 'Dokumento Nr.', type: ColumnType.documentNumber },
    { label: 'Kliento kodas', type: ColumnType.companyCode },
    { label: 'Kliento pav.', type: ColumnType.companyName },
    { label: 'Išraš. data', type: ColumnType.documentDate },
    { label: 'Mok.data ', type: ColumnType.dueDate }, // trailing space not a typo, fixes a bug when switching from SILVA to BD, lolz
    { label: 'Vėlavimo dienos', type: ColumnType.skip },
    { label: 'Skola', type: ColumnType.amountOutstanding }
  ]

  const rows = sheet.rows.map(row => row.filter(val => val !== undefined))
  return [columns, rows]
}

export function SGBExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  // Order id	Subscription id	Ledger	Product id	Product description	User id	First name	Last name	Personal number	Adress	City	Land	Zipcode	Telephone	Email	Total	Text	Approved	Reason	Invoice date

  const columns = [
    { label: 'Order id', type: ColumnType.invoiceExtra1 },
    { label: 'Subscription id', type: ColumnType.invoiceExtra2 },
    { label: 'Ledger', type: ColumnType.skip },
    { label: 'Product id', type: ColumnType.lineProductId },
    { label: 'Product description', type: ColumnType.lineProductName },
    { label: 'User id', type: ColumnType.customerCodeInClientSystem },
    { label: 'First name', type: ColumnType.firstName },
    { label: 'Last name', type: ColumnType.lastName },
    { label: 'Personal number', type: ColumnType.companyCode },
    { label: 'Address', type: ColumnType.address },
    { label: 'City', type: ColumnType.city },
    { label: 'Land', type: ColumnType.country },
    { label: 'Zipcode', type: ColumnType.postCode },
    { label: 'Telephone', type: ColumnType.phoneNumber },
    { label: 'Email', type: ColumnType.email },
    { label: 'Total', type: ColumnType.linePrice },
    { label: 'Text', type: ColumnType.skip },
    { label: 'Approved', type: ColumnType.skip },
    { label: 'Reason', type: ColumnType.skip },
    { label: 'Invoice date', type: ColumnType.documentDate }
  ]

  return [columns, sheet.rows.slice(1)]
}

type SheetExtractor = (sheet: RawSheet, columnTypes: ColumnType[]) => [SheetColumn[], unknown[][]]

export const sheetExtractors: { [key in SheetFormat]: SheetExtractor } = {
  [SheetFormat.simpleNoHeader]: simpleExtractorNoHeader,
  [SheetFormat.simple]: simpleExtractor,
  [SheetFormat.silva]: SILVAExtractor,
  [SheetFormat.bd]: BDExtractor,
  [SheetFormat.sgb]: SGBExtractor,
  [SheetFormat.uprent]: UPRentExtractor,
  [SheetFormat.custom]: customExtractor
}

export const guessFormat = async (workbook: RawWorkbook, clientId: number, apolloClient: ApolloClient<unknown>): Promise<SheetConfig> => {
  if (workbook.sheets.length) {
    const sheet = workbook.sheets[0]
    const rawColumns = sheet.rows[0]
    if (sheet.rows[0] && String(sheet.rows[0][0]).includes('Filtravimo sąlygos:')) {
      return { format: SheetFormat.silva }
    }
    if (
      sheet.rows[0] &&
      sheet.rows[0].length === 10 &&
      sheet.rows[0][0] !== undefined &&
      sheet.rows[0][1] === undefined &&
      sheet.rows[0][2] !== undefined &&
      sheet.rows[0][3] === undefined &&
      !isNaN(parseFloat(String(sheet.rows[0][9])))
    ) {
      return { format: SheetFormat.bd }
    }
    if (!R.without(['Ledger', 'Order id', 'Subscription id', 'First name', 'Last name', 'Zipcode', 'Land'], sheet.rows[0]).length) {
      return { format: SheetFormat.sgb }
    }

    const config = await apolloClient.query<GetImportFileColumnConfig, GetImportFileColumnConfigVariables>({
      query: GetImportFileColumnConfigQuery,
      variables: { column_count: rawColumns.length, client_id: clientId },
      fetchPolicy: 'network-only'
    })
    if (config.data?.import_file_column_config?.length) {
      return {
        format: SheetFormat.custom,
        columnTypes: JSON.parse(config.data?.import_file_column_config?.[0].config)
      }
    }

    if (
      !R.without(
        ['Kliento pavadinimas', '', 'Dokumento Nr.', 'Dok.data', 'Mok.data', 'Debetas', 'Kreditas', 'Dokumento suma', 'Vėlav. d.'],
        sheet.rows[0].map(x => x || '')
      ).length &&
      sheet.rows[0].length === 9
    ) {
      return { format: SheetFormat.uprent }
    }
  }
  return { format: SheetFormat.simple }
}

export const validateValue = (value: string, columnType: ColumnType, context: ImportContext): string[] => {
  const { validators, required } = columnTypes[columnType]
  if (required && !value) {
    return ['Required.']
  }
  if (value && validators && validators.length) {
    return R.flatten(validators.map(validator => validator(value, context)))
  }
  return []
}

const validateRows = (rows: string[][], columns: SheetColumn[], context: ImportContext): string[][][] =>
  rows.map(row => row.map((value, colIdx) => validateValue(value, columns[colIdx].type, context)))

// @TODO how to handle errors?..
export const extractFormat = (config: SheetConfig, workbook: RawWorkbook, context: ImportContext): CurrentWorkbook => ({
  selectedSheetName: workbook.sheets[0].name,
  format: config.format,
  sheets: workbook.sheets.reduce((map, sheet) => {
    const [columns, rows] = sheetExtractors[config.format](sheet, config.columnTypes || [])
    if (rows[0] && rows[0].length < columns.length) {
      throw new Error('Not enough columns in sheet format')
    }
    const stringifiedRows = rows.map(row =>
      row.map(value => {
        if (value === null || value === undefined) {
          return ''
        }
        return String(value)
      })
    )
    map[sheet.name] = {
      name: sheet.name,
      columns,
      rows: stringifiedRows,
      errors: complexValidation(
        stringifiedRows,
        validateRows(stringifiedRows, columns, context),
        columns.map(c => c.type)
      )
    }
    return map
  }, {} as { [key: string]: Sheet })
})

export function sheetToValues(sheet: Sheet): RowValues[] {
  const colType2Index = new Map(sheet.columns.map((col, idx) => [col.type, idx]))

  function getVal(row: string[], type: ColumnType): undefined | string {
    const idx = colType2Index.get(type)
    return idx !== undefined ? row[idx] : undefined
  }

  function parseVal<T>(row: string[], type: ColumnType, parser: (val: string) => T): T | undefined {
    const rawVal = getVal(row, type)
    if (rawVal === undefined || rawVal === '') {
      return undefined
    }
    return parser(rawVal)
  }

  const stringParser = (val: string): string => String(val).trim()
  const dateParser = (val: string): Date => {
    const v = parseDate(val)
    if (!v) {
      throw new Error(`Expect ${val} to be valid date`)
    }
    return v
  }
  const amountParser = (val: string): Decimal => new Decimal(val)

  return sheet.rows.map(row => ({
    [ColumnType.companyCode]: parseVal(row, ColumnType.companyCode, stringParser),
    [ColumnType.companyName]: parseVal(row, ColumnType.companyName, stringParser),
    [ColumnType.documentDate]: parseVal(row, ColumnType.documentDate, dateParser),
    [ColumnType.documentNumber]: parseVal(row, ColumnType.documentNumber, stringParser),
    [ColumnType.dueDate]: parseVal(row, ColumnType.dueDate, dateParser),
    [ColumnType.amountWithoutVat]: parseVal(row, ColumnType.amountWithoutVat, amountParser),
    [ColumnType.amountWithVat]: parseVal(row, ColumnType.amountWithVat, amountParser),
    [ColumnType.amountPaid]: parseVal(row, ColumnType.amountPaid, amountParser),
    [ColumnType.vat]: parseVal(row, ColumnType.vat, amountParser),
    [ColumnType.amountOutstanding]: parseVal(row, ColumnType.amountOutstanding, amountParser),
    [ColumnType.email]: parseVal(row, ColumnType.email, stringParser),
    [ColumnType.email2]: parseVal(row, ColumnType.email2, stringParser),
    [ColumnType.email3]: parseVal(row, ColumnType.email3, stringParser),
    [ColumnType.phoneNumber]: parseVal(row, ColumnType.phoneNumber, stringParser),
    [ColumnType.phoneNumber2]: parseVal(row, ColumnType.phoneNumber2, stringParser),
    [ColumnType.phoneNumber3]: parseVal(row, ColumnType.phoneNumber3, stringParser),
    [ColumnType.address]: parseVal(row, ColumnType.address, stringParser),
    [ColumnType.currency]: parseVal(row, ColumnType.currency, stringParser),
    [ColumnType.customerCodeInClientSystem]: parseVal(row, ColumnType.customerCodeInClientSystem, stringParser),
    [ColumnType.linePrice]: parseVal(row, ColumnType.linePrice, amountParser),
    [ColumnType.lineQuantity]: parseVal(row, ColumnType.lineQuantity, amountParser),
    [ColumnType.lineProductId]: parseVal(row, ColumnType.lineProductId, stringParser),
    [ColumnType.lineProductName]: parseVal(row, ColumnType.lineProductName, stringParser),
    [ColumnType.firstName]: parseVal(row, ColumnType.firstName, stringParser),
    [ColumnType.lastName]: parseVal(row, ColumnType.lastName, stringParser),
    [ColumnType.city]: parseVal(row, ColumnType.city, stringParser),
    [ColumnType.country]: parseVal(row, ColumnType.country, stringParser),
    [ColumnType.postCode]: parseVal(row, ColumnType.postCode, stringParser),
    [ColumnType.invoiceExtra1]: parseVal(row, ColumnType.invoiceExtra1, stringParser),
    [ColumnType.invoiceExtra2]: parseVal(row, ColumnType.invoiceExtra2, stringParser),
    [ColumnType.invoiceExtra3]: parseVal(row, ColumnType.invoiceExtra3, stringParser),
    [ColumnType.invoiceExtra4]: parseVal(row, ColumnType.invoiceExtra4, stringParser),
    [ColumnType.debtorExtra1]: parseVal(row, ColumnType.debtorExtra1, stringParser),
    [ColumnType.debtorExtra2]: parseVal(row, ColumnType.debtorExtra2, stringParser),
    [ColumnType.debtorCategory]: parseVal(row, ColumnType.debtorCategory, stringParser),
    [ColumnType.fullAmount]: parseVal(row, ColumnType.fullAmount, amountParser)
  }))
}

const DUPLICATE_DOCUMENT_NUMBER_MSG = 'Duplicate document number.'
const validateDocumentNumberUniqueness = (rows: string[][], errors: string[][][], columnTypes: ColumnType[]): string[][][] => {
  if (columnTypes.includes(ColumnType.documentNumber)) {
    const docNoColIdx = columnTypes.indexOf(ColumnType.documentNumber)
    const key = (row: string[]) => row[docNoColIdx]
    const documentNumberCounts = R.countBy(key, rows)
    return errors.map((errorRow, rowIdx) => {
      const countKey = key(rows[rowIdx])
      const colErrors = errorRow[docNoColIdx]
      if (documentNumberCounts[countKey] > 1) {
        if (!colErrors.includes(DUPLICATE_DOCUMENT_NUMBER_MSG)) {
          return arrayrepl(errorRow, docNoColIdx, [...colErrors, DUPLICATE_DOCUMENT_NUMBER_MSG])
        }
      } else if (colErrors.includes(DUPLICATE_DOCUMENT_NUMBER_MSG)) {
        return arrayrepl(errorRow, docNoColIdx, R.without([DUPLICATE_DOCUMENT_NUMBER_MSG], colErrors))
      }

      return errorRow
    })
  }
  return errors
}

const validateCompanyNameAndCodeUniqueness = (rows: string[][], errors: string[][][], columnTypes: ColumnType[]): string[][][] => {
  if (columnTypes.includes(ColumnType.companyCode) && columnTypes.includes(ColumnType.companyName)) {
    const companyCodeColIdx = columnTypes.indexOf(ColumnType.companyCode)
    const companyNameColIdx = columnTypes.indexOf(ColumnType.companyName)

    const companyName2Code: Record<string, string> = {}

    return errors.map((errorRow, rowIdx) => {
      const name = rows[rowIdx][companyNameColIdx]
      const code = rows[rowIdx][companyCodeColIdx]
      const colErrors = errorRow[companyCodeColIdx]
      if (!companyName2Code[name]) {
        companyName2Code[name] = code
      } else if (companyName2Code[name] !== code) {
        const msg = `Row ${rowIdx + 1} has the same company name, but different company code ${
          companyName2Code[name]
        }. Name and code must always match.`
        return arrayrepl(errorRow, companyCodeColIdx, [
          ...colErrors.filter(err => !err.includes('has the same company name, but different company code')),
          msg
        ])
      }
      if (colErrors.find(err => err.includes('has the same company name, but different company code'))) {
        return arrayrepl(
          errorRow,
          companyCodeColIdx,
          colErrors.filter(err => !err.includes('has the same company name, but different company code'))
        )
      }
      return errorRow
    })
  }
  return errors
}

// validation logic is overcomplicated and needs rethinking. but I'm tired, sorry
export const complexValidation = (rows: string[][], errors: string[][][], columnTypes: ColumnType[]): string[][][] =>
  [validateCompanyNameAndCodeUniqueness, validateDocumentNumberUniqueness].reduce(
    (errs, validator) => validator(rows, errs, columnTypes),
    errors
  )
