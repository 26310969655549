import { reminderType2ContactType, ReminderType } from '@/models/reminders'

interface PartialReminder {
  type: string
  debtor: {
    debtor_contacts: Array<{ type: string; is_enabled?: boolean }>
  }
}

export function doesReminderHaveContacts(reminder: PartialReminder): boolean {
  return !!reminder.debtor.debtor_contacts.find(
    contact =>
      contact.type === reminderType2ContactType[reminder.type as ReminderType] &&
      (contact.is_enabled === undefined || contact.is_enabled === true)
  )
}
