import React, { FC } from 'react'
import { FEventFields, FEventFields_debtor, FEventFields_invoices, FEventFields_reminders_reminder } from '@/queries/_gen_/FEventFields'
import { reminderTypeNames, ReminderType } from '@/models/reminders'
import { Link } from 'react-router-dom'

const ReminderRenderer: FC<{ reminder: FEventFields_reminders_reminder }> = ({ reminder }) => (
  <Link to={`/reminders/${reminder.id}`}>{reminderTypeNames[reminder.type as ReminderType]} reminder</Link>
)

const InvoiceRenderer: FC<{ invoices: FEventFields_invoices[] }> = ({ invoices }) =>
  invoices.length === 1 ? (
    <>
      invoice <Link to={`/invoices/${invoices[0]?.invoice.id}`}>{invoices[0]?.invoice.document_number}</Link>
    </>
  ) : (
    <>invoices {invoices.map(i => i.invoice.document_number).join(', ')}</>
  )

const DebtorRenderer: FC<{ debtor: FEventFields_debtor }> = ({ debtor }) => <Link to={`/debtors/${debtor.id}`}>{debtor.name}</Link>

export const EventSubject: FC<{ event: FEventFields }> = ({ event }) => {
  const reminder = event.reminders[0]?.reminder
  if (reminder) {
    return <ReminderRenderer reminder={reminder} />
  }
  const invoices = event.invoices
  if (invoices && invoices.length) {
    return <InvoiceRenderer invoices={invoices} />
  }
  return <DebtorRenderer debtor={event.debtor} />
}
