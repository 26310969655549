import React from 'react'
import styled from 'styled-components'
import { ReminderStatus, reminderStatusNames } from '@/models/reminders'

interface ReminderStatusTagProps {
  $reminderStatus: ReminderStatus
  $floatRight?: boolean
}

const reminderStatus2Color: { [key in ReminderStatus]: string } = {
  [ReminderStatus.sent]: 'green',
  [ReminderStatus.new]: 'blue',
  [ReminderStatus.cancelled]: '#D7D715',
  [ReminderStatus.suspended]: 'orange',
  [ReminderStatus.error]: 'red',
  [ReminderStatus.queued]: 'blue'
}

const ReminderStatusTagSpan = styled.span<ReminderStatusTagProps>`
  font-weight: bold;
  ${({ $floatRight }: ReminderStatusTagProps) => ($floatRight ? 'float: right;' : '')}
  color: ${({ $reminderStatus }: ReminderStatusTagProps) => reminderStatus2Color[$reminderStatus]};
`

export const ReminderStatusTag = (props: ReminderStatusTagProps) => (
  <ReminderStatusTagSpan {...props}>{reminderStatusNames[props.$reminderStatus].toLocaleLowerCase()}</ReminderStatusTagSpan>
)
