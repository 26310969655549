import React, { FC } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import { UserRole, userRoles, UserRoleNames } from 'common/models/user'

const { Option } = Select

export const UserRoleSelect: FC<SelectProps<UserRole>> = ({ value, ...props }) => (
  <Select {...props} value={value || undefined} placeholder='Select client...'>
    {userRoles.map(role => (
      <Option key={role} value={role}>
        {UserRoleNames[role]}
      </Option>
    ))}
  </Select>
)
