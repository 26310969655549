import React, { useMemo, useCallback, FC } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { SearchInvoicesQuery } from '@/queries/invoices.queries'
import { SearchInvoices, SearchInvoicesVariables, SearchInvoices_items } from '@/queries/_gen_/SearchInvoices'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { GaphqlTable } from '@/components/table/GraphqlTable'
import { TextFilter } from '@/components/table/filters/TextFilter'
import { BuildFilterVariablesFn } from '@/hooks/useSearchQuery'
import Container from '@/components/layouts/Container'
import { dateRenderer } from '@/components/table/renderers/dateRenderer'
import { amountRenderer } from '@/components/table/renderers/amountRenderer'
import { yesNoRenderer } from '@/components/table/renderers/yesNoRenderer'
import { takeFirst } from '@/utils/array'
import styled from 'styled-components'
import { DownloadInvoicesButton } from '@/components/DownloadInvoicesButton'
import { Route, Routes, useNavigate, useParams, Link } from 'react-router-dom'
import { UpdateInvoiceDrawer } from '@/views/invoices/components/UpdateInvoiceDrawer'
import { usePathWithParams, usePathWithParamsBuilder } from '@/hooks/usePathWithParams'
import { EditOutlined, MessageOutlined } from '@ant-design/icons'
import { Debtor } from '@/models/debtor'
import { invoiceStatusRenderer } from '@/components/table/renderers/invoiceStatusRenderer'
import { useRole } from '@/hooks/useRole'
import { withSpan } from '@/components/contexts/TraceContext'
import { stages, getStage } from '@/models/stage'

const DownloadButton = styled(DownloadInvoicesButton)`
  margin-bottom: 0.5em;
`

const ActionsContainer = styled.div`
  white-space: nowrap;

  & > * + * {
    margin-left: 0.5em;
  }
`

export const InvoicesView = withSpan(
  'InvoicesView',
  () => ({}),
  () => {
    const baseUrl = usePathWithParams('/invoices')
    const buildPath = usePathWithParamsBuilder()
    const nav = useNavigate()
    const { isManager } = useRole()
    const stage = getStage(useParams().stage)

    const columns: ColumnProps<SearchInvoices_items>[] = useMemo(
      () => [
        ...(isManager
          ? [
              {
                title: '',
                key: 'editcol',
                dataIndex: 'id',
                render: (invoiceId: number, record: SearchInvoices_items) => (
                  <ActionsContainer>
                    <Link to={buildPath(`/invoices/${stage}/${invoiceId}`)} title='Edit invoice'>
                      <EditOutlined />
                    </Link>
                    <Link to={buildPath(`/invoices/${stage}/${invoiceId}/history`)} title='Invoice comments'>
                      ({record.events_aggregate.aggregate?.count})
                      <MessageOutlined />
                    </Link>
                  </ActionsContainer>
                )
              }
            ]
          : []),
        {
          title: '',
          key: 'status',
          dataIndex: 'id',
          render: invoiceStatusRenderer
        },
        {
          title: 'Doc. no',
          key: 'docno',
          dataIndex: 'document_number',
          sorter: true,
          filterDropdown: TextFilter
        },
        {
          title: 'Company',
          key: 'debtor',
          dataIndex: 'debtor',
          sorter: true,
          sortKey: 'debtor.name',
          render: (debtor: Debtor) => <Link to={`/debtors/${debtor.id}`}>{debtor.name}</Link>,
          filterDropdown: TextFilter
        },
        {
          title: 'Document date',
          key: 'docdate',
          dataIndex: 'document_date',
          render: dateRenderer,
          sorter: true
        },
        {
          title: 'Due date',
          key: 'duedate',
          dataIndex: 'due_date',
          render: dateRenderer,
          sorter: true
        },
        {
          title: 'Amt. Outstanding',
          key: 'amount_outstanding',
          dataIndex: 'amount_outstanding',
          align: 'right',
          render: amountRenderer,
          sorter: true
        },
        {
          title: 'Paid',
          key: 'is_paid',
          dataIndex: 'is_paid',
          filters: [
            { text: 'Yes', value: 'true' },
            { text: 'No', value: 'false' }
          ],
          sorter: true,
          render: yesNoRenderer
        }
      ],
      []
    )

    const client = useCurrentClient()

    const extraVariables: Partial<SearchInvoicesVariables> = useMemo(
      () => ({
        where: {
          debtor: {
            client_id: {
              _eq: client.id
            }
          },
          ...(stage !== stages.all
            ? {
                stage: {
                  _eq: `${stage}`
                }
              }
            : {})
        }
      }),
      [client, stage]
    )

    const buildFilterVariables: BuildFilterVariablesFn<SearchInvoicesVariables> = useCallback(filters => {
      const retv: Partial<SearchInvoicesVariables> = {
        where: {
          ...(filters.debtor
            ? {
                debtor: {
                  name: {
                    _ilike: `%${filters.debtor}%`
                  }
                }
              }
            : {}),
          ...(filters.docno
            ? {
                document_number: {
                  _ilike: `%${filters.docno}%`
                }
              }
            : {}),
          ...(filters.is_paid && filters.is_paid.length === 1
            ? {
                is_paid: {
                  _eq: filters.is_paid[0] === 'true'
                }
              }
            : {}),
          ...(filters.import_file
            ? {
                import_file_invoices: {
                  import_file_id: { _eq: Number(takeFirst(filters.import_file)) }
                }
              }
            : {})
        }
      }
      return retv
    }, [])

    return (
      <HeaderWithContent header='Invoices'>
        <Container>
          <GaphqlTable<SearchInvoices_items, SearchInvoicesVariables, SearchInvoices>
            columns={columns}
            query={SearchInvoicesQuery}
            extraVariables={extraVariables}
            buildFilterVariables={buildFilterVariables}
            emptyText='No invoices found'
            renderExtraTop={bag => {
              if (bag.items && bag.items.length && client.category?.invoice_template_id) {
                const importFileId = bag.items
                  .map(i => i.import_file_invoices.map(i => i.import_file_id))
                  .flat()
                  .reduce((max, num) => Math.max(max, num), 0)
                if (importFileId) {
                  const impFile = bag.items
                    .map(i => i.import_file_invoices)
                    .flat()
                    .find(i => i.import_file_id === importFileId)
                  return (
                    <DownloadButton importFileId={importFileId}>
                      Download invoices imported from file "{impFile?.import_file.orig_filename || '???'}"
                    </DownloadButton>
                  )
                }
              }
              return null
            }}
          />
        </Container>
        <Routes>
          <Route path='/:id/*' element={<RoutedUpdateInvoiceDrawer onClose={() => nav(baseUrl)} />} />
        </Routes>
      </HeaderWithContent>
    )
  }
)

const RoutedUpdateInvoiceDrawer: FC<{ onClose: () => void }> = ({ onClose }) => {
  const invoiceId = Number(useParams().id!)
  return <UpdateInvoiceDrawer onClose={onClose} path={`/invoices/${invoiceId}`} invoiceId={invoiceId} />
}
