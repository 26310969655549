import React, { FC } from 'react'
import List from 'antd/lib/list'
import { Navigate, Route, Routes, useParams } from 'react-router'
import { SearchClients, SearchClientsVariables, SearchClients_items } from '@/queries/_gen_/SearchClients'
import { ItemLink, InfiniteList } from '@/components/infinite_list/InfiniteList'
import { SideWithContent as L } from '@/components/layouts/CustomLayouts'
import { SearchClientsQuery } from '@/queries/clients.queries'
import CreateClientView from './CreateClientView'
import { UpdateClientView } from './UpdateClientView'
import ClientsIndexView from './ClientsIndexView'
import { SortByName, useQueryFilterBuilder } from '@/hooks/searchHelperHooks'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { InfListAddButton } from '@/components/infinite_list/InfListAddButton'
import { InfListQueryFilter } from '@/components/infinite_list/InfListQueryFilter'
import { useSearchQuery } from '@/hooks/useSearchQuery'
import { useRole } from '@/hooks/useRole'

export const ClientsListItem: FC<{ client: SearchClients_items }> = ({ client }) => (
  <ItemLink to={usePathWithParams(`/clients/${client.id}`)}>
    <List.Item.Meta title={client.name} description={client.is_archived ? 'Archived' : ''} />
  </ItemLink>
)

const ClientsList = (props: { selectedItemId?: number }) => {
  const searchBag = useSearchQuery<SearchClients_items, SearchClientsVariables, SearchClients>({
    query: SearchClientsQuery,
    buildFilterVariables: useQueryFilterBuilder(),
    defaultSort: SortByName
  })

  const newClientUrl = usePathWithParams('/clients/new')

  const { isAdmin } = useRole()

  return (
    <InfiniteList
      searchBag={searchBag}
      selectedItemId={props.selectedItemId}
      renderItem={client => <ClientsListItem client={client} />}
      filtersComponent={InfListQueryFilter}
      footerComponent={isAdmin ? () => <InfListAddButton label='New client' to={newClientUrl} /> : undefined}
    />
  )
}

// @TODO make this a generic component
export const ClientsView: FC = () => (
  <L.Wrapper>
    <L.Side width={300}>
      <Routes>
        <Route path='/:id?' element={<ClientsList />} />
        <Route path='/:id/api_keys' element={<ClientsList />} />
      </Routes>
    </L.Side>
    <L.Content>
      <Routes>
        <Route path='/' element={<ClientsIndexView />} />
        <Route path='/new' element={<CreateClientView />} />
        <Route path='/:id' element={<RoutedUpdateClientView />} />
        <Route path='/:id/api_keys' element={<RoutedUpdateClientView />} />
        <Route path='*' element={<Navigate to='/clients' />} />
      </Routes>
    </L.Content>
  </L.Wrapper>
)

const RoutedUpdateClientView = () => {
  const clientId = Number(useParams().id!)
  return <UpdateClientView clientId={clientId} pathRoot={`/clients/${clientId}`} />
}
