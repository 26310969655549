import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Button from 'antd/lib/button'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'

const AddButton = styled(Button)`
  width: 100%;
  border-radius: 0 !important;

  &:hover,
  &:focus {
    color: rgba(0 0 0 / 65%);
    border-color: #d9d9d9;
  }
`

export interface InfListAddButtonProps {
  to: string
  label: string
}

export const InfListAddButton: FC<InfListAddButtonProps> = ({ label, to }) => (
  <Link to={to}>
    <AddButton icon={<PlusOutlined />}>{label}</AddButton>
  </Link>
)
