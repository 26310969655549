import React, { FC } from 'react'
import { GetUser, GetUserVariables } from '@/queries/_gen_/GetUser'
import Fetch from '@/components/Fetch'
import CenterAlert from '@/components/CenterAlert'
import { UpdateUserForm } from './components/UserGeneralForm'
import * as R from 'ramda'
import { RoutedTabPane } from '@/components/RoutedTabs'
import { HeaderWithTabs } from '@/components/layouts/HeaderWithTabs'
import { GetUserQuery } from '@/queries/users.queries'
import { fullName } from 'common/models/user'

export interface UpdateUserViewProps {
  userId: string
  pathRoot?: string
}

export const UpdateUserView: FC<UpdateUserViewProps> = ({ userId, pathRoot }) => (
  <Fetch<GetUser, GetUserVariables> query={GetUserQuery} container={true} variables={{ id: userId }}>
    {data => {
      if (data.user) {
        const user = {
          ...R.omit(['__typename'], data.user),
          password: ''
        }

        const tabs: RoutedTabPane[] = [
          {
            name: 'General',
            path: '',
            render: () => <UpdateUserForm key={user.id} user={user} />
          }
        ]

        return <HeaderWithTabs fitToContainer={true} pathRoot={pathRoot || `/users/${userId}`} header={fullName(data.user)} tabs={tabs} />
      }
      return <CenterAlert type='error' message='User not found :(' />
    }}
  </Fetch>
)
