export enum Column {
  documentNumber = 'document_number',
  invoiceDate = 'document_date',
  dueDate = 'due_date',
  amountWithVat = 'amount_with_vat',
  amountWithoutVat = 'amount_wo_vat',
  amountOutstanding = 'amount_outstanding',
  vat = 'vat',
  currency = 'currency',
  extra1 = 'extra_1',
  extra2 = 'extra_2',
  extra3 = 'extra_3',
  extra4 = 'extra_4',
  debtorExtra1 = 'debtor_extra_1',
  debtorExtra2 = 'debtor_extra_2',
  daysLate = 'days_late',
  amountPaid = 'amount_paid',
  fullAmount = 'full_amount'
}


export const monetaryColumns = [Column.amountOutstanding, Column.amountWithVat, Column.amountWithoutVat, Column.vat, Column.amountPaid, Column.fullAmount]

export const dateColumns = [Column.invoiceDate, Column.dueDate]

export const columnNames: { [key in Column]: string } = {
  [Column.documentNumber]: 'Document no.',
  [Column.invoiceDate]: 'Document date',
  [Column.dueDate]: 'Due date',
  [Column.amountOutstanding]: 'Outstanding',
  [Column.amountWithVat]: 'Amount w. VAT',
  [Column.amountWithoutVat]: 'Amount w/o VAT',
  [Column.vat]: 'VAT',
  [Column.currency]: 'Currency',
  [Column.extra1]: 'Extra 1',
  [Column.extra2]: 'Extra 2',
  [Column.extra3]: 'Extra 3',
  [Column.extra4]: 'Extra 4',
  [Column.debtorExtra1 ]: 'Debtor extra 1',
  [Column.debtorExtra2]: 'Debtor extra 2',
  [Column.daysLate]: 'Days late',
  [Column.amountPaid]: 'Amount paid',
  [Column.fullAmount]: 'Full amount'
}

export const selectableColumns: Column[] = [
  Column.documentNumber,
  Column.invoiceDate,
  Column.dueDate,
  Column.amountOutstanding,
  Column.amountWithVat,
  Column.amountWithoutVat,
  Column.amountPaid,
  Column.fullAmount,
  Column.vat,
  Column.currency,
  Column.extra1,
  Column.extra2,
  Column.extra3,
  Column.extra4,
  Column.debtorExtra1,
  Column.debtorExtra2,
  Column.daysLate
]