import React, { FC } from 'react'
import { FEventFields, FEventFields_reminders } from '@/queries/_gen_/FEventFields'
import { EventType } from 'common/models/events'
import { EventItemWrapper } from './EventItemWrapper'
import { CommentItem } from './CommentItem'
import { GetEventsVariables } from '@/queries/_gen_/GetEvents'

interface EventItemProps {
  event: FEventFields
  variables: GetEventsVariables
}

export const EventItem: FC<EventItemProps> = ({ event, variables }) =>
  eventTypeRenderers[event.event_type as EventType]({ event, variables })

const ReminderRecipients: FC<{ reminder?: FEventFields_reminders }> = ({ reminder }) => {
  if (reminder) {
    if (reminder?.reminder_send_request?.email_recipients) {
      return <>to {reminder.reminder_send_request?.email_recipients}</>
    }
    if (reminder?.reminder_send_request?.success_phone_numbers) {
      return <>to {reminder.reminder_send_request.success_phone_numbers.join(', ')}</>
    }
    if (reminder?.reminder_send_request?.error_phone_numbers) {
      return <>to {reminder.reminder_send_request.error_phone_numbers.join(', ')}</>
    }
  }
  return null
}

// @TODO make these good
const eventTypeRenderers: { [key in EventType]: FC<{ event: FEventFields; variables: GetEventsVariables }> } = {
  [EventType.reminderCreated]: ({ event }) => <EventItemWrapper event={event}>Reminder created.</EventItemWrapper>,
  [EventType.reminderSendTriggered]: ({ event }) => <EventItemWrapper event={event}>Reminder queued to be sent.</EventItemWrapper>,
  [EventType.reminderMarkedAsSent]: ({ event }) => <EventItemWrapper event={event}>Reminder marked as sent.</EventItemWrapper>,
  [EventType.reminderSent]: ({ event }) => (
    <EventItemWrapper event={event}>
      Reminder sent <ReminderRecipients reminder={event.reminders[0]} />.
    </EventItemWrapper>
  ),
  [EventType.reminderCancelled]: ({ event }) => <EventItemWrapper event={event}>Reminder cancelled.</EventItemWrapper>,
  [EventType.reminderSendError]: ({ event }) => {
    const sendRequest = event.reminders[0]?.reminder_send_request
    return (
      <EventItemWrapper event={event}>
        Error occurred while sending <ReminderRecipients reminder={event.reminders[0]} />: {sendRequest?.error_reason || 'unknown'}.
      </EventItemWrapper>
    )
  },
  [EventType.comment]: CommentItem,
  [EventType.invoiceCreated]: ({ event }) => <EventItemWrapper event={event}>Invoice created.</EventItemWrapper>,
  [EventType.invoiceUpdated]: ({ event }) => <EventItemWrapper event={event}>Invoice updated.</EventItemWrapper>,
  [EventType.reminderEmailEvent]: ({ event }) => (
    <EventItemWrapper event={event}>
      Email was {event.extra_info?.event ? event.extra_info?.event : 'ghmz: *we do not know what happened'}
    </EventItemWrapper>
  )
}
