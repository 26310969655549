import Card from 'antd/lib/card'
import styled from 'styled-components'

export const CenteredCard = styled(Card)`
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .ant-form-item-label > label {
    width: 6em;
  }

  .ant-form-item-control {
    width: 266px;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-alert {
    margin-bottom: 24px;
  }
`
