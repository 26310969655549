import Container from '@/components/layouts/Container'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Select } from 'antd'
import Form from 'antd/lib/form/Form'
import FormItem from 'antd/lib/form/FormItem'
import moment, { Moment } from 'moment'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { REPORT_LANGUAGES, REPORT_LANGUAGE_IDS } from 'common/models/report'

const { Option } = Select

const today = moment(new Date())

const MOMENT_DATE_FORMAT = 'YYYY-MM-DD'

const StyledForm = styled(Form)`
  margin-bottom: 2em !important;
`

const ReportButton = styled(Button)`
  width: 200px;
  text-align: left !important;
`

export const ReportsView: FC = () => {
  const client = useCurrentClient()
  const [from, setFrom] = useState(today)
  const [to, setTo] = useState(today)
  const [locale, setLocale] = useState(REPORT_LANGUAGE_IDS[0])

  const onFromChange = (d: Moment | null) => {
    if (d) {
      setFrom(d)
    }
  }

  const onToChange = (d: Moment | null) => {
    if (d) {
      setTo(d)
    }
  }

  return (
    <HeaderWithContent header='Reports'>
      <Container>
        <StyledForm layout='inline'>
          <FormItem label='From'>
            <DatePicker value={from} onChange={onFromChange} />
          </FormItem>
          <FormItem label='To'>
            <DatePicker value={to} onChange={onToChange} />
          </FormItem>
          <FormItem label='Language'>
            <Select value={locale} onChange={loc => setLocale(loc)}>
              {REPORT_LANGUAGES.map(({ id, label }) => (
                <Option key={id} value={id}>
                  {label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </StyledForm>
        <a
          href={`${__CONFIG__.backendUri}/report/reminders/${client.id}?from=${from.format(MOMENT_DATE_FORMAT)}&to=${to.format(
            MOMENT_DATE_FORMAT
          )}&locale=${locale}`}
        >
          <ReportButton type='primary' icon={<DownloadOutlined />}>
            Activity report
          </ReportButton>
        </a>
        <br />
        <br />
        <a href={`${__CONFIG__.backendUri}/report/debtors/${client.id}?locale=${locale}`}>
          <ReportButton type='primary' icon={<DownloadOutlined />}>
            Debtors
          </ReportButton>
        </a>
        <br />
        <br />
        <a
          href={`${__CONFIG__.backendUri}/report/invoicecomments/${client.id}?from=${from.format(MOMENT_DATE_FORMAT)}&to=${to.format(
            MOMENT_DATE_FORMAT
          )}&locale=${locale}`}
        >
          <ReportButton type='primary' icon={<DownloadOutlined />}>
            Invoice comments
          </ReportButton>
        </a>
      </Container>
    </HeaderWithContent>
  )
}
