import { useEffect, useState } from 'react'
import { useIsMountedRef } from './useIsMountedRef'
import { loaderDelayMilliseconds } from '@/constants'

export function useDelay(deps: unknown[] = []) {
  const [done, setDone] = useState(false)
  const isMounted = useIsMountedRef()
  useEffect(() => {
    setDone(false)
    setTimeout(() => {
      if (isMounted.current) {
        setDone(true)
      }
    }, loaderDelayMilliseconds)
  }, [setDone, isMounted, ...deps])

  return done
}
