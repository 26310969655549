import { order_by } from '@/queries/_gen_global'
import { DocumentNode } from 'graphql'
import { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useQueryParams } from './useQueryParams'
import { mapObj, filterKeys, dotNotationToNested, replaceIntersectingValues } from '@/utils/object'
import { makeItArray, EMPTY_LIST, takeFirst } from '@/utils/array'
import * as R from 'ramda'
import { useQuery } from 'react-apollo'
import useUpdateEffect from './useUpdateEffect'
import { ApolloError } from 'apollo-client'

const DEFAULT_PAGE_SIZE = 20

export interface BaseItem {
  id: number
}

export interface BaseVariables {
  limit: number
  offset: number
  where: any
  order_by?: unknown
}

export interface BaseResult<T> {
  items: T[]
  total: {
    aggregate: {
      count: number | null
    } | null
  }
}

export type FilterState = Record<string, string[] | null | undefined>

export interface SortState {
  field: string
  order: order_by
}

const DEFAULT_SORT_STATE: SortState = {
  field: 'id',
  order: order_by.desc
}

export interface PaginationState {
  pageSize: number
  currentPage: number
}

export interface SearchState {
  pagination: PaginationState
  sort: SortState
  filter: FilterState
}

export type BuildFilterVariablesFn<Variables> = (filter: FilterState) => Partial<Variables>

export interface SearchQueryOptions<Variables extends BaseVariables> {
  query: DocumentNode
  extraVariables?: Partial<Variables>
  buildFilterVariables?: BuildFilterVariablesFn<Variables>
  defaultSort?: SortState
  defaultPageSize?: number
}

export interface SearchQueryBag<T extends BaseItem, Variables extends BaseVariables, Result extends BaseResult<T>> {
  items: T[]
  total: number
  loading: boolean
  state: SearchState
  error?: ApolloError
  hasMore: boolean
  fetchingMore: boolean
  data?: Result
  variables: Variables
  query: DocumentNode
  setState: (state: Partial<SearchState>) => void
  fetchMore: (page: number) => void
}

/*
this monster provides a paginated search query whose
state is tracked via query parameters
*/
export function useSearchQuery<T extends BaseItem, Variables extends BaseVariables, Result extends BaseResult<T>>(
  options: SearchQueryOptions<Variables>
): SearchQueryBag<T, Variables, Result> {
  const { query, extraVariables, buildFilterVariables, defaultSort, defaultPageSize } = options
  const [queryParams, setQueryParams] = useQueryParams()
  const [fetchingMore, setFetchingMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const fetchMorePageRef = useRef(0)

  const _defaultSort = defaultSort || DEFAULT_SORT_STATE
  const _defaultPageSize = defaultPageSize || DEFAULT_PAGE_SIZE
  const state = useMemo((): SearchState => {
    const rawOrder = takeFirst(queryParams.sortOrder)
    return {
      pagination: {
        pageSize: Number(takeFirst(queryParams.pageSize) || _defaultPageSize),
        currentPage: Number(takeFirst(queryParams.currentPage) || 1)
      },
      sort: {
        field: takeFirst(queryParams.sortBy) || _defaultSort.field,
        order: rawOrder && Object.values<string>(order_by).includes(rawOrder) ? (rawOrder as order_by) : _defaultSort.order
      },
      filter: mapObj(
        filterKeys(queryParams, k => k.startsWith('f_')),
        k => k.substr(2),
        v => (v ? makeItArray(v) : [])
      )
    }
  }, [queryParams, _defaultSort, _defaultPageSize])

  const variables: Variables = useMemo(() => {
    let vars = {
      limit: state.pagination.pageSize,
      offset: (state.pagination.currentPage - 1) * state.pagination.pageSize,
      where: {},
      order_by: [dotNotationToNested(state.sort.field, state.sort.order), { id: 'desc' }]
    } as Variables
    if (extraVariables) {
      vars = R.mergeDeepLeft(vars, extraVariables) as Variables
    }
    if (buildFilterVariables) {
      vars = R.mergeDeepLeft(vars, buildFilterVariables(state.filter)) as Variables
    }
    return vars
  }, [state, extraVariables, buildFilterVariables])

  const setState = useCallback(
    (newState: Partial<SearchState>): void => {
      const ustate = {
        ...state,
        ...newState
      }
      setQueryParams(
        replaceIntersectingValues(
          {
            pageSize: String(_defaultPageSize),
            currentPage: '1',
            sortBy: _defaultSort.field,
            sortOrder: _defaultSort.order
          },
          null,
          {
            pageSize: String(ustate.pagination.pageSize),
            currentPage: !R.equals(ustate.sort, state.sort) ? '1' : String(ustate.pagination.currentPage),
            sortBy: ustate.sort.field,
            sortOrder: ustate.sort.order,
            ...mapObj(
              ustate.filter,
              k => `f_${k}`,
              v => v || null
            )
          }
        )
      )
    },
    [state, setQueryParams, _defaultPageSize, _defaultSort]
  )

  const result = useQuery<Result, Variables>(query, {
    variables,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setHasMore(!!data?.total.aggregate && (data.total.aggregate.count || 0) > fetchMorePageRef.current * variables.limit)
    }
  })

  const items = (result.data && result.data.items) || []
  const total = (result.data && result.data.total.aggregate && result.data.total.aggregate.count) || 0

  const resetPage = useCallback(() => {
    setState({
      pagination: {
        ...state.pagination,
        currentPage: 1
      }
    })
  }, [state, setState])

  // reset page if no results, but last page
  useEffect(() => {
    if (!items.length && state.pagination.currentPage > 1 && !result.loading) {
      resetPage()
    }
  }, [items, state, result.loading, setState, resetPage])

  // reset page if extra vars or query change
  useUpdateEffect(() => {
    resetPage()
  }, [extraVariables, query])

  useUpdateEffect(() => {
    fetchMorePageRef.current = 0
  }, [variables, query])

  // for use by infinite lists
  const fetchMore = useCallback(() => {
    if (!fetchingMore && hasMore) {
      const page = fetchMorePageRef.current + 1
      result
        .fetchMore({
          query,
          variables: {
            ...variables,
            offset: page * variables.limit,
            limit: variables.limit
          },
          updateQuery: (prev: Result, options) => {
            const newItems = [
              ...((prev && prev.items) || EMPTY_LIST),
              ...((options.fetchMoreResult && options.fetchMoreResult.items) || EMPTY_LIST)
            ]

            return {
              ...(prev || {}),
              items: newItems
            }
          }
        })
        .finally(() => setFetchingMore(false))
      setFetchingMore(true)
      fetchMorePageRef.current = page
    }
  }, [result, fetchingMore, variables, query, hasMore])

  return useMemo(
    () => ({
      items,
      total,
      state,
      loading: result.loading,
      setState,
      fetchMore,
      fetchingMore,
      error: result.error,
      hasMore,
      data: result.data,
      variables,
      query
    }),
    [result, state, setState, hasMore, fetchMore, fetchingMore, total, variables, query, items]
  )
}
