import React, { FC, useMemo } from 'react'
import List, { ListProps } from 'antd/lib/list'
import { useRemindersSendRequstsSub } from '@/views/reminders/hooks/useRemindersSendRequestsSub'
import { RemindersSendRequestSubscription_reminders_send_request } from '@/queries/_gen_/RemindersSendRequestSubscription'
import { ReminderSendRequestStatus } from '@/models/reminders'
import Alert from 'antd/lib/alert'
import Progress from 'antd/lib/progress'
import Card from 'antd/lib/card'
import { formatDateTimeReadable } from '@/utils/date'
import { ItemLink } from './infinite_list/InfiniteList'
import styled from 'styled-components'
import { useQueryParams } from '@/hooks/useQueryParams'
import { takeFirst } from '@/utils/array'
import { fullName } from 'common/models/user'

const StyledItemLink = styled(ItemLink)`
  &.selected .ant-list-item-meta-description {
    font-weight: bold;
    color: black;
  }
`

export type ReminderSendingStatusProps = Omit<
  ListProps<RemindersSendRequestSubscription_reminders_send_request>,
  'renderItem' | 'dataSource'
>

export const ReminderSendingStatusItem: FC<RemindersSendRequestSubscription_reminders_send_request> = props => {
  const { id, created_at, creator, reminder_send_requests } = props

  const [params] = useQueryParams()
  const selected = takeFirst(params.f_send_reminders_request_id) === String(id)

  const { total, success, error, pending } = useMemo(
    () => ({
      total: reminder_send_requests.length,
      success: reminder_send_requests.filter(r => r.status === ReminderSendRequestStatus.sent).length,
      error: reminder_send_requests.filter(r => r.status === ReminderSendRequestStatus.error).length,
      pending: reminder_send_requests.filter(r => r.status === ReminderSendRequestStatus.new).length
    }),
    [reminder_send_requests]
  )

  const descParts = []
  if (success) descParts.push(`${success} sent`)
  if (error) descParts.push(`${error} failed`)
  if (pending) descParts.push(`${pending} in progress`)

  return (
    <List.Item>
      <StyledItemLink to={`/reminders?f_send_reminders_request_id=${id}`} className={selected ? 'selected' : ''}>
        <List.Item.Meta
          title={
            <Progress
              percent={Math.ceil((100 / total) * (success + error))}
              format={() => `${success + error} / ${total}`}
              status={error ? 'exception' : pending ? 'active' : 'success'}
            />
          }
          description={`${formatDateTimeReadable(created_at)}, ${fullName(creator)}, ${descParts.join(', ')}`}
        />
      </StyledItemLink>
    </List.Item>
  )
}

export const ReminderSendingStatus: FC<ReminderSendingStatusProps> = props => {
  const { data, loading, error } = useRemindersSendRequstsSub()
  if (error) {
    return <Alert type='error' message={error.message} />
  } else if (data && !data.reminders_send_request.length) {
    return null
  } else {
    return (
      <Card>
        <h3>Recently sent reminders</h3>
        <List
          dataSource={data?.reminders_send_request || []}
          loading={loading}
          renderItem={item => <ReminderSendingStatusItem {...item} />}
          {...props}
        />
      </Card>
    )
  }
}
