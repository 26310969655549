import React, { FC, ComponentType, useMemo, useCallback } from 'react'
import Button from 'antd/lib/button'
import { Form, FieldArray, Formik } from 'formik'
import Input from 'antd/lib/input'
import Switch from 'antd/lib/switch'
import { DebtorContact, ContactType, DebtorWithContacts } from '@/models/debtor'
import { FormikField } from '@/components/form/FormikField'
import * as yup from 'yup'
import styled from 'styled-components'
import AntdForm from 'antd/lib/form'
import { ReplaceDebtorContactsMutation, Fragments } from '@/queries/debtors.queries'
import { ReplaceDebtorContacts, ReplaceDebtorContactsVariables } from '@/queries/_gen_/ReplaceDebtorContacts'
import { FDebtorContacts } from '@/queries/_gen_/FDebtorContacts'
import { FDebtorContactFields } from '@/queries/_gen_/FDebtorContactFields'
import { FormMutation } from '@/components/form/FormMutation'
import { RemoveItemButton } from '@/components/form/RemoveItemButton'
import { MutationUpdaterFn } from 'apollo-client'
import { PlusOutlined } from '@ant-design/icons'
import { useEventEmit } from '@/hooks/useEventEmit'
import { DebtorUpdatedEvent } from '@/models/events'

const Section = styled.section`
  & + section {
    margin-top: 3em;
  }

  .ant-form-item:first-child .ant-form-item-control {
    width: 400px;
  }

  .ant-form + .ant-form {
    margin-top: 2em;
  }
`

const AddButton = styled(Button)`
  margin-top: 1em;
`

const SubmitButton = styled(Button)`
  margin-top: 2em;
`

const AddressExtraFieldWrapper = styled.div`
  & > * {
    margin-top: 0.5em !important;
    min-width: 25em;
  }
`

const DescriptionFormikField = styled(FormikField)`
  margin-top: 0.5em !important;
`

interface ContactTypeConfig {
  label: {
    plural: string
    singular: string
  }
  type: ContactType
  input: ComponentType<any>
  validationSchema: yup.AnySchema
}

const baseValidator = yup.string().required('Required.')

const contactTypes: ContactTypeConfig[] = [
  {
    label: {
      plural: 'Emails',
      singular: 'Email'
    },
    type: ContactType.email,
    validationSchema: baseValidator.email('Must be a valid email.'),
    input: Input
  },
  {
    label: {
      plural: 'Phone numbers',
      singular: 'Phone number'
    },
    type: ContactType.phone,
    validationSchema: baseValidator.matches(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/, 'Must be a valid phone number.'),
    input: Input
  },
  {
    label: {
      plural: 'Addresses',
      singular: 'Address'
    },
    type: ContactType.address,
    validationSchema: baseValidator,
    input: Input.TextArea
  }
]

const emptyContact = (type: ContactType): DebtorContact => ({
  value: '',
  type,
  is_enabled: true,
  description: '',
  city: null,
  country: null,
  post_code: null
})

interface Values {
  contacts: DebtorContact[]
}

interface DebtorContactsFormProps {
  debtor: DebtorWithContacts & { id: number }
  readOnly?: boolean
}

export const DebtorContactsForm: FC<DebtorContactsFormProps> = ({ debtor, readOnly }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const initial: Values = useMemo(() => ({ contacts: debtor.debtor_contacts.map(({ __typename, ...contact }) => contact) }), [debtor])

  const buildVariables = useCallback(
    (values: Values): ReplaceDebtorContactsVariables => ({
      debtor_id: debtor.id,
      contacts: values.contacts.map(c => ({ ...c, debtor_id: debtor.id }))
    }),
    [debtor]
  )

  const mutationUpdater: MutationUpdaterFn<ReplaceDebtorContacts> = useCallback(
    (dataProxy, { data }) => {
      if (data) {
        dataProxy.writeFragment<FDebtorContacts>({
          id: `debtor:${debtor.id}`,
          fragment: Fragments.DebtorContacts,
          fragmentName: 'FDebtorContacts',
          data: {
            __typename: 'debtor',
            debtor_contacts: (data.result && data.result.returning) || []
          }
        })

        if (data.result && data.result.returning) {
          data.result.returning.forEach(contact =>
            dataProxy.writeFragment<FDebtorContactFields>({
              id: `debtor_contact:${contact.id}`,
              fragment: Fragments.DebtorContactFields,
              data: contact
            })
          )
        }
      }
    },
    [debtor]
  )

  const emitDebtorUpdated = useEventEmit(DebtorUpdatedEvent)

  return (
    <FormMutation<Values, ReplaceDebtorContacts, ReplaceDebtorContactsVariables>
      buildVariables={buildVariables}
      mutation={ReplaceDebtorContactsMutation}
      successMessage='Contacts updated!'
      update={mutationUpdater}
      onSaved={() => emitDebtorUpdated(debtor.id)}
      refetchQueries={['GetReminder', 'GetReminderStats']}
    >
      {({ onSubmit, result: { loading } }) => (
        <Formik<Values> initialValues={initial} onSubmit={onSubmit}>
          {({ values, setFieldValue, dirty }) => (
            <Form>
              <FieldArray name='contacts'>
                {arrayHelpers =>
                  contactTypes.map(ctype => {
                    const contacts = values.contacts.map((c, idx) => ({ c, idx })).filter(({ c }) => c.type === ctype.type)
                    return (
                      <Section key={ctype.type}>
                        <h3>{ctype.label.plural}</h3>
                        {!contacts.length && <p>{`No ${ctype.label.plural.toLowerCase()} are known for this debtor.`}</p>}
                        {contacts.map(
                          (
                            { idx } // filter by contact type while keeping original index
                          ) => (
                            <div className='ant-form ant-form-inline' key={idx}>
                              <div>
                                <FormikField
                                  validationSchema={ctype.validationSchema.test('uniqueness', 'Duplicate contact', value => {
                                    if (values.contacts.find((contact, cIdx) => cIdx !== idx && contact.value === value)) {
                                      return false
                                    }
                                    return true
                                  })}
                                  name={`contacts.${idx}.value`}
                                  type={ctype.type === ContactType.email ? 'email' : 'text'}
                                >
                                  {({ field }) => <ctype.input placeholder={ctype.label.singular} {...field} disabled={readOnly} />}
                                </FormikField>
                                {ctype.type === ContactType.address && (
                                  <AddressExtraFieldWrapper>
                                    <FormikField name={`contacts.${idx}.country`} placeholder='Country' disabled={readOnly} />
                                    <FormikField name={`contacts.${idx}.city`} placeholder='City' disabled={readOnly} />
                                    <FormikField
                                      name={`contacts.${idx}.post_code`}
                                      placeholder='Post code / zip code'
                                      disabled={readOnly}
                                    />
                                  </AddressExtraFieldWrapper>
                                )}
                                {(ctype.type === ContactType.phone || ctype.type === ContactType.email) && (
                                  <DescriptionFormikField
                                    name={`contacts.${idx}.description`}
                                    placeholder='Description'
                                    disabled={readOnly}
                                  />
                                )}
                              </div>

                              <FormikField name={`contacts.${idx}.is_enabled`} label='Enabled'>
                                {({ field }) => (
                                  <Switch
                                    key={String(field.value)}
                                    defaultChecked={field.value}
                                    disabled={readOnly}
                                    onChange={checked => setFieldValue(`contacts.${idx}.is_enabled` as keyof Values, checked)}
                                  />
                                )}
                              </FormikField>
                              {!readOnly && (
                                <AntdForm.Item>
                                  <RemoveItemButton
                                    title={`remove ${ctype.label.singular.toLowerCase()}`}
                                    onClick={() => arrayHelpers.remove(idx)}
                                  />
                                </AntdForm.Item>
                              )}
                            </div>
                          )
                        )}
                        {!readOnly && (
                          <AddButton
                            icon={<PlusOutlined />}
                            title={`Add ${ctype.label.singular.toLowerCase()}`}
                            onClick={() => arrayHelpers.insert(values.contacts.length, emptyContact(ctype.type))}
                          />
                        )}
                      </Section>
                    )
                  })
                }
              </FieldArray>
              {!readOnly && (
                <SubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
                  Update contacts
                </SubmitButton>
              )}
            </Form>
          )}
        </Formik>
      )}
    </FormMutation>
  )
}
