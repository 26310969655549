import { useEffect, DependencyList, EffectCallback, useRef } from 'react'

export default function useUpdateEffect(effect: EffectCallback, dependencies: DependencyList = []) {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effect()
    }
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps
}
