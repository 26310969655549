export const setupVariableAutocompleter = (editor: any, variables?: Array<string>) => {
  editor.ui.registry.addAutocompleter('variables', {
    ch: '$',
    minChars: 1,
    fetch(pattern: string) {
      if (variables) {
        const matchedVars = variables.filter(v => `{${v}}`.startsWith(pattern))

        return Promise.resolve(
          matchedVars.map(v => ({
            value: `{${v}}`,
            text: v
          }))
        )
      } else {
        return null
      }
    },
    onAction(autocompleteApi: any, rng: any, value: any) {
      editor.selection.setRng(rng)
      editor.insertContent('$' + value)
      autocompleteApi.hide()
    }
  })
}
