import { endOfDay, format, formatDistanceToNow, startOfDay, parse } from 'date-fns'
import { dateTimeFormat, backendDateFormat } from '@/constants'

export const toDate = (ts: string | Date): Date => (typeof ts === 'string' ? new Date(ts) : ts)

export const formatDateTimeReadable = (ts: string | Date): string => format(toDate(ts), dateTimeFormat)

export const fromNow = (ts: string | Date): string => formatDistanceToNow(toDate(ts), { addSuffix: true })

export const toBackendDate = (ts: Date): string => format(ts, backendDateFormat)

export const fromBackendDate = (ts: string): Date => parse(ts, backendDateFormat, new Date())

export const toEndOfDayBackendTimestamp = (ts: Date): string => endOfDay(ts).toISOString()

export const toStartOfDayBackendTimestamp = (ts: Date): string => startOfDay(ts).toISOString()
