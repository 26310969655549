import React, { FC, useCallback, useMemo, useEffect } from 'react'
import { Navigate, useNavigate, useNavigation } from 'react-router-dom'
import { useProfile } from '@/hooks/useProfile'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Form } from '@/components/form/Form'
import { FormikField } from '@/components/form/FormikField'
import * as yup from 'yup'
import { logger } from '@/logger'
import Alert from 'antd/lib/alert'
import { useApolloClient } from 'react-apollo'
import { GetMeQuery } from '@/queries/users.queries'
import { useBackendHttpCall } from '@/hooks/useBackendHttpCall'
import { CenteredCard } from '@/components/CenteredCard'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { AuthContainer } from '@/components/layouts/AuthContainer'

const ButtonsContainer = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  align-items: baseline;

  a {
    font-size: 90%;
    color: gray;
  }
`

interface LoginData {
  username: string
  password: string
}

const initial: LoginData = { username: '', password: '' }

const validationSchema = yup.object().shape({
  username: yup.string().email('Must be a valid email'),
  password: yup.string()
})

export const Login: FC = () => {
  const nav = useNavigate()
  const state = useNavigation().location?.state
  const profile = useProfile()

  const apolloClient = useApolloClient()

  const [login, { response, loading, error, data }] = useBackendHttpCall<{ error?: string }, LoginData>()

  const onSubmit = useCallback(
    (values: LoginData) => {
      logger.info(`trying to login ${values.username}`)
      login('/auth/login', {
        body: values,
        method: 'POST'
      })
    },
    [login]
  )

  // if login was sucessfull, triger profile fetch
  useEffect(() => {
    if (response && response.ok) {
      apolloClient
        .query({
          query: GetMeQuery,
          fetchPolicy: 'network-only'
        })
        .then(() => nav(state?.referrer || '/', { replace: true }))
    }
  }, [response, apolloClient, nav, state])

  const errorMessage = useMemo((): string | null => {
    if (error) {
      return error.message
    }
    if (response && !response.ok) {
      if (response.status === 401) {
        return data?.error ?? 'Username or password is invalid.'
      } else {
        return 'Unexpected error. Sorry!'
      }
    }

    return null
  }, [error, response, data])

  if (profile) {
    return <Navigate to='/' />
  }
  return (
    <AuthContainer>
      <CenteredCard title='InvoRep Login'>
        {errorMessage && <Alert type='error' message={errorMessage} />}
        {state?.message && <Alert type='success' message={state.message} />}
        <Formik<LoginData> initialValues={initial} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ values, isValid }) => (
            <Form className='ant-form'>
              <FormikField name='username' placeholder='Email' autoFocus={true} />
              <FormikField name='password' placeholder='Password' type='password' disableAutocomplete={true} />
              <ButtonsContainer>
                <Link to='/password_reset'>Forgot password?</Link>
                <Button
                  htmlType='submit'
                  type='primary'
                  loading={loading}
                  disabled={!(values.username && values.password) || loading || !isValid}
                >
                  Login
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
      </CenteredCard>
    </AuthContainer>
  )
}
