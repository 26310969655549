import React, { FC } from 'react'
import InsetContainer from '@/components/layouts/InsetContainer'
import Container from '@/components/layouts/Container'
import { ReminderAutoSender } from './components/RemindersAutoSender'
import { ReminderSendingStatus } from '@/components/ReminderSendingStatus'
import styled from 'styled-components'
import { useRole } from '@/hooks/useRole'

const StyledContainer = styled(Container)`
  & > * + * {
    margin-top: 1em;
  }
`

export const RemindersIndexView: FC = () => {
  const { isManager } = useRole()
  return (
    <InsetContainer>
      <StyledContainer>
        {isManager && <ReminderAutoSender />}
        <ReminderSendingStatus />
      </StyledContainer>
    </InsetContainer>
  )
}
