import React, { FC } from 'react'
import { GetClient, GetClientVariables } from '@/queries/_gen_/GetClient'
import Fetch from '@/components/Fetch'
import { GetClientQuery } from '@/queries/clients.queries'
import CenterAlert from '@/components/CenterAlert'
import { UpdateClientForm } from './components/ClientGeneralForm'
import * as R from 'ramda'
import { RoutedTabPane } from '@/components/RoutedTabs'
import { HeaderWithTabs } from '@/components/layouts/HeaderWithTabs'
import ClientApiKeyView from './ClientApiKeyView'

export interface UpdateClientViewProps {
  clientId: number
  pathRoot?: string
}

export const UpdateClientView: FC<UpdateClientViewProps> = ({ clientId, pathRoot }) => (
  <Fetch<GetClient, GetClientVariables> query={GetClientQuery} container={true} variables={{ id: clientId }}>
    {data => {
      if (data.client) {
        const client = data.client

        const tabs: RoutedTabPane[] = [
          {
            name: 'General',
            path: '',
            render: () => <UpdateClientForm key={client.id} client={R.omit(['client_contacts', '__typename'], client)} />
          },
          {
            name: 'API Keys',
            path: '/api_keys',
            render: () => <ClientApiKeyView key={'api_keys'} client={R.omit(['client_contacts', '__typename'], client)} />
          }
        ]

        return <HeaderWithTabs pathRoot={pathRoot || `/clients/${clientId}`} header={data.client.name} tabs={tabs} />
      }
      return <CenterAlert type='error' message='Client not found :(' />
    }}
  </Fetch>
)
