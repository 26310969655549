import React from 'react'
import ReactDOM from 'react-dom'
import './antd-styles'
import { App } from './views/App'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './global.less'

// it causes annoying errors to be logged that I'm not sure how to solve
// let's just turn this feature off, what could go wrong
//if (window.AbortController) {
// delete window.AbortController
//}

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />
  }
])

/* const history = createBrowserHistory()

function logLocation(loc: Location<unknown>) {
  agent.api.pushLog(['navigation'], { level: LogLevel.INFO, context: { url: `${loc.pathname}${loc.search}` } })
}

history.listen(logLocation)
logLocation(history.location)
const setLoc = (location: Location): string => {
  const loc = location.pathname + location.search
  logger.setGlobalContext('location', loc)
  return loc
}

setLoc(history.location)

history.listen((location, action) => {
  const loc = setLoc(location)
  logger.info(`nav ${action}: ${loc}`)
})
*/
// refresh auth token before even mounting the app, to not interrupt loader anim

ReactDOM.render(<RouterProvider router={router} />, document.getElementById('react-mount'))
