import React, { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import classnames from 'classnames'
import styled from 'styled-components'
import { logger } from '@/logger'

const Dropzone = styled.div`
  width: 300px;
  height: 300px;
  border-width: 5px;
  border-color: #777;
  border-style: dashed;
  border-radius: 5px;
  margin-top: 2em;
  cursor: pointer;

  &.isDragActive {
    border-style: solid;
    border-color: #6c6;
    background-color: #eee;
  }

  &.isDragReject {
    border-style: solid;
    border-color: #c66;
    background-color: #eee;
  }

  .inner-container {
    margin-top: 50%;
    transform: translateY(-50%);
  }

  .image {
    height: 50px;
    display: block;
    margin: auto;
  }

  .text {
    text-align: center;
    font-size: 150%;
    padding: 0 2em;
    word-wrap: anywhere;

    &.accepted {
      color: green;
    }

    &.rejected {
      color: red;
    }
  }
`

interface ImportUploadStageProps {
  onUpload: (files: File[]) => void
  file?: File | null
  parseError?: Error
  iconSrc?: string
  typeName?: string
  acceptedMimeTypes?: string[]
}

export const PayupDropzone: FC<ImportUploadStageProps> = ({ onUpload, file, parseError, iconSrc, typeName, acceptedMimeTypes }) => {
  const { getRootProps, getInputProps, acceptedFiles, fileRejections, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: Object.fromEntries(acceptedMimeTypes?.map(mimeType => [mimeType, []]) ?? []),
    multiple: false,
    onDrop: onUpload
  })

  return (
    <>
      <Dropzone {...getRootProps()} id='dropzone' className={classnames({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <div className='inner-container'>
          {iconSrc && <img className='image' src={iconSrc} />}
          {(() => {
            if (parseError) {
              return <p className='text rejected'>Failed to parse: {parseError.message}</p>
            } else if (fileRejections.length) {
              logger.warn(
                `rejected file [${fileRejections[0].file.name}] with type [${fileRejections[0].file.type}] and size [${fileRejections[0].file.size}]`
              )
              return (
                <p className='text rejected'>
                  {fileRejections[0].file.name} is not a valid {typeName || ''} file
                </p>
              )
            } else if (acceptedFiles.length || file) {
              return <p className='text accepted'>{(file || acceptedFiles[0]).name}</p>
            } else {
              return <p className='text'>drop {typeName || ''} file here, or click to select</p>
            }
          })()}
        </div>
      </Dropzone>
    </>
  )
}
