import React, { FC, useMemo, useState } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import { useAvailableClients } from '@/hooks/useAvailableClients'

const { Option } = Select

export const ClientSelect: FC<SelectProps<number>> = ({ value, ...props }) => {
  const clients = useAvailableClients()
  const [search, setSearch] = useState('')

  const nonArchivedClients = useMemo(() => clients.filter(({ id, is_archived }) => id === value || !is_archived), [clients, value])

  const filteredClients = useMemo(() => {
    if (!search) {
      return nonArchivedClients
    }
    return nonArchivedClients.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
  }, [nonArchivedClients, search])

  return (
    <Select
      {...props}
      filterOption={false}
      showSearch={true}
      onSearch={setSearch}
      value={value || undefined}
      placeholder='Select client...'
    >
      {filteredClients.map(({ id, name, is_archived }) => (
        <Option key={id} value={id}>
          {`${name}${is_archived ? ' (archived)' : ''}`}
        </Option>
      ))}
    </Select>
  )
}
