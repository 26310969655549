import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { CreateCategoryForm } from './components/CategoryForm'
import Container from '@/components/layouts/Container'

export const CreateCategoryView: FC = () => {
  const nav = useNavigate()
  return (
    <HeaderWithContent header='New category'>
      <Container>
        <CreateCategoryForm onSaved={category => nav(`/categories/${category.id}`)} />
      </Container>
    </HeaderWithContent>
  )
}
