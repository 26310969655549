import React, { FC, useCallback } from 'react'
import { PaymentRow } from '@/hooks/import_payments/useParsePaymentsCSV'
import { usePaymentsImport } from '@/hooks/import_payments/usePaymentsImport'
import { Result, Alert, Button } from 'antd'
import Container from '@/components/layouts/Container'
import styled from 'styled-components'
import Spinner from '@/components/Spinner'

const WideSpinner = styled(Spinner)`
  min-width: 200px;
`

export interface PaymentImportConfirmProps {
  file: File
  rows: PaymentRow[]
}

export const PaymentImportConfirm: FC<PaymentImportConfirmProps> = props => {
  const { file, rows } = props

  const [importFn, { result, loading, error }] = usePaymentsImport()

  const importCallback = useCallback(() => {
    if (file && importFn) {
      importFn(rows, file)
    }
  }, [file, rows, importFn])

  const imported = !!result

  return (
    <Container>
      {imported && <Result status='success' title={`Successfully imported ${rows.length} payments!`} />}
      {error && <Alert type='error' message={error.message} />}
      {!rows.length && <p>No payment rows found, nothing to import.</p>}
      {loading && <WideSpinner tip='Importing...' noDelay={true} />}
      {!loading && !imported && !error && (
        <>
          <h3>Import payments</h3>
          <p>New payments: {rows.length}</p>
          <Button onClick={importCallback} type='primary'>
            Import!
          </Button>
        </>
      )}
    </Container>
  )
}
