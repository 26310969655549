import { Invoice } from '@/models/invoice'
import React from 'react'

export const invoiceStatusRenderer = (_: string, record: Invoice): JSX.Element => {
  if (record.is_paid) {
    return <span style={{ color: 'green' }}>Paid</span>
  } else if (record.is_sending_suspended) {
    return <span style={{ color: 'orange' }}>Suspended</span>
  } else {
    return <span style={{ color: 'blue' }}>Unpaid</span>
  }
}
