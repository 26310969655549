import React from 'react'
import CardLink from '@/components/CardLink'
import Container from '@/components/layouts/Container'
import { useParams } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { getStage } from '@/models/stage'

export default () => {
  const stage = getStage(useParams().stage)

  return (
    <Container>
      <CardLink icon={<PlusOutlined />} title='New' to={`/categories/${stage}/new`}>
        <p>Add a new category</p>
      </CardLink>
    </Container>
  )
}
