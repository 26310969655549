import React, { FC, ReactNode } from 'react'
import { FieldConfig, Field } from 'formik'
import Form, { FormItemProps } from 'antd/lib/form'
import Input from 'antd/lib/input'
import { AnySchema } from 'yup'
import styled from 'styled-components'
import { formWidth } from '@/style-vars'
import { FormikFieldError } from './FormError'

export interface FormikFieldProps extends Omit<FieldConfig, 'render'> {
  label?: React.ReactNode
  placeholder?: string
  validationSchema?: AnySchema<any>
  formItemProps?: FormItemProps
  className?: string
  autoFocus?: boolean
  prefix?: ReactNode
  disabled?: boolean
  disableAutocomplete?: boolean
  description?: string
}

export const yupValidator = (schema: AnySchema<any>) => (value: any) =>
  schema
    .validate(value)
    .then(() => {
      /* */
    })
    .catch(e => e.message)

export const FormikField: FC<FormikFieldProps> = props => {
  const {
    label,
    validationSchema,
    formItemProps,
    className,
    children,
    type,
    autoFocus,
    placeholder,
    prefix,
    disabled,
    description,
    disableAutocomplete,
    ...fProps
  } = props
  return (
    <Form.Item label={label} {...(formItemProps || {})} className={className} extra={description}>
      <Field validate={validationSchema ? yupValidator(validationSchema) : undefined} {...fProps}>
        {children ||
          (({ field }) => (
            <Input
              {...field}
              autoFocus={autoFocus}
              type={type || 'text'}
              placeholder={placeholder}
              prefix={prefix}
              disabled={disabled}
              autoComplete={disableAutocomplete ? 'off' : undefined}
            />
          ))}
      </Field>
      <FormikFieldError name={fProps.name} $block={true} />
    </Form.Item>
  )
}

export const ShortFormikField = styled(FormikField)`
  max-width: ${formWidth};
`
