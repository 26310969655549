import { CountCreditorsQuery } from '@/queries/creditors.queries'
import { CountCreditors, CountCreditorsVariables } from '@/queries/_gen_/CountCreditors'
import { queryFieldValidator } from '@/utils/form'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'

export const creditorUniquenessValidator = (client: ApolloClient<InMemoryCache>, field: string, clientId: number, existingId?: number) =>
  queryFieldValidator<CountCreditors, CountCreditorsVariables>(
    client,
    CountCreditorsQuery,
    value => ({
      where: {
        [field]: { _eq: value },
        client_id: { _eq: clientId },
        ...(existingId ? { id: { _neq: existingId } } : {})
      }
    }),
    r => !(r.result && r.result.aggregate && r.result.aggregate.count)
  )
