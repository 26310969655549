import { FetchTransport, initializeFaro, OTELApi } from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'

export const agent = initializeFaro({
  app: {
    name: 'frontend client',
    version: '1.0.0',
    environment: __CONFIG__.env
  },
  transports: [
    ...(__CONFIG__.receiverUri
      ? [
          new FetchTransport({
            url: __CONFIG__.receiverUri
          })
        ]
      : [])
  ]
})

const ignoreUrls = agent.transports.transports.flatMap(transport => transport.getIgnoreUrls())

agent.instrumentations.add(
  new TracingInstrumentation({
    instrumentations: [new FetchInstrumentation({ ignoreUrls }), new DocumentLoadInstrumentation()],
    resourceAttributes: {
      env: __CONFIG__.env,
      'deployment.environment': __CONFIG__.env
    }
  })
)

export const tracer = (agent.api.getOTEL() as OTELApi).trace.getTracer('invorep frontend')
