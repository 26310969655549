import React, { FC } from 'react'
import { GetCategoryQuery } from '@/queries/categories.queries'
import { GetCategory, GetCategoryVariables } from '@/queries/_gen_/GetCategory'
import Fetch from '@/components/Fetch'
import CenterAlert from '@/components/CenterAlert'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import Container from '@/components/layouts/Container'
import { UpdateCategoryForm } from './components/CategoryForm'
import { useParams } from 'react-router-dom'

export const UpdateCategoryView: FC = () => {
  const id = useParams().id
  const categoryId = parseInt(id || '0', 10)

  return (
    <Fetch<GetCategory, GetCategoryVariables> query={GetCategoryQuery} variables={{ id: categoryId }}>
      {data => {
        if (data && data.category) {
          return (
            <HeaderWithContent header={data.category.name}>
              <Container>
                <UpdateCategoryForm category={data.category} />
              </Container>
            </HeaderWithContent>
          )
        }
        return <CenterAlert type='error' message='Category not found :(' />
      }}
    </Fetch>
  )
}
