import React, { FC } from 'react'
import List from 'antd/lib/list'
import { Routes, Route, useParams } from 'react-router'
import { SearchCreditorsQuery } from '@/queries/creditors.queries'
import { SearchCreditors, SearchCreditorsVariables, SearchCreditors_items } from '@/queries/_gen_/SearchCreditors'
import { ItemLink, InfiniteList } from '@/components/infinite_list/InfiniteList'
import { SideWithContent as L } from '@/components/layouts/CustomLayouts'
import { UpdateCreditorView } from './UpdateCreditorView'
import CreateCreditorView from './CreateCreditorView'
import CreditorsIndexView from './CreditorsIndexView'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { SortByName, useCurrentClientFilterVariables } from '@/hooks/searchHelperHooks'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { InfListQueryFilter } from '@/components/infinite_list/InfListQueryFilter'
import { InfListAddButton } from '@/components/infinite_list/InfListAddButton'
import { BuildFilterVariablesFn, useSearchQuery } from '@/hooks/useSearchQuery'
import { useRole } from '@/hooks/useRole'
import { takeFirst } from '@/utils/array'

const CreditorsListItem: FC<{ creditor: SearchCreditors_items }> = ({ creditor }) => (
  <ItemLink to={usePathWithParams(`/creditors/${creditor.id}`)}>
    <List.Item.Meta title={creditor.name} />
  </ItemLink>
)

const buildFilterVariables: BuildFilterVariablesFn<SearchCreditorsVariables> = filters => {
  const query = (takeFirst(filters.query) || '').trim()
  if (query) {
    return {
      where: {
        _or: [
          {
            name: { _ilike: `%${query.trim()}%` }
          }
        ]
      }
    }
  }
  return {}
}

const CreditorsList: FC = () => {
  const currentClient = useCurrentClient()
  const _id = useParams().id!
  const selectedItemId = _id ? Number(_id) : undefined

  const searchBag = useSearchQuery<SearchCreditors_items, SearchCreditorsVariables, SearchCreditors>({
    query: SearchCreditorsQuery,
    extraVariables: useCurrentClientFilterVariables(),
    buildFilterVariables,
    defaultSort: SortByName
  })

  const newCreditorUrl = usePathWithParams('/creditors/new')

  const { isManager } = useRole()

  return (
    <InfiniteList
      searchBag={searchBag}
      key={`creditor-ilist-${currentClient.id}`}
      selectedItemId={selectedItemId}
      renderItem={creditor => <CreditorsListItem creditor={creditor} />}
      filtersComponent={InfListQueryFilter}
      footerComponent={isManager ? () => <InfListAddButton label='New creditor' to={newCreditorUrl} /> : undefined}
    />
  )
}

export default () => {
  const { isManager } = useRole()

  return (
    <L.Wrapper>
      <L.Side width={300}>
        <Routes>
          <Route path='/:id?/*' element={<CreditorsList />} />
        </Routes>
      </L.Side>
      <L.Content>
        <Routes>
          <Route path='/' element={<CreditorsIndexView />} />
          {isManager && <Route path='/new' element={<CreateCreditorView />} />}
          <Route path='/:id/*' element={<RoutedUpdateCreditorView />} />
        </Routes>
      </L.Content>
    </L.Wrapper>
  )
}

const RoutedUpdateCreditorView: FC = () => {
  const creditorId = Number(useParams().id!)
  return <UpdateCreditorView creditorId={creditorId} pathRoot={`/creditors/${creditorId}`} />
}
