import React, { ReactNode, ComponentType, useCallback, useState, useEffect } from 'react'
import InfiniteScroller from 'react-infinite-scroller'
import { NavLink } from 'react-router-dom'
import List, { ListItemProps } from 'antd/lib/list'
import styled from 'styled-components'
import * as styleConsts from '@/style-vars'
import Alert from 'antd/lib/alert'
import { BaseItem, BaseResult, FilterState, SearchQueryBag, BaseVariables } from '@/hooks/useSearchQuery'
import Spinner from '../Spinner'

const InnerWrapper = styled.div`
  overflow: auto;
  background: white;
  height: 100%;
  flex: 1;
  border-top: ${styleConsts.softBorder};
`

const OuterWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

const InnerComponentWrapper = styled.div`
  background: white;
  flex: 0;
`

const TotalsWrapper = styled(InnerComponentWrapper)`
  padding: 2px;
  border-top: ${styleConsts.softBorder};
`

const ListAlert = styled(Alert)`
  margin: 1em;
`

const ListItem = styled(List.Item)<ListItemProps & { selected?: boolean }>`
  padding-left: 1em;
  padding-right: 1em;

  &:hover {
    background-color: ${styleConsts.hoverBackground};
  }

  ${({ selected }: ListItemProps & { selected?: boolean }) =>
    selected
      ? `
      background-color: ${styleConsts.active} !important;
      & * {

        h4 {
          font-weight: bold;
        }

        color: white !important;
      }
    `
      : ''}
`

const FooterSpinnerContainer = styled.div`
  position: relative;
`

export interface FilterComponentProps {
  filters: FilterState
  setFilters: (state: FilterState) => void
}

export interface ListWrapperProps {
  innerComponent: ComponentType<Record<string, never>>
}

export interface InfiniteListProps<Item extends BaseItem, Variables extends BaseVariables, Result extends BaseResult<Item>> {
  renderItem: (result: Item, selected: boolean) => ReactNode
  selectedItemId?: number | string
  filtersComponent?: ComponentType<FilterComponentProps>
  footerComponent?: ComponentType<SearchQueryBag<Item, Variables, Result>>
  headerComponent?: ComponentType<Record<string, never>>
  listWraperComponent?: ComponentType<ListWrapperProps>
  searchBag: SearchQueryBag<Item, Variables, Result>
}

export function InfiniteList<Item extends BaseItem, Variables extends BaseVariables, Result extends BaseResult<Item>>(
  props: InfiniteListProps<Item, Variables, Result>
) {
  const {
    renderItem,
    selectedItemId,
    filtersComponent: FiltersComponent,
    footerComponent: FooterComponent,
    headerComponent: HeaderComponent,
    listWraperComponent: ListWraperComponent,
    searchBag
  } = props

  const [scrollerDiv, setScrollerDiv] = useState<HTMLDivElement | null>(null)

  const { error, loading, fetchMore, fetchingMore, hasMore, items, state, setState, total, query, variables } = searchBag

  const setFilters = useCallback((filter: FilterState) => setState({ filter }), [setState])

  useEffect(() => {
    if (scrollerDiv) {
      scrollerDiv.scrollTop = 0
    }
  }, [query, variables, scrollerDiv])

  const ListComponent = () => (
    <List
      dataSource={items}
      loading={loading && !items.length}
      renderItem={(item: Item) => (
        <ListItem key={item.id} selected={item.id === selectedItemId}>
          {renderItem(item, item.id === selectedItemId)}
        </ListItem>
      )}
      footer={
        fetchingMore && (
          <FooterSpinnerContainer>
            <Spinner noDelay={true} />
          </FooterSpinnerContainer>
        )
      }
    />
  )

  return (
    <OuterWrapper>
      {FiltersComponent && (
        <InnerComponentWrapper>
          <FiltersComponent filters={state.filter} setFilters={setFilters} />
        </InnerComponentWrapper>
      )}
      <InnerWrapper ref={setScrollerDiv}>
        <InfiniteScroller
          hasMore={hasMore && !fetchingMore && !loading && !error}
          useWindow={false}
          loadMore={fetchMore}
          key={`${searchBag.query}-${searchBag.variables}`}
        >
          {error ? (
            <ListAlert type='error' message={error.message} />
          ) : (
            <>
              {HeaderComponent && <HeaderComponent />}

              {ListWraperComponent ? <ListWraperComponent innerComponent={ListComponent} /> : <ListComponent />}
            </>
          )}
        </InfiniteScroller>
      </InnerWrapper>
      {FooterComponent && (
        <InnerComponentWrapper>
          <FooterComponent {...searchBag} />
        </InnerComponentWrapper>
      )}
      <TotalsWrapper>Total: {total}</TotalsWrapper>
    </OuterWrapper>
  )
}

export const ItemLink = styled(NavLink)`
  text-decoration: none !important;
  flex: 1;
`
