import React from 'react'
import { useQuery, QueryHookOptions } from 'react-apollo'
import { DocumentNode } from 'graphql'
import { QueryContainer } from './QueryContainer'

export interface Props<TData, TVariables> extends QueryHookOptions<TData, TVariables> {
  container?: boolean
  query: DocumentNode
  children: (data: TData) => JSX.Element | null
}

export default function Fetch<TData = any, TVariables = Record<string, any>>(props: Props<TData, TVariables>) {
  const { children, container, query, ...rest } = props
  const queryParams: QueryHookOptions<TData, TVariables> = {
    fetchPolicy: 'cache-and-network',
    ...rest
  }
  const qresult = useQuery<TData, TVariables>(query, queryParams)
  return (
    <QueryContainer queryResult={qresult} container={container}>
      {children}
    </QueryContainer>
  )
}
