import styled from 'styled-components'
import Button from 'antd/lib/button'

export const CompactButton = styled(Button)`
  padding: 0 !important;
  font-size: 100% !important;
  height: auto !important;

  span {
    color: #fff !important;
  }

  &:disabled {
    span {
      color: rgb(0 0 0 / 25%) !important;
    }
  }
`
