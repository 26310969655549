import React, { FC } from 'react'
import Button, { ButtonProps } from 'antd/lib/button'
import { DownloadOutlined } from '@ant-design/icons'

interface DownloadInvoicesButtonProps extends ButtonProps {
  importFileId: number
}

export const DownloadInvoicesButton: FC<DownloadInvoicesButtonProps> = ({ importFileId, ...props }) => (
  <a target='_blank' rel='noopener noreferrer' href={__CONFIG__.backendUri + '/invoice/download/byimportfile/' + importFileId}>
    <Button icon={<DownloadOutlined />} type='primary' {...props} />
  </a>
)
