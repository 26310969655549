import AntDrawer from 'antd/lib/drawer'
import styled from 'styled-components'
import { backgroundColor } from '@/style-vars'

export const Drawer = styled(AntDrawer)`
  .ant-drawer-body {
    padding: 24px 10px;
  }

  .ant-drawer-wrapper-body {
    background-color: ${backgroundColor};
  }
`
