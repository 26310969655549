import { SearchCategoriesQuery } from '@/queries/categories.queries'
import { SearchCategories, SearchCategoriesVariables } from '@/queries/_gen_/SearchCategories'
import { queryFieldValidator } from '@/utils/form'
import ApolloClient from 'apollo-client'

export const archivedReminderTemplateValidator = (client: ApolloClient<any>, templateId?: number) => {
  const validator = queryFieldValidator<SearchCategories, SearchCategoriesVariables>(
    client,
    SearchCategoriesQuery,
    () => ({
      where: {
        category_steps: {
          template_id: {
            _eq: templateId
          }
        }
      },
      limit: 1,
      offset: 0
    }),
    r => !(r.total && r.total.aggregate?.count)
  )
  return (value: boolean | undefined) => (value ? validator(value) : true)
}

export const archivedInvoiceTemplateValidator = (client: ApolloClient<any>, templateId?: number) => {
  const validator = queryFieldValidator<SearchCategories, SearchCategoriesVariables>(
    client,
    SearchCategoriesQuery,
    () => ({
      where: {
        invoice_template_id: {
          _eq: templateId
        }
      },
      limit: 1,
      offset: 0
    }),
    r => !(r.total && r.total.aggregate?.count)
  )
  return (value: boolean | undefined) => (value ? validator(value) : true)
}
