import React, { FC } from 'react'
import { Drawer } from '@/components/Drawer'
import { CreateCasecardView } from './CreateCasecardView'

export interface CreateCasecardDrawerProps {
  path: string
  onClose: () => void
}

export const CreateCasecardDrawer: FC<CreateCasecardDrawerProps> = props => {
  const { onClose, path } = props

  return (
    <Drawer width={720} onClose={onClose} visible={true}>
      {<CreateCasecardView pathRoot={path} />}
    </Drawer>
  )
}
