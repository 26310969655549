import styled from 'styled-components'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  $white?: boolean
  $centeredColumn?: boolean
  $fitToContainer?: boolean
  $scrollable?: boolean
}

export default styled.div<Props>`
  flex: 1;
  padding: 1em;
  position: relative;
  ${(props: Props) =>
    props.$scrollable
      ? `
    overflow: auto;
  `
      : ''}
  ${(props: Props) => (props.$white ? 'background: white;' : '')}
  ${(props: Props) =>
    props.$centeredColumn
      ? `
    display: flex;
    flex-direction: column;
    align-items: center;
  `
      : ''}
  ${(props: Props) =>
    props.$fitToContainer
      ? `
    display: flex;
    flex-direction: column;
    flex: 1;
    height:100%;
  `
      : ''}
`
