import React, { FC, useState } from 'react'
import Button from 'antd/lib/button'
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea'

const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 1em;

  button + button {
    margin-left: 0.5em;
  }
`

interface CommentFormProps {
  comment?: string
  onSubmit: (comment: string) => void
  onCancel: () => void
  loading?: boolean
  disabled?: boolean
}

export const CommentForm: FC<CommentFormProps> = props => {
  const { disabled, comment, onSubmit, onCancel, loading } = props

  const [value, setValue] = useState(comment || '')

  return (
    <div>
      <TextArea disabled={disabled} value={value} onChange={e => setValue(e.target.value)} ref={ref => ref?.focus()} rows={4} />
      <ButtonContainer>
        <Button type='primary' disabled={disabled} loading={loading} onClick={() => onSubmit(value)}>
          Save
        </Button>
        <Button type='default' disabled={disabled} onClick={() => onCancel()}>
          Cancel
        </Button>
      </ButtonContainer>
    </div>
  )
}
