import React, { FC, useMemo } from 'react'
import { columnTypes, columnTypeNames, SheetColumn, ColumnType } from '@/models/import/xls'
import styled from 'styled-components'
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import { DownOutlined } from '@ant-design/icons'

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;

  & > span {
    line-height: 20px;
  }
`

const StyledMenu = styled(Menu)`
  overflow: hidden auto;
  max-height: 300px;
`

const DropdownTrigger = styled.span`
  cursor: pointer;
  color: #888;
  font-size: 0.9em;
`

interface HeaderRendererProps {
  column: SheetColumn
  onColumnTypeChange: (columnType: ColumnType) => void
}

export const HeaderRenderer: FC<HeaderRendererProps> = props => {
  const { column, onColumnTypeChange } = props

  const menu = useMemo(
    () => (
      <StyledMenu onClick={e => onColumnTypeChange(e.key as ColumnType)}>
        {columnTypes
          .filter(ctype => ctype !== column.type)
          .map(ctype => (
            <Menu.Item key={ctype}>{columnTypeNames[ctype]}</Menu.Item>
          ))}
      </StyledMenu>
    ),
    [onColumnTypeChange, column]
  )

  return (
    <HeaderContent>
      <span>{column.label}</span>
      <Dropdown overlay={menu} trigger={['click']}>
        <DropdownTrigger className='ant-dropdown-link'>
          {columnTypeNames[column.type]} <DownOutlined />
        </DropdownTrigger>
      </Dropdown>
    </HeaderContent>
  )
}
