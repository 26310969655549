import React, { FC, ReactNode } from 'react'
import { HeaderWithContent as L } from '@/components/layouts/CustomLayouts'
import InsetContainer from '@/components/layouts/InsetContainer'
import { Header } from '../Header'

interface Props {
  header: ReactNode
  children?: ReactNode
  fitToContainer?: boolean
}

export const HeaderWithContent: FC<Props> = props => (
  <L.Wrapper>
    <L.Header height={60}>
      <Header>{props.header}</Header>
    </L.Header>
    <L.Content style={{ overflow: props.fitToContainer ? 'hidden' : '' }}>
      <InsetContainer $fitToContainer={props.fitToContainer}>{props.children}</InsetContainer>
    </L.Content>
  </L.Wrapper>
)
