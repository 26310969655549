import styled from 'styled-components'
import Alert from 'antd/lib/alert'

export default styled(Alert)`
  text-align: center;
  max-width: 400px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
`
