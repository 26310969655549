export enum Folder {
  images = 'images',
  preview = 'preview',
  importfiles = 'importfiles',
  reminders = 'reminders',
  invoices = 'invoices'
}

export const folders = [Folder.images, Folder.preview, Folder.importfiles, Folder.reminders, Folder.invoices]

export function folderFromString(folder: string): Folder {
  if ((folders as string[]).includes(folder)) {
      return folder as Folder
  }
  throw new Error(`Unknown folder ${folder}`)
}