import React, { FC } from 'react'
import { GetCreditor, GetCreditorVariables } from '@/queries/_gen_/GetCreditor'
import Fetch from '@/components/Fetch'
import { GetCreditorQuery } from '@/queries/creditors.queries'
import CenterAlert from '@/components/CenterAlert'
import { UpdateCreditorForm, ReadOnlyCreditorForm } from './components/CreditorGeneralForm'
import { CreditorViewLayout } from './components/CreditorViewLayout'
import * as R from 'ramda'
import { RoutedTabPane } from '@/components/RoutedTabs'
import Container from '@/components/layouts/Container'
import { useRole } from '@/hooks/useRole'

export interface UpdateCreditorViewProps {
  creditorId: number
  pathRoot?: string
}

export const UpdateCreditorView: FC<UpdateCreditorViewProps> = ({ creditorId, pathRoot }) => {
  const { isManager } = useRole()

  return (
    <Fetch<GetCreditor, GetCreditorVariables> query={GetCreditorQuery} container={true} variables={{ id: creditorId }}>
      {data => {
        if (data && data.creditor) {
          const creditor = data.creditor

          const tabs: RoutedTabPane[] = [
            {
              name: 'General',
              path: '',
              render: () => (
                <Container>
                  {isManager ? (
                    <UpdateCreditorForm key={creditor.id} creditor={R.omit(['__typename'], creditor)} />
                  ) : (
                    <ReadOnlyCreditorForm key={creditor.id} creditor={creditor} />
                  )}
                </Container>
              )
            }
          ]

          return <CreditorViewLayout pathRoot={pathRoot || `/creditors/${creditorId}`} header={data.creditor.name} tabs={tabs} />
        }
        return <CenterAlert type='error' message='Creditor not found :(' />
      }}
    </Fetch>
  )
}
