import React, { FC } from 'react'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { CreateTemplateForm } from './components/TemplateReminderForm'
import Container from '@/components/layouts/Container'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { useNavigate } from 'react-router'

export const CreateReminderTemplateView: FC = () => {
  const nav = useNavigate()
  return (
    <HeaderWithContent header='New template'>
      <Container>
        <CreateTemplateForm onSaved={template => nav(`/templates/reminders/${template.id}`)} client={useCurrentClient()} />
      </Container>
    </HeaderWithContent>
  )
}
