// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface Event<T> {
  eventId: string
}

// arg is debtor id
export const DebtorUpdatedEvent: Event<number> = {
  eventId: 'debtor_updated'
}
