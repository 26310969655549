import { tracer } from '@/agent'
import * as otel from '@opentelemetry/api'
import React, { useMemo, ComponentType } from 'react'
import { FunctionComponent } from 'react'

const RENDER_TIMEOUT_MS = 250

export class TraceScope {
  name: string
  parent: TraceScope | undefined

  // eslint-disable-next-line prettier/prettier
  private currentSpan?: otel.Span
  private currentContext?: otel.Context
  private stopTimeout?: NodeJS.Timeout

  constructor(name: string, parent?: TraceScope) {
    this.name = name
    this.parent = parent
  }

  start(attributes?: otel.Attributes): void {
    if (!this.currentSpan) {
      const parentContext = this.parent?.getCurrentContext() ?? otel.context.active()
      this.currentSpan = tracer.startSpan(this.name, attributes ?? {}, parentContext)
      this.currentContext = otel.trace.setSpan(parentContext, this.currentSpan)
      this.resetStopTimeout()
    }
  }

  withContext<T>(fn: () => T): T {
    const context = this.getCurrentContext()
    if (context) {
      return otel.context.with(context, fn)
    }
    return fn()
  }

  startActiveSpan<T>(spanName: string, attributes: otel.Attributes, fn: (span: otel.Span | undefined) => T): T {
    const context = this.getCurrentContext()
    if (context) {
      return tracer.startActiveSpan(spanName, attributes, context, fn)
    }
    return fn(undefined)
  }

  getCurrentContext(): otel.Context | undefined {
    if (this.currentContext) {
      this.resetStopTimeout()
      return this.currentContext
    }
    return undefined
  }

  stop(): void {
    if (this.currentSpan) {
      this.currentSpan
      this.currentSpan.end()
      this.currentSpan = this.currentContext = undefined
      if (this.stopTimeout) {
        clearTimeout(this.stopTimeout)
        this.stopTimeout = undefined
      }
    }
  }

  private resetStopTimeout() {
    if (this.stopTimeout) {
      clearTimeout(this.stopTimeout)
    }

    this.stopTimeout = setTimeout(() => {
      this.stop()
    }, RENDER_TIMEOUT_MS)
  }
}

export const TraceContext = React.createContext<TraceScope>(new TraceScope('default'))

interface TraceProviderProps {
  children: React.ReactNode
  name: string
  attributes?: otel.Attributes
}

export function TraceProvider({ children, name, attributes }: TraceProviderProps): JSX.Element {
  const parent = useTraceScope()
  const scope = useMemo(() => {
    const scope = new TraceScope(name, parent)
    scope.start(attributes)
    return scope
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, parent])

  scope.start(attributes)

  return <TraceContext.Provider value={scope}>{children}</TraceContext.Provider>
}

export interface WithTraceScopeProps {
  scope: TraceScope
}

type PropsToAttrs<T> = (props: T) => otel.Attributes

export function withSpan<T>(
  name: string,
  propsToAttrs: PropsToAttrs<T> | undefined,
  WrappedComponent: ComponentType<T | (T & WithTraceScopeProps)>
): ComponentType<T> {
  let Child = WrappedComponent
  if (typeof WrappedComponent === 'function') {
    Child = ((props: T & WithTraceScopeProps) =>
      props.scope.withContext(() => (WrappedComponent as FunctionComponent<T>)(props))) as typeof WrappedComponent
  }

  return function WithTraceScope(props: T) {
    return (
      <TraceProvider name={name} attributes={propsToAttrs?.(props) ?? {}}>
        <TraceContext.Consumer>{scope => <Child {...(props as T & JSX.IntrinsicAttributes)} scope={scope} />}</TraceContext.Consumer>
      </TraceProvider>
    )
  }
}

export function withTraceContext<T>(fn: (props: T) => JSX.Element): (props: T) => JSX.Element {
  return (props: T) => {
    const scope = useTraceScope()
    return scope.withContext(() => fn(props))
  }
}

export function useTraceScope(): TraceScope {
  return React.useContext(TraceContext)
}
