import React, { FC } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import { templateTypes, templateTypeNames, TemplateType } from '@/models/templates'

const { Option } = Select

export const TemplateTypeSelect: FC<SelectProps<TemplateType>> = ({ value, ...props }) => (
  <Select {...props} value={value || undefined} placeholder='Select a type...'>
    {templateTypes.map(templateType => (
      <Option key={templateType} value={templateType}>
        {templateTypeNames[templateType]}
      </Option>
    ))}
  </Select>
)
