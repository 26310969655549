import { BuildFilterVariablesFn } from '@/hooks/useSearchQuery'
import { SearchDebtorsVariables } from '@/queries/_gen_/SearchDebtors'
import { takeFirst } from './array'

export const buildFilterVariables: BuildFilterVariablesFn<SearchDebtorsVariables> = filters => {
  const query = (takeFirst(filters.query) || '').trim()
  if (query) {
    return {
      where: {
        _or: [
          {
            name: { _ilike: `%${query.trim()}%` }
          },
          {
            debtor_contacts: {
              value: { _ilike: `%${query.trim()}%` }
            }
          }
        ]
      }
    }
  }
  return {}
}
