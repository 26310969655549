import React, { ReactElement, useCallback, useEffect, FC } from 'react'
import { DocumentNode } from 'graphql'
import { MutationResult, MutationOptions, useMutation, ExecutionResult } from 'react-apollo'
import message from 'antd/lib/message'

interface ResultType {
  result: unknown
}

interface FormMutationBag<Values, TData> {
  onSubmit: (values: Values) => Promise<ExecutionResult<TData>>
  result: MutationResult<TData>
}

interface Props<Values, TData extends ResultType, TVariables> extends Omit<MutationOptions, 'variables' | 'optimisticResponse'> {
  mutation: DocumentNode
  buildVariables: (values: Values) => TVariables
  children: FC<FormMutationBag<Values, TData>>
  successMessage?: string | ((result: TData) => string)
  onSaved?: (result: TData) => void
}

export function FormMutation<Values, TData extends ResultType, TVariables>(props: Props<Values, TData, TVariables>): ReactElement {
  const { mutation, buildVariables, children, successMessage, onSaved, ...mutationProps } = props

  const Children = children

  const onCompleted = useCallback(
    (data: TData) => {
      if (data.result && successMessage) {
        message.success(typeof successMessage === 'string' ? successMessage : successMessage(data))
      }
    },
    [successMessage]
  )

  const [mutate, result] = useMutation<TData, TVariables>(mutation, {
    onCompleted,
    ...mutationProps
  })

  useEffect(() => result.error && message.error(result.error.message), [result.error])
  useEffect(() => {
    if (onSaved && result && result.data && result.called && !result.loading) {
      onSaved(result.data)
    }
  }, [onSaved, result])
  const onSubmit = useCallback((values: Values) => mutate({ variables: buildVariables(values) }), [mutate, buildVariables])

  return <Children onSubmit={onSubmit} result={result} />
}
