import React, { FC, useMemo, useCallback } from 'react'
import Button, { ButtonProps } from 'antd/lib/button'
import { useReminderStats } from '../hooks/useReminderStats'
import { useSendReminders } from '../hooks/useSendReminders'
import { useRemindersSendRequstsSub } from '../hooks/useRemindersSendRequestsSub'
import { reminder_bool_exp } from '@/queries/_gen_global'

export type SendRemindersButtonProps = Omit<ButtonProps, 'loading' | 'onClick' | 'disabled'>

export const SendRemindersButton: FC<SendRemindersButtonProps> = props => {
  const { totalSendable, loading, error, where } = useReminderStats()

  const { sendReminders, sends } = useSendReminders()
  const { data } = useRemindersSendRequstsSub()

  const sendInProgress = !!(data && data.reminders_send_request.find(x => !x.completed_at))

  const pendingSends = useMemo(() => sends.filter(s => !!s.loading), [sends])

  const send = useCallback(() => {
    if (totalSendable < 100 || confirm(`Total ${totalSendable} reminders will be sent, are you sure you want to send?`)) {
      const nwhere: reminder_bool_exp = {
        _and: [where, { status: { _eq: 'new' } }]
      }
      sendReminders(nwhere, totalSendable)
    }
  }, [where, sendReminders, totalSendable])

  const label = ((): string => {
    if (error) {
      return 'Error occurred'
    } else if (!!pendingSends.length || sendInProgress) {
      return 'Sending...'
    } else if (loading) {
      return 'Calculating...'
    } else if (totalSendable === 0) {
      return 'Nothing to send.'
    }
    return `Send ${totalSendable} new reminders`
  })()

  return (
    <Button
      type={'primary'}
      danger={!!error}
      loading={!!pendingSends.length || loading || sendInProgress}
      onClick={send}
      disabled={!!pendingSends.length || loading || totalSendable === 0 || sendInProgress}
      {...props}
    >
      {label}
    </Button>
  )
}
