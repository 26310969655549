import { CountUsersQuery } from '@/queries/users.queries'
import { CountUsers, CountUsersVariables } from '@/queries/_gen_/CountUsers'
import { queryFieldValidator } from '@/utils/form'
import ApolloClient from 'apollo-client'

export const userUniquenessValidator = (client: ApolloClient<any>, field: string, existingId?: string) =>
  queryFieldValidator<CountUsers, CountUsersVariables>(
    client,
    CountUsersQuery,
    value => ({
      where: {
        [field]: { _eq: value },
        ...(existingId ? { id: { _neq: existingId } } : {})
      }
    }),
    r => !(r.result && r.result.aggregate && r.result.aggregate.count)
  )
