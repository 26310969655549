import React, { FC } from 'react'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import { SearchOutlined } from '@ant-design/icons'

export const TextFilter: FC<FilterDropdownProps> = ({ setSelectedKeys, confirm, selectedKeys, clearFilters }) => {
  const doSearch = () => {
    confirm && confirm()
  }

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={'Search'}
        ref={i => i && i.focus()}
        value={selectedKeys ? selectedKeys[0] : ''}
        onChange={e => setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={doSearch}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button type='primary' onClick={doSearch} icon={<SearchOutlined />} size='small' style={{ width: 90, marginRight: 8 }}>
        Search
      </Button>
      <Button onClick={() => clearFilters && selectedKeys && clearFilters()} size='small' style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  )
}
