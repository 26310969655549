import React from 'react'
import tinymce, { Editor, EditorAPI } from 'tinymce'
import ReactDOM from 'react-dom'
import { InvoiceTableConfigForm, Values, defaultValues } from './InvoiceTableConfigForm'
import { TABLE_VAR_PREFIX } from 'common/models/template'

export const INVOICE_TABLE_PLUGIN_ID = 'invoicetable'

const INVOICE_TABLE_MOUNT_ID = `${INVOICE_TABLE_PLUGIN_ID}-dialog-mount`

function InvoiceTablePlugin(editor: Editor) {
  const openDialog = () => {
    const data: { values: Values } = { values: defaultValues }
    const dialog = editor.windowManager.open({
      title: 'Item Table',
      body: {
        type: 'panel',
        items: [
          {
            type: 'htmlpanel', // component type
            html: `<div id="${INVOICE_TABLE_MOUNT_ID}"></div>`
          }
        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close'
        },
        {
          type: 'submit',
          text: 'Save',
          primary: true
        }
      ],
      onSubmit(api: EditorAPI) {
        // Insert content when the window form is submitted
        editor.insertContent(
          '${' + TABLE_VAR_PREFIX + JSON.stringify([data.values.columns.map(c => [c.id, c.label]), ['total', data.values.totalLabel]]) + '}'
        )
        api.close()
      }
    })
    const mount = document.getElementById(INVOICE_TABLE_MOUNT_ID)
    ReactDOM.render(<InvoiceTableConfigForm onChange={values => (data.values = values)} />, mount)
    return dialog
  }

  // Add a button that opens a window
  editor.ui.registry.addButton(INVOICE_TABLE_PLUGIN_ID, {
    text: 'Item table',
    onAction() {
      // Open window
      openDialog()
    }
  })

  // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
  editor.ui.registry.addMenuItem(INVOICE_TABLE_PLUGIN_ID, {
    text: 'Item table',
    onAction() {
      // Open window
      openDialog()
    }
  })

  return {
    getMetadata() {
      return {
        name: 'Invoice table plugin',
        url: ''
      }
    }
  }
}

tinymce.PluginManager.add(INVOICE_TABLE_PLUGIN_ID, InvoiceTablePlugin)
