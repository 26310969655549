import React from 'react'
import CardLink from '@/components/CardLink'
import Container from '@/components/layouts/Container'
import { PlusOutlined } from '@ant-design/icons'
import { useRole } from '@/hooks/useRole'

export default () => {
  const { isManager } = useRole()

  return (
    <Container>
      {isManager && (
        <CardLink icon={<PlusOutlined />} title='New' to='/debtors/new'>
          <p>Add a new debtor</p>
        </CardLink>
      )}
    </Container>
  )
}
