import React, { FC, useState, useCallback } from 'react'
import Input from 'antd/lib/input'
import styled from 'styled-components'

interface MarginsFieldProps {
  value: number
  onChange: (value: number) => void
}

const MarginsFieldDiv = styled.div`
  display: flex;

  input {
    width: 5em !important;
    margin-right: 0.5em !important;
  }

  input + label {
    padding-top: 5px;
  }

  table {
    margin: auto;
  }
`

const MarginsField: FC<MarginsFieldProps> = ({ value, onChange }) => (
  <MarginsFieldDiv>
    <Input type='number' value={value} onChange={e => onChange(Number(e.target.value))} />
    <label>cm</label>
  </MarginsFieldDiv>
)

export interface Margins {
  left: number
  right: number
  top: number
  bottom: number
}

interface MarginsPluginConfigFormProps {
  margins: Margins
  onChange: (margins: Margins) => void
}

export const MarginsPluginConfigForm: FC<MarginsPluginConfigFormProps> = ({ margins, onChange }) => {
  const [values, setValues] = useState(margins)

  const update = useCallback(
    (vals: Partial<Margins>) => {
      const newVals = {
        ...values,
        ...vals
      }
      setValues(newVals)
      onChange(newVals)
    },
    [onChange, values]
  )

  return (
    <div className='ant-form'>
      <table>
        <tbody>
          <tr>
            <td></td>
            <td>
              <MarginsField value={values.top} onChange={top => update({ top })} />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <MarginsField value={values.left} onChange={left => update({ left })} />
            </td>
            <td></td>
            <td>
              <MarginsField value={values.right} onChange={right => update({ right })} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <MarginsField value={values.bottom} onChange={bottom => update({ bottom })} />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
