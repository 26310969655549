import { ApolloError } from 'apollo-client'
import React, { FC, useState, useRef, useCallback, useMemo } from 'react'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { useApolloClient } from 'react-apollo'
import { SendRemindersVariables, SendReminders } from '@/queries/_gen_/SendReminders'
import { SendRemindersMutation, GetReminderStatsQueryName, GetReminderIdsQuery } from '@/queries/reminders.queries'
import { reminder_bool_exp } from '@/queries/_gen_global'
import { GetReminderIds, GetReminderIdsVariables } from '@/queries/_gen_/GetReminderIds'
import { ReminderSendRequestStatus } from 'common/models/reminder'
import message from 'antd/lib/message'
import { logger } from '@/logger'

export interface ReminderImportState {
  _id: number
  count: number
  clientId: number
  error?: ApolloError | Error | null
  loading: boolean
  success: boolean
}

export interface ReminderSenderContextValue {
  sends: ReminderImportState[]
  sendReminders: (where: reminder_bool_exp, estimatedCount: number) => number
}

export const ReminderSenderContext = React.createContext<ReminderSenderContextValue>({ sends: [], sendReminders: () => 0 })

export const ReminderSenderContextProvider: FC = ({ children }) => {
  const idRef = useRef(0)
  const [sends, updateStatus] = useState<ReminderImportState[]>([])
  const currentClient = useCurrentClient()
  const apolloClient = useApolloClient()

  const sendReminders = useCallback(
    (where: reminder_bool_exp, estimatedCount: number) => {
      const initialState: ReminderImportState = {
        _id: ++idRef.current,
        count: estimatedCount,
        loading: true,
        success: false,
        clientId: currentClient.id
      }

      logger.info(`Trying to send reminders ${estimatedCount} reminders...`)

      updateStatus(sends => [...sends, initialState])

      function updateSend(update: Partial<ReminderImportState>) {
        updateStatus(sends =>
          sends.map(send =>
            send._id === initialState._id
              ? {
                  ...send,
                  ...update
                }
              : send
          )
        )
      }

      // reminder get ids
      apolloClient
        .query<GetReminderIds, GetReminderIdsVariables>({
          query: GetReminderIdsQuery,
          variables: {
            where,
            client_id: currentClient.id
          },
          fetchPolicy: 'no-cache'

          // send the reminders
        })
        .then(result => {
          updateSend({
            count: result.data.reminder.length
          })

          return apolloClient.mutate<SendReminders, SendRemindersVariables>({
            mutation: SendRemindersMutation,
            variables: {
              client_id: currentClient.id,
              reminder_ids: result.data.reminder.map(r => r.id),
              reminder_requests: result.data.reminder.map(r => ({
                reminder_id: r.id,
                status: ReminderSendRequestStatus.new
              }))
            },
            refetchQueries: [GetReminderStatsQueryName]
          })
        })
        // set success state
        .then(() => {
          logger.info('Triggered remidner send successfully')
          message.success('Successfully triggered reminder sending.')
          updateSend({
            loading: false,
            success: true
          })
        })
        // set error state
        .catch(e => {
          logger.info(`Error sending reminders, ${e.stack || e.message}`)
          message.error('Failed to start sending reminders.')
          updateSend({
            loading: false,
            success: false,
            error: e
          })
        })

      return initialState._id
    },
    [currentClient, apolloClient]
  )

  const value: ReminderSenderContextValue = useMemo(
    () => ({
      sends,
      sendReminders
    }),
    [sends, sendReminders]
  )

  return <ReminderSenderContext.Provider value={value}>{children}</ReminderSenderContext.Provider>
}
