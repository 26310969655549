import { SortState, BuildFilterVariablesFn, BaseVariables } from '@/hooks/useSearchQuery'
import { order_by } from '@/queries/_gen_global'
import { useCallback, useMemo } from 'react'
import { takeFirst } from '../utils/array'
import { dotNotationToNested } from '../utils/object'
import { useCurrentClient } from '@/hooks/useCurrentClient'

export const SortByName: SortState = {
  field: 'name',
  order: order_by.asc
}

export const useSortBy = (field: string, order: order_by) =>
  useMemo(
    () => ({
      field,
      order
    }),
    [field, order]
  )

export const useQueryFilterBuilder = <Variables extends BaseVariables>(fieldName?: string): BuildFilterVariablesFn<Variables> =>
  useCallback(
    filters => {
      const query = takeFirst(filters.query) || ''
      return (
        query && query.trim()
          ? {
              where: dotNotationToNested(fieldName || 'name', { _ilike: `%${query.trim()}%` })
            }
          : {}
      ) as any // CHEAT
    },
    [fieldName]
  )

export const useCurrentClientFilterVariables = <Variables extends BaseVariables>(
  fieldName?: string,
  orNull?: boolean
): Partial<Variables> => {
  const currentClient = useCurrentClient()

  return useMemo(() => {
    if (orNull) {
      return {
        where: {
          _or: [
            dotNotationToNested(fieldName || 'client_id', { _eq: currentClient.id }),
            dotNotationToNested(fieldName || 'client_id', { _is_null: true })
          ]
        }
      } as any
    } else {
      return {
        where: dotNotationToNested(fieldName || 'client_id', { _eq: currentClient.id })
      }
    }
  }, [currentClient, fieldName, orNull])
}
