import styled from 'styled-components'
import React, { ReactNode } from 'react'

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  $fitToContainer?: boolean
}

const Outer = styled.div<Props>`
  flex: 1;
  padding: 1em;
  display: flex;
  flex-direction: column;
  ${(props: Props) => (props.$fitToContainer ? 'height:100%;' : '')}
`

const Inner = styled.div<Props>`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  ${(props: Props) => (props.$fitToContainer ? 'height:100%;' : '')}
`

interface Props extends ContainerProps {
  children: ReactNode
}

export default (props: Props) => (
  <Outer $fitToContainer={props.$fitToContainer}>
    <Inner $fitToContainer={props.$fitToContainer}>{props.children}</Inner>
  </Outer>
)
