import React, { FC, ReactNode } from 'react'
import { FEventFields } from '@/queries/_gen_/FEventFields'
import Comment, { CommentProps } from 'antd/lib/comment'
import Tooltip from 'antd/lib/tooltip'
import { formatDateTimeReadable, fromNow } from '@/utils/date'
import styled from 'styled-components'
import { EventSubject } from './EventSubject'
import { fullName } from 'common/models/user'

const StyledComment = styled(Comment)`
  .ant-comment-inner {
    padding: 0;
  }

  pre {
    margin-bottom: 0;
  }

  .ant-comment-actions {
    margin-top: 0;
  }

  .ant-comment-content-author-name a {
    color: #40a9ff;
  }
`

interface EventItemWrapperProps extends Omit<CommentProps, 'content'> {
  children: ReactNode
  event: FEventFields
}

export const EventItemWrapper: FC<EventItemWrapperProps> = ({ children, event, ...commentProps }) => (
  <StyledComment
    author={
      <>
        <span>{fullName(event.creator)}</span> event on <EventSubject event={event} />
      </>
    }
    datetime={
      <Tooltip title={formatDateTimeReadable(event.created_at)}>
        <span>{fromNow(event.created_at)}</span>
      </Tooltip>
    }
    content={children}
    {...commentProps}
  />
)
