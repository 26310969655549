import { sessionId } from './context'

export interface Context {
  [x: string]: ContextValue
}

// eslint-disable-next-line prettier/prettier
export declare type ContextValue = string | number | boolean | Context | undefined | null

export type LogLevel = 'error' | 'debug' | 'info' | 'warn'

export interface Logger {
  info: (message: string, context?: Context) => void
  error: (message: string, error?: Error, context?: Context) => void
  debug: (message: string, context?: Context) => void
  warn: (message: string, context?: Context) => void
  log: (level: LogLevel, message: string, context?: Context) => void
  setGlobalContext: (name: string, value: string) => void
}

class PayupLogger implements Logger {
  private context: Context = {}

  constructor() {
    this.setGlobalContext('session_id', sessionId)
  }

  info(message: string, context?: Context) {
    this.log('info', message, context)
  }
  error(message: string, error?: Error, context?: Context) {
    this.log('error', message, error ? { ...context, stack: error.stack, message: error.message } : context)
  }
  debug(message: string, context?: Context) {
    this.log('debug', message, context)
  }
  warn(message: string, context?: Context) {
    this.log('warn', message, context)
  }
  setGlobalContext(name: string, value: string) {
    this.context[name] = value
  }

  log(level: LogLevel, message: string, context?: Context) {
    const args = [`${level}: ${message}`, { ...context, ...this.context }]
    if (level === 'error') {
      console.error(...args)
    } else if (level === 'warn') {
      console.warn(...args)
    } else {
      console.log(...args)
    }
  }
}

export const logger: Logger = new PayupLogger()
