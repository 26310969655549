import { ReminderPeriod } from "../models/reminder";
import { assertUnreachable } from './misc';

interface PartialInvoice {
  amount_outstanding: string,
  amount_paid?: string,
  currency: string,
  extra_1?: string | null,
  extra_2?: string | null,
  full_amount?: string | null,
}

function calcInvoiceAmount<D>(invoices: PartialInvoice[], Decimal: D, fn: (inv: PartialInvoice) => D): Record<string, D> {
  return invoices.reduce<Record<string, D>>((result, invoice) => ({
    ...result,
    // @ts-ignore
    [invoice.currency]: (result[invoice.currency] || new Decimal(0)).add(fn(invoice)),
  }), {})
}

// st00pid, Decimal has to be injected. too tired to figure out better way
export function calcInvoiceOutstanding<D>(invoices: PartialInvoice[], Decimal: D): Record<string, D> {
  // @ts-ignore
  return calcInvoiceAmount(invoices, Decimal, invoice => new Decimal(invoice.amount_outstanding))
}

export function calcInvoicePaid<D>(invoices: PartialInvoice[], Decimal: D): Record<string, D> {
  // @ts-ignore
  return calcInvoiceAmount(invoices, Decimal, invoice => new Decimal(invoice.amount_paid || '0'))
}

export function calcInvoiceFullAmount<D>(invoices: PartialInvoice[], Decimal: D): Record<string, D> {
  // @ts-ignore
  return calcInvoiceAmount(invoices, Decimal, invoice => (new Decimal(invoice.full_amount || '0')))
}

interface PartialInvoiceFilter {
  is_late?: boolean | null
  is_sending_suspended?: boolean | null
  due_date?: any
}

interface PartialReminderFilter {
  period: string
  period_days_from?: number | null
  period_days_to?: number | null
}

type DifferenceInCalendarDays = (
  dateLeft: Date | number,
  dateRight: Date | number
) => number

function toDate(value: string | number | Date): number | Date {
  if (typeof value === 'string') {
    return new Date(value);
  }
  return value;
}

// returns invoices that would be sent for the reminder, but including those for which sending is suspended
export function filterApplicableInvoices<I extends PartialInvoiceFilter>(reminder: PartialReminderFilter, invoices: I[], differenceInCalendarDays: DifferenceInCalendarDays): I[] {
  const period = reminder.period as ReminderPeriod
  return invoices.filter(invoice => {
      switch(period) {
        case ReminderPeriod.all:
          return true;
        case ReminderPeriod.late:
          return invoice.is_late;
        case ReminderPeriod.notLate: {
          if (typeof reminder.period_days_from === 'number' && typeof reminder.period_days_to === 'number' && invoice.due_date) {
            const daysPast = differenceInCalendarDays(new Date(), toDate(invoice.due_date));
            return !invoice.is_late && reminder.period_days_from <= daysPast && reminder.period_days_to >= daysPast;
          }
          return !invoice.is_late;
        }
        default:
          return assertUnreachable(period); 
      }
    });
}

// returns invoices to send for the reminder, suspended invoices excluded
export function filterSendableInvoices<I extends PartialInvoiceFilter >(reminder: PartialReminderFilter, invoices: I[], differenceInCalendarDays: DifferenceInCalendarDays): I[] {
  return filterApplicableInvoices(reminder, invoices.filter(invoice => !invoice.is_sending_suspended), differenceInCalendarDays);
}
