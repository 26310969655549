import React, { FC } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import { templateLanguages, templateLanguageNames, TemplateLanguage } from '@/models/templates'

const { Option } = Select

export const LangaugeSelect: FC<SelectProps<TemplateLanguage>> = ({ value, ...props }) => (
  <Select {...props} value={value || undefined} placeholder='Select a langauge...'>
    {templateLanguages.map(lang => (
      <Option key={lang} value={lang}>
        {templateLanguageNames[lang]}
      </Option>
    ))}
  </Select>
)
