import * as qs from 'query-string'
import { useCallback, useMemo } from 'react'
import * as R from 'ramda'
import { useNavigate, useLocation } from 'react-router'

type UpdateFn = (values: Record<string, string | null>) => void

export function useQueryParams(): [qs.ParsedQuery, UpdateFn] {
  const nav = useNavigate()
  const location = useLocation()
  const parsed = useMemo(() => qs.parse(location.search), [location.search])

  const update: UpdateFn = useCallback(
    values => {
      const omitKeys = Object.keys(values).filter(key => values[key] === null)
      const queryParamsString = qs.stringify({
        ...R.omit(omitKeys, parsed),
        ...R.omit(omitKeys, values)
      })
      nav(location.pathname + (queryParamsString ? '?' : '') + queryParamsString)
    },
    [parsed, nav, location]
  )

  return [parsed, update]
}
