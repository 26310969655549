import React, { FC } from 'react'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'

import { UserProfileForm } from './users/components/UserProfileForm'

export const ProfileView: FC = () => (
  <HeaderWithContent header='My profile'>
    <UserProfileForm />
  </HeaderWithContent>
)
