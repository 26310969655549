import React from 'react'
import CardLink from '@/components/CardLink'
import Container from '@/components/layouts/Container'
import { PlusOutlined } from '@ant-design/icons'
import { useRole } from '@/hooks/useRole'

export default () => {
  const { isAdmin } = useRole()
  return (
    <Container>
      {isAdmin && (
        <CardLink icon={<PlusOutlined />} title='New' to='/clients/new'>
          <p>Add a new client</p>
        </CardLink>
      )}
    </Container>
  )
}
