import React, { FC } from 'react'
import { Drawer } from '@/components/Drawer'
import { UpdateCasecardView } from './UpdateCasecardView'

export interface UpdateCasecardDrawerProps {
  path: string
  onClose: () => void
  casecardId?: number
  readOnly: boolean
}

export const UpdateCasecardDrawer: FC<UpdateCasecardDrawerProps> = props => {
  const { onClose, casecardId, path, readOnly } = props

  return (
    <Drawer width={720} onClose={onClose} visible={!!casecardId}>
      {casecardId && <UpdateCasecardView pathRoot={path} casecardId={casecardId} readOnly={readOnly} />}
    </Drawer>
  )
}
