import CenterAlert from '@/components/CenterAlert'
import React, { FC } from 'react'
import Fetch from '@/components/Fetch'
import Container from '@/components/layouts/Container'
import { RoutedTabPane } from '@/components/RoutedTabs'
import { CasecardViewLayout } from './CasecardViewLayout'
import { UpdateCasecardForm } from './CasecardGeneralForm'
import { GetCasecardQuery } from '@/queries/casecards.queries'
import { GetCasecard, GetCasecardVariables } from '@/queries/_gen_/GetCasecard'

export interface UpdateCasecardViewProps {
  casecardId: number
  pathRoot?: string
  readOnly: boolean
}

export const UpdateCasecardView: FC<UpdateCasecardViewProps> = ({ casecardId, pathRoot, readOnly }) => (
  <Fetch<GetCasecard, GetCasecardVariables> query={GetCasecardQuery} container={true} variables={{ id: casecardId }}>
    {data => {
      const casecard = data.casecard
      if (casecard) {
        const tabs: RoutedTabPane[] = [
          {
            name: 'General',
            path: '',
            render: () => (
              <Container>
                <UpdateCasecardForm casecard={casecard} readOnly={readOnly} />
              </Container>
            )
          }
        ]

        return <CasecardViewLayout pathRoot={pathRoot || `/casecards/${casecardId}`} header={casecard.case_number} tabs={tabs} />
      } else {
        return <CenterAlert type='error' message='Casecard not found :(' />
      }
    }}
  </Fetch>
)
