import React, { FC } from 'react'
import { Drawer } from '@/components/Drawer'
import { UpdateInvoiceView } from './UpdateInvoiceView'

export interface UpdateInvoiceDrawerProps {
  path: string
  onClose: () => void
  invoiceId?: number
}

export const UpdateInvoiceDrawer: FC<UpdateInvoiceDrawerProps> = props => {
  const { onClose, invoiceId, path } = props

  return (
    <Drawer width={720} onClose={onClose} visible={!!invoiceId}>
      {invoiceId && <UpdateInvoiceView pathRoot={path} invoiceId={invoiceId} />}
    </Drawer>
  )
}
