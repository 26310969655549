import React, { FC, useMemo } from 'react'
import { FilterComponentProps } from '@/components/infinite_list/InfiniteList'
import Container from '@/components/layouts/Container'
import Input from 'antd/lib/input'
import { Dropdown } from '@/components/Dropdown'
import styled from 'styled-components'
import { fontColor } from '@/style-vars'
import { takeFirst } from '@/utils/array'

const DropdownsBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1em;

  .dropdown {
    margin-right: 1em;

    .ant-dropdown-link {
      font-weight: bold;
      color: ${fontColor};
    }
  }
`

export const DEFAULT_ARCHIVED_FILTER_VALUE = 'no'

export const TemplatesListFilter: FC<FilterComponentProps> = props => {
  const { filters, setFilters } = props

  const archived = useMemo(
    () => [
      {
        value: 'yes',
        label: 'Yes'
      },
      {
        value: DEFAULT_ARCHIVED_FILTER_VALUE,
        label: 'No'
      }
    ],
    []
  )

  return (
    <Container>
      <Input.Search
        value={takeFirst(filters.query) || ''}
        placeholder='Template name'
        onChange={e => setFilters({ query: [e.target.value] })}
      />
      <DropdownsBar>
        <Dropdown
          label='Archived'
          options={archived}
          value={takeFirst(filters.archived) || DEFAULT_ARCHIVED_FILTER_VALUE}
          onChange={value => setFilters({ ...filters, archived: value === DEFAULT_ARCHIVED_FILTER_VALUE ? null : [value] })}
        />
      </DropdownsBar>
    </Container>
  )
}
