import React, { FC, useCallback } from 'react'
import Timeline from 'antd/lib/timeline'
import { FormikField } from '@/components/form/FormikField'
import Input from 'antd/lib/input'
import AntdForm from 'antd/lib/form'
import * as R from 'ramda'
import { RemoveItemButton } from '@/components/form/RemoveItemButton'
import styled from 'styled-components'
import { ReminderTypeSelect, ReminderTypeSelectOnChange } from './ReminderTypeSelect'
import { FormikFieldError } from '@/components/form/FormError'
import { ReminderType } from '@/models/reminders'
import { CategoryFormStepValue } from './categoryFormModels'

const NumInput = styled(Input)`
  width: 5em !important;
`

export const TemplateFormikField = styled(FormikField)`
  width: 15em;
`

const CustomTimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-head {
    margin-top: 0;
  }
`

interface CategoryStepFormItemProps {
  index: number
  before: boolean
  value: CategoryFormStepValue
  clientId?: number
  onDelete: () => void
  setFieldValue: (field: string, value: any) => void
}

const DaysField: FC<{ name: string; label: string; setValue: (value: any) => void; before: boolean }> = ({
  name,
  label,
  setValue,
  before
}) => {
  const onChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const num = Number(evt.target.value)
      if (!isNaN(num)) {
        setValue(num * (before ? -1 : 1))
      }
    },
    [setValue, before]
  )

  return (
    <FormikField label={label} name={name}>
      {({ field: { value, ...rest } }) => (
        <NumInput size='small' min={0} max={10000} value={Math.abs(value)} onChange={onChange} {...R.omit(['onChange'], rest)} />
      )}
    </FormikField>
  )
}

export const CategoryStepFormItem: FC<CategoryStepFormItemProps> = props => {
  const { before, onDelete, index, setFieldValue, clientId, value } = props

  const onReminderTypeChange: ReminderTypeSelectOnChange = useCallback(
    value => {
      if (value) {
        setFieldValue(`category_steps.${index}.template_id`, value.templateId || null)
        setFieldValue(`category_steps.${index}.reminder_type`, value.reminderType)
      } else {
        setFieldValue(`category_steps.${index}.template_id`, null)
        setFieldValue(`category_steps.${index}.reminder_type`, null)
      }
    },
    [setFieldValue, index]
  )

  return (
    <CustomTimelineItem color={before ? 'green' : 'red'}>
      <div className='ant-form ant-form-inline'>
        <DaysField
          label='From'
          name={`category_steps.${index}.days_from`}
          before={before}
          setValue={val => setFieldValue(`category_steps.${index}.days_from`, val)}
        />
        <DaysField
          label='To'
          name={`category_steps.${index}.days_to`}
          before={before}
          setValue={val => setFieldValue(`category_steps.${index}.days_to`, val)}
        />
        <TemplateFormikField name={`category_steps.${index}.template_id`}>
          {() => (
            <ReminderTypeSelect
              value={{ reminderType: value.reminder_type as ReminderType, templateId: value.template_id }}
              size='small'
              clientId={clientId}
              onChange={onReminderTypeChange}
            />
          )}
        </TemplateFormikField>
        <AntdForm.Item>
          <RemoveItemButton onClick={onDelete} />
        </AntdForm.Item>
      </div>
      <FormikFieldError $block={true} name={`category_steps.${index}`} />
    </CustomTimelineItem>
  )
}
