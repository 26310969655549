import { useQuery } from 'react-apollo'
import { GetReminderStats, GetReminderStatsVariables } from '@/queries/_gen_/GetReminderStats'
import React, { FC, useMemo } from 'react'
import { reminder_bool_exp } from '@/queries/_gen_global'
import { GetReminderStatsQuery } from '@/queries/reminders.queries'
import { ApolloError } from 'apollo-client'
import { useRemindersSendRequstsSub } from '../../hooks/useRemindersSendRequestsSub'
import useUpdateEffect from '@/hooks/useUpdateEffect'

export interface ReminderStatsContextValue {
  sendableNewEmails: number
  sendableNewSMS: number
  totalSendable: number
  missingContacts: number
  loading: boolean
  loaded: boolean
  error?: ApolloError
  where: reminder_bool_exp
}

const empty: ReminderStatsContextValue = {
  sendableNewEmails: 0,
  sendableNewSMS: 0,
  totalSendable: 0,
  missingContacts: 0,
  loading: false,
  loaded: false,
  where: {}
}

export interface ReminderStatsContextProviderProps {
  where: reminder_bool_exp
}

export const ReminderStatsContext = React.createContext<ReminderStatsContextValue>(empty)

export const ReminderStatsContextProvider: FC<ReminderStatsContextProviderProps> = props => {
  const { where, children } = props

  const result = useQuery<GetReminderStats, GetReminderStatsVariables>(GetReminderStatsQuery, {
    variables: {
      where
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: sendRequestsData } = useRemindersSendRequstsSub()

  useUpdateEffect(() => {
    result.refetch()
  }, [sendRequestsData])

  const value = useMemo((): ReminderStatsContextValue => {
    const { data, loading, error } = result
    const sendableNewEmails = data?.total_new_emails?.aggregate?.count || 0
    const sendableNewSMS = data?.total_new_sms?.aggregate?.count || 0
    return {
      loading,
      loaded: !!data,
      error,
      sendableNewEmails: data?.total_new_emails?.aggregate?.count || 0,
      sendableNewSMS: data?.total_new_sms?.aggregate?.count || 0,
      missingContacts: data?.missing_contacts.aggregate?.count || 0,
      totalSendable: sendableNewEmails + sendableNewSMS,
      where
    }
  }, [result, where])

  return <ReminderStatsContext.Provider value={value}>{children}</ReminderStatsContext.Provider>
}
