import { Folder } from 'common/models/files'
import { Context, logger } from '@/logger'
import { makeId } from 'common/utils/id'
import { makeRequestHeaders } from '@/context'

export const fetch = (url: string, reqinfo: RequestInit): Promise<Response> => {
  const requestId = makeId()
  return window.fetch(url, {
    credentials: 'include',
    ...reqinfo,
    headers: {
      ...(reqinfo.headers || {}),
      ...makeRequestHeaders(requestId)
    }
  })
}

interface FileResult {
  location: string
  filename: string
}

export const uploadFile = (folder: Folder, clientId: number, content: Blob, filename: string): Promise<FileResult> => {
  const body = new FormData()
  body.append('file', content, filename)

  const requestId = makeId()
  const ctx: Context = { request_id: requestId }

  logger.info(`Uploading file ${filename} to bucket ${folder} for client ${clientId}`, ctx)
  return fetch(`${__CONFIG__.backendUri}/files/upload/${folder}/${clientId}`, {
    method: 'POST',
    body
  })
    .then(res =>
      res.json().then((resp: any) => {
        if (res.status === 200) {
          logger.info(`Upload great success, loc: ${resp.location}`, ctx)
          return resp
        } else {
          logger.error('Failed to upload file', resp, ctx)
          throw resp
        }
      })
    )
    .catch(e => {
      logger.error('Failed to upload file', e, ctx)
      throw e
    })
}
