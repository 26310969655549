import React, { FC, ReactNode, useEffect } from 'react'
import { GetMe_me_me_user, GetMe } from '@/queries/_gen_/GetMe'
import { useQuery } from 'react-apollo'
import { GetMeQuery } from '@/queries/users.queries'
import { isUnauthorizedError } from '@/utils/apollo'
import Loader from '../Loader'
import Container from '../layouts/Container'
import Alert from 'antd/lib/alert'
import { Faro } from '@grafana/faro-web-sdk'

// eslint-disable-next-line prettier/prettier
declare global {
  interface Window {
    faro: Faro
  }
}

export type Profile = GetMe_me_me_user

export const ProfileContext = React.createContext<Profile | null>(null)

interface ProfileProviderProps {
  children: ReactNode
}

export const ProfileProvider: FC<ProfileProviderProps> = props => {
  const { children } = props

  const { data, error, loading } = useQuery<GetMe>(GetMeQuery, {
    fetchPolicy: 'network-only'
  })

  const profile: Profile | null = data?.me?.me_user ?? null

  useEffect(() => {
    const user = data?.me?.me_user
    if (user && window.faro) {
      window.faro.api.setUser({
        id: user.id,
        email: user.email
      })
    }
  }, [data])

  if (loading) {
    return <Loader />
  }
  if (error && !isUnauthorizedError(error)) {
    return (
      <Container>
        <Alert type='error' message={error.message} />
      </Container>
    )
  }

  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>
}
