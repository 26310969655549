import React, { FC, useMemo } from 'react'
import Card from 'antd/lib/card'
import Alert from 'antd/lib/alert'
import { Link } from 'react-router-dom'
import { usePathWithParamsBuilder } from '@/hooks/usePathWithParams'
import { SendRemindersButton } from './SendRemindersButton'
import { useReminderStats } from '../hooks/useReminderStats'
import Spinner from '@/components/Spinner'

export const ReminderAutoSender: FC = () => {
  const { sendableNewEmails, sendableNewSMS, loading, missingContacts, loaded } = useReminderStats()

  const pathBuilder = usePathWithParamsBuilder()

  const sendMessage: JSX.Element | null = useMemo(() => {
    if (sendableNewEmails && sendableNewSMS) {
      return (
        <p>
          Based on current filter settings, <strong>{sendableNewEmails} new email reminders</strong> and{' '}
          <strong>{sendableNewSMS} new SMS reminders</strong> can be sent.
        </p>
      )
    } else if (sendableNewEmails) {
      return (
        <p>
          Based on current filter settings, <strong>{sendableNewEmails} new email reminders</strong> can be sent.
        </p>
      )
    } else if (sendableNewSMS) {
      return (
        <p>
          Based on current filter settings, <strong>{sendableNewSMS} new SMS reminders</strong> can be sent.
        </p>
      )
    } else if (!!missingContacts) {
      return (
        <Alert
          type='error'
          showIcon={true}
          message={
            <>
              Based on current filter settings, there are{' '}
              <Link to={pathBuilder('/reminders', { f_has_contacts: 'no' })}>{missingContacts} reminders that cannot be sent</Link> because
              there is no contact information.
            </>
          }
        />
      )
    } else {
      return (
        <p>
          There are no SMS or Email reminders to be sent based on current filters. &nbsp;
          <Link to='/reminders?f_status=new'>show all new reminders</Link>
        </p>
      )
    }
  }, [sendableNewEmails, sendableNewSMS, missingContacts, pathBuilder])

  return (
    <>
      {loading && !loaded && <Spinner size='large' />}
      {loaded && (
        <>
          <Card>
            {sendMessage}
            <br />
            <SendRemindersButton />
          </Card>
        </>
      )}
    </>
  )
}
