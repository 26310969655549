import React, { FC } from 'react'
import { ApolloError } from 'apollo-client'
import Alert from 'antd/lib/alert'

interface ErrorMessageProps {
  error?: Error | ApolloError
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => {
  if (error) {
    return <Alert type='error' message={error.message} />
  }
  return null
}
