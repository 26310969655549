import Table, { TableProps, ColumnProps } from 'antd/lib/table'
import { DocumentNode } from 'graphql'
import React, { useMemo, useCallback, ReactNode } from 'react'
import {
  useSearchQuery,
  BaseItem,
  BaseVariables,
  BaseResult,
  SortState,
  BuildFilterVariablesFn,
  SearchQueryBag
} from '@/hooks/useSearchQuery'
import { order_by } from '@/queries/_gen_global'
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { takeFirst } from '@/utils/array'
import { mapObj } from '@/utils/object'
import { withSpan } from '../contexts/TraceContext'

type BaseTableProps<T> = Omit<TableProps<T>, 'loading' | 'dataSource' | 'pagination' | 'onChange' | 'rowKey'>

export interface GraphqlTableProps<T extends BaseItem, Variables extends BaseVariables, Result extends BaseResult<T>>
  extends BaseTableProps<T> {
  query: DocumentNode
  extraVariables: Partial<Variables>
  columns: ColumnProps<T>[]
  buildFilterVariables?: BuildFilterVariablesFn<Variables>
  renderExtraBottom?: (searchBag: SearchQueryBag<T, Variables, Result>) => ReactNode
  renderExtraTop?: (searchBag: SearchQueryBag<T, Variables, Result>) => ReactNode
  emptyText?: string
}

type GraphqlTableComponent = <T extends BaseItem, Variables extends BaseVariables, Result extends BaseResult<T>>(
  props: GraphqlTableProps<T, Variables, Result>
) => JSX.Element

export const GaphqlTable = withSpan(
  'GraphqlTable',
  () => ({}),
  <T extends BaseItem, Variables extends BaseVariables, Result extends BaseResult<T>>(
    props: GraphqlTableProps<T, Variables, Result>
  ): JSX.Element => {
    const { columns, query, extraVariables, buildFilterVariables, renderExtraBottom, renderExtraTop, ...tableProps } = props

    const searchBag = useSearchQuery<T, Variables, Result>({
      query,
      extraVariables,
      buildFilterVariables,
      defaultPageSize: 10
    })

    const { items, total, loading, state, setState, error } = searchBag

    const onChange: TableProps<T>['onChange'] = useCallback(
      (
        newPagination: TablePaginationConfig,
        filters: Record<string, Array<string | number | boolean> | null>,
        sorter: SorterResult<T> | SorterResult<T>[]
      ) => {
        const _sorter = takeFirst(sorter)
        const sortKey = (_sorter?.column as any)?.sortKey
        const field = _sorter ? takeFirst(_sorter.field) : null
        const sort: SortState =
          field && _sorter
            ? {
                field: String(sortKey || field),
                order: _sorter.order === 'descend' ? order_by.desc : order_by.asc
              }
            : {
                field: 'id',
                order: order_by.desc
              }

        const _filters = mapObj(
          filters,
          k => k,
          val => (val ? val.map(i => String(i)) : val)
        )

        setState({
          pagination: {
            pageSize: newPagination.pageSize || state.pagination.pageSize,
            currentPage: newPagination.current || state.pagination.currentPage
          },
          sort,
          ...(_filters ? { filter: _filters } : {})
        })
      },
      [state, setState]
    )

    const columnsTransformed: ColumnProps<T>[] = useMemo(
      () =>
        columns.map(column => {
          const filteredValue = column.key && state.filter.hasOwnProperty(column.key) && state.filter[column.key]
          return {
            ...column,
            filteredValue: filteredValue || [],
            sortOrder:
              column.dataIndex === state.sort.field || (column as any).sortKey === state.sort.field
                ? state.sort.order == order_by.desc
                  ? 'descend'
                  : 'ascend'
                : undefined
          }
        }),
      [columns, state]
    )

    const pagination: TablePaginationConfig = useMemo(
      () => ({
        total,
        current: state.pagination.currentPage,
        pageSize: state.pagination.pageSize
      }),
      [state, total]
    )

    return (
      <>
        {renderExtraTop ? renderExtraTop(searchBag) : null}
        <Table<T>
          {...tableProps}
          columns={columnsTransformed}
          loading={loading}
          dataSource={items}
          pagination={pagination}
          onChange={onChange}
          rowKey='id'
          locale={{
            emptyText: error?.message ?? props.emptyText
          }}
        />
        {total ? <p>&nbsp;&nbsp;Total: {total}</p> : null}
        {renderExtraBottom ? renderExtraBottom(searchBag) : null}
      </>
    )
  }
) as GraphqlTableComponent
