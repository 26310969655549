import { ApolloQueryResult, ApolloError } from 'apollo-client'
import { ErrorResponse } from 'apollo-link-error'
class QueryError extends Error {
  messages: string[]

  constructor(messages: string[]) {
    super()
    this.messages = messages
  }
}

export function extractQueryResult<T>(result: ApolloQueryResult<T>): T {
  if (result.errors) {
    throw new QueryError(result.errors.map(e => e.message))
  }
  return result.data
}

export function isUnauthorizedError(error: ApolloError | ErrorResponse): boolean {
  return !!error.graphQLErrors?.find(g => g.extensions?.code === 'access-denied')
}
