import React, { FC } from 'react'
import styled from 'styled-components'
import { FilterComponentProps } from './InfiniteList'
import Input from 'antd/lib/input'
import { takeFirst } from '@/utils/array'

const WithPadding = styled.div`
  padding: 1em;
`

export const InfListQueryFilter: FC<FilterComponentProps> = ({ filters, setFilters }) => (
  <WithPadding>
    <Input.Search value={takeFirst(filters.query) || ''} onChange={e => setFilters({ query: [e.target.value] })} />
  </WithPadding>
)
