import React, { FC } from 'react'
import { RoutedTabPane, RoutedTabs } from '@/components/RoutedTabs'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'

interface Props {
  header: string
  tabs: RoutedTabPane[]
  pathRoot: string
}

export const CreditorViewLayout: FC<Props> = props => (
  <HeaderWithContent header={props.header}>
    <RoutedTabs pathRoot={props.pathRoot} type='card' animated={false} panes={props.tabs} />
  </HeaderWithContent>
)
