import { BaseVariables, BuildFilterVariablesFn, FilterState } from '@/hooks/useSearchQuery'

export type FilterBuilderKeys<Variables extends BaseVariables> = { [key: string]: (...value: string[]) => Variables['where'] }

export function createFilterBuilder<Variables extends BaseVariables>(
  keys: FilterBuilderKeys<Variables>,
  defaults: FilterState = {}
): BuildFilterVariablesFn<Variables> {
  return filters =>
    ({
      where: {
        _and: Object.entries(keys).reduce<Array<Variables['where']>>((vars, [fkey, filterFn]) => {
          const value = filters[fkey] ?? defaults[fkey]
          return value !== null && value !== undefined && value.length ? [...vars, filterFn(...value)] : vars
        }, [])
      }
    } as any)
}
