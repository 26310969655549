import React, { FC } from 'react'
import List from 'antd/lib/list'
import { Route, Routes } from 'react-router'
import { SearchTemplates, SearchTemplatesVariables } from '@/queries/_gen_/SearchTemplates'
import { ItemLink, InfiniteList } from '@/components/infinite_list/InfiniteList'
import { SideWithContent as L } from '@/components/layouts/CustomLayouts'
import { SearchRemindersTemplatesQuery } from '@/queries/remindersTemplates.queries'
import {
  TemplateWithId,
  templateTypeNames,
  TemplateType,
  templateLanguageNames,
  TemplateLanguage,
  InvoiceTemplateWithId
} from '@/models/templates'
import { RemindersTemplatesIndexView, InvoicesTemplatesIndexView } from './TemplatesIndexView'
import { CreateReminderTemplateView } from './CreateReminderTemplateView'
import { UpdateReminderTemplateView } from './UpdateReminderTemplateView'
import styled from 'styled-components'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { SortByName, useCurrentClientFilterVariables } from '@/hooks/searchHelperHooks'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { InfListAddButton } from '@/components/infinite_list/InfListAddButton'
import { useSearchQuery } from '@/hooks/useSearchQuery'
import { SearchInvoiceTemplatesQuery } from '@/queries/invoicesTemplates.queries'
import { SearchInvoiceTemplates, SearchInvoiceTemplatesVariables } from '@/queries/_gen_/SearchInvoiceTemplates'
import { UpdateInvoiceTemplateView } from '@/views/templates/UpdateInvoiceTemplateView'
import { CreateInvoiceTemplateView } from '@/views/templates/CreateInvoiceTemplateView'
import { createFilterBuilder } from '@/utils/search'
import { DEFAULT_ARCHIVED_FILTER_VALUE, TemplatesListFilter } from '@/views/templates/TemplatesListFilter'

const GlobalLabel = styled.span`
  float: right;
  margin-right: 1em;
  color: #999;
  font-weight: bold;
  font-size: 0.8em;
`

const ReminderTemplateItem: FC<TemplateWithId> = template => (
  <ItemLink to={usePathWithParams(`/templates/reminders/${template.id}`)}>
    <List.Item.Meta
      title={
        <>
          {template.name}
          {!template.client_id ? <GlobalLabel>GLOBAL</GlobalLabel> : null}
        </>
      }
      description={`${templateTypeNames[template.type as TemplateType]}, ${templateLanguageNames[template.language as TemplateLanguage]}`}
    />
  </ItemLink>
)

const filterBuilder = createFilterBuilder<SearchTemplatesVariables>(
  {
    query: value => ({ name: { _ilike: `%${value}%` } }),
    archived: value => ({
      archived: {
        _eq: value === 'yes'
      }
    })
  },
  {
    archived: [DEFAULT_ARCHIVED_FILTER_VALUE]
  }
)

const RemindersTemplatesList: FC<{ selectedItemId?: number }> = props => {
  const currentClient = useCurrentClient()
  const searchBag = useSearchQuery<TemplateWithId, SearchTemplatesVariables, SearchTemplates>({
    query: SearchRemindersTemplatesQuery,
    extraVariables: useCurrentClientFilterVariables('client_id', true),
    buildFilterVariables: filterBuilder,
    defaultSort: SortByName
  })

  const newTemplateUrl = usePathWithParams('/templates/reminders/new')
  return (
    <InfiniteList
      searchBag={searchBag}
      key={`reminders-ilist-${currentClient.id}`}
      selectedItemId={props.selectedItemId}
      renderItem={(template: TemplateWithId) => <ReminderTemplateItem {...template} />}
      filtersComponent={TemplatesListFilter}
      footerComponent={() => <InfListAddButton label='New template' to={newTemplateUrl} />}
    />
  )
}

const InvoicesTemplateItem: FC<InvoiceTemplateWithId> = template => (
  <ItemLink to={usePathWithParams(`/templates/invoices/${template.id}`)}>
    <List.Item.Meta title={<>{template.name}</>} description={`${templateLanguageNames[template.language as TemplateLanguage]}`} />
  </ItemLink>
)

const InvoicesTemplatesList: FC<{ selectedItemId?: number }> = props => {
  const currentClient = useCurrentClient()

  const searchBag = useSearchQuery<InvoiceTemplateWithId, SearchInvoiceTemplatesVariables, SearchInvoiceTemplates>({
    query: SearchInvoiceTemplatesQuery,
    extraVariables: useCurrentClientFilterVariables('client_id', true),
    buildFilterVariables: filterBuilder,
    defaultSort: SortByName
  })

  const newTemplateUrl = usePathWithParams('/templates/invoices/new')

  return (
    <InfiniteList
      searchBag={searchBag}
      key={`invoices-ilist-${currentClient.id}`}
      selectedItemId={props.selectedItemId}
      renderItem={(template: InvoiceTemplateWithId) => <InvoicesTemplateItem {...template} />}
      filtersComponent={TemplatesListFilter}
      footerComponent={() => <InfListAddButton label='New template' to={newTemplateUrl} />}
    />
  )
}

const DebtRecoveryTemplateItem: FC<TemplateWithId> = template => (
  <ItemLink to={usePathWithParams(`/templates/debt-recoveries/${template.id}`)}>
    <List.Item.Meta
      title={
        <>
          {template.name}
          {!template.client_id ? <GlobalLabel>GLOBAL</GlobalLabel> : null}
        </>
      }
      description={`${templateTypeNames[template.type as TemplateType]}, ${templateLanguageNames[template.language as TemplateLanguage]}`}
    />
  </ItemLink>
)

const DebtRecoveryTemplatesList: FC<{ selectedItemId?: number }> = props => {
  const currentClient = useCurrentClient()
  const searchBag = useSearchQuery<TemplateWithId, SearchTemplatesVariables, SearchTemplates>({
    query: SearchRemindersTemplatesQuery,
    extraVariables: useCurrentClientFilterVariables('client_id', true),
    buildFilterVariables: filterBuilder,
    defaultSort: SortByName
  })

  const newTemplateUrl = usePathWithParams('/templates/debt-recoveries/new')
  return (
    <InfiniteList
      searchBag={searchBag}
      key={`debt-recoveries-ilist-${currentClient.id}`}
      selectedItemId={props.selectedItemId}
      renderItem={(template: TemplateWithId) => <DebtRecoveryTemplateItem {...template} />}
      filtersComponent={TemplatesListFilter}
      footerComponent={() => <InfListAddButton label='New template' to={newTemplateUrl} />}
    />
  )
}

const TemplatesView: FC<{ path: string; listView: React.ComponentType<{ selectedItemId?: number }> }> = props => (
  <L.Wrapper>
    <L.Side width={300}>
      <Routes>
        <Route path={'/:id?'} element={<props.listView />} />
      </Routes>
    </L.Side>
    <L.Content>
      <Routes>
        <Route path={'/'} element={props.path == 'invoices' ? <InvoicesTemplatesIndexView /> : <RemindersTemplatesIndexView />} />
        <Route path={'/new'} element={props.path == 'invoices' ? <CreateInvoiceTemplateView /> : <CreateReminderTemplateView />} />
        <Route path={'/:id'} element={props.path == 'invoices' ? <UpdateInvoiceTemplateView /> : <UpdateReminderTemplateView />} />
      </Routes>
    </L.Content>
  </L.Wrapper>
)

export const RemindersTemplatesView: FC = () => <TemplatesView path={'reminders'} listView={RemindersTemplatesList} />
export const InvoicesTemplatesView: FC = () => <TemplatesView path={'invoices'} listView={InvoicesTemplatesList} />
export const DebtRecoveryTemplatesView: FC = () => <TemplatesView path={'debt-recoveries'} listView={DebtRecoveryTemplatesList} />
