export const active = '#1890ff'

export const backgroundColor = '#f0f2f5'

export const formWidth = '400px'

export const softBorder = '1px solid #e8e8e8'

export const hoverBackground = '#e6f7ff'

export const fontColor = '#333333'
