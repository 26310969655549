import React, { FC, useMemo } from 'react'
import { FilterComponentProps } from '@/components/infinite_list/InfiniteList'
import Container from '@/components/layouts/Container'
import Input from 'antd/lib/input'
import { ReminderType, reminderTypeNames, ReminderStatus, reminderStatusNames } from '@/models/reminders'
import { Dropdown } from '@/components/Dropdown'
import styled from 'styled-components'
import { fontColor } from '@/style-vars'
import { takeFirst } from '@/utils/array'
import { Link } from 'react-router-dom'
import { WhenFilter } from '@/components/filters/WhenFilter'

const DropdownsBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1em;

  .dropdown {
    margin-right: 1em;

    .ant-dropdown-link {
      font-weight: bold;
      color: ${fontColor};
    }
  }
`

const LinksBar = styled.div`
  display: flex;
  font-size: 80%;

  span {
    color: #bfbfbf;
    margin: 0 4px;
  }
`

const DEFAULT = '__default__'

export const ReminderListFilter: FC<FilterComponentProps> = props => {
  const { filters, setFilters } = props

  const typeOptions = useMemo(
    () => [
      ...Object.values(ReminderType).map(rtype => ({
        value: rtype,
        label: reminderTypeNames[rtype]
      })),
      {
        label: 'All',
        value: DEFAULT
      }
    ],
    []
  )

  const statusOptions = useMemo(
    () => [
      ...Object.values(ReminderStatus).map(rstatus => ({
        value: rstatus,
        label: reminderStatusNames[rstatus]
      })),
      {
        label: 'All',
        value: DEFAULT
      }
    ],
    []
  )

  const contactsOptions = useMemo(
    () => [
      {
        value: 'yes',
        label: 'Yes'
      },
      {
        value: 'no',
        label: 'No'
      },
      {
        label: 'All',
        value: DEFAULT
      }
    ],
    []
  )

  return (
    <Container>
      <Input.Search
        value={takeFirst(filters.query) || ''}
        placeholder='Debtor name'
        onChange={e => setFilters({ query: [e.target.value] })}
      />
      <DropdownsBar>
        <Dropdown
          label='Type'
          options={typeOptions}
          value={takeFirst(filters.type) || DEFAULT}
          onChange={value => setFilters({ ...filters, type: value === DEFAULT ? null : [value] })}
        />
        <Dropdown
          label='Status'
          options={statusOptions}
          value={takeFirst(filters.status) || DEFAULT}
          onChange={value => setFilters({ ...filters, status: value === DEFAULT ? null : [value] })}
        />
        <Dropdown
          label='Has contacts'
          options={contactsOptions}
          value={takeFirst(filters.has_contacts) || DEFAULT}
          onChange={value => setFilters({ ...filters, has_contacts: value === DEFAULT ? null : [value] })}
        />
        <WhenFilter
          value={takeFirst(filters.when) || null}
          onChange={value => setFilters({ ...filters, when: value === null ? null : [value] })}
        />
      </DropdownsBar>
      <LinksBar>
        Quick filters: &nbsp;
        <Link to='/reminders?f_status=new'>all new</Link>
        <span>|</span>
        <Link to='/reminders?f_has_contacts=no&f_status=new'>missing contacts</Link>
        <span>|</span>
        <Link to='/reminders'>all</Link>
      </LinksBar>
    </Container>
  )
}
