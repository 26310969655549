export enum UserRole {
  manager = 'manager',
  guest = 'guest'
}

export const UserRoleNames: { [key in UserRole]: string } = {
  [UserRole.manager]: 'Manager',
  [UserRole.guest]: 'Guest'
}

export const userRoles = Object.values(UserRole)

export const fullName = (user: { first_name: string, last_name: string } | null) => {
  return user ? `${user.first_name} ${user.last_name}` : `[unknown user]`
}

export const initials = (user: { first_name: string, last_name: string } | null) => {
  return user ? user.first_name[0] + user.last_name[0] : '??'
}