import React, { FC } from 'react'
import * as R from 'ramda'
import CenterAlert from '@/components/CenterAlert'
import Fetch from '@/components/Fetch'
import Container from '@/components/layouts/Container'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { UpdateInvoiceTemplateForm } from '@/views/templates/components/TemplateInvoiceForm'
import { GetInvoiceTemplate, GetInvoiceTemplateVariables } from '@/queries/_gen_/GetInvoiceTemplate'
import { GetInvoiceTemplateQuery } from '@/queries/invoicesTemplates.queries'
import { useParams } from 'react-router-dom'

export const UpdateInvoiceTemplateView: FC = () => {
  const client = useCurrentClient()
  const id = useParams().id
  const templateId = parseInt(id || '0', 10)

  return (
    <Fetch<GetInvoiceTemplate, GetInvoiceTemplateVariables>
      key={`template-update-${templateId}`}
      query={GetInvoiceTemplateQuery}
      container={true}
      variables={{ id: templateId }}
      fetchPolicy='network-only'
    >
      {data => {
        if (data.template) {
          const template = R.omit(['__typename'], data.template)
          return (
            <HeaderWithContent header={template.name}>
              <Container>
                <UpdateInvoiceTemplateForm key={template.id} client={client} template={template} />
              </Container>
            </HeaderWithContent>
          )
        }
        return <CenterAlert type='error' message='Template not found :(' />
      }}
    </Fetch>
  )
}
