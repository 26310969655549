import React, { FC } from 'react'
import styled from 'styled-components'

interface Props {
  absolutePos?: boolean
  className?: string
}

const Loader: FC<Props> = (props: Props) => (
  <div className={`la-line-scale la-2x ${props.className || ''}`}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default styled(Loader)`
  ${(props: Props) => (props.absolutePos ? 'position: absolute;' : '')}
`
