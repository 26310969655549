import React, { FC, useMemo, useCallback, useState } from 'react'
import { FEventFields } from '@/queries/_gen_/FEventFields'
import { EventItemWrapper } from './EventItemWrapper'
import { EventSubject } from './EventSubject'
import styled from 'styled-components'
import { DeleteEventMutation, GetEventsQuery } from '@/queries/events.queries'
import { useMutationWithErrorReporting } from '@/hooks/useMutationWithErrorReporting'
import { GetEventsVariables, GetEvents } from '@/queries/_gen_/GetEvents'
import message from 'antd/lib/message'
import { UpdateCommentForm } from './UpdateCommentForm'
import { fullName } from 'common/models/user'

const CommentContent = styled.p`
  margin: 0;
`

export const CommentItem: FC<{ event: FEventFields; variables: GetEventsVariables }> = ({ event, variables }) => {
  const [deleteEventMutation, deleteEventResult] = useMutationWithErrorReporting(DeleteEventMutation)
  const [editing, setEditing] = useState(false)

  const deleteEvent = useCallback(() => {
    if (!deleteEventResult.loading && confirm('Are you sure you want to delete this comment?')) {
      deleteEventMutation({
        variables: {
          event_id: event.id
        },
        update: cache => {
          if (variables) {
            const query = cache.readQuery<GetEvents, GetEventsVariables>({ query: GetEventsQuery, variables })
            if (query) {
              cache.writeQuery<GetEvents, GetEventsVariables>({
                query: GetEventsQuery,
                variables,
                data: {
                  ...query,
                  items: query.items.filter(item => item.id !== event.id)
                }
              })
            }
          }
        }
      }).then(() => message.success('Comment deleted!'))
    }
  }, [event, deleteEventResult, variables, deleteEventMutation])

  const actions = useMemo(
    () =>
      editing
        ? undefined
        : [
            <span key='edit' onClick={() => setEditing(true)}>
              edit
            </span>,
            <span key='delete' onClick={deleteEvent}>
              delete
            </span>
          ],
    [editing, deleteEvent]
  )

  return (
    <EventItemWrapper
      actions={actions}
      event={event}
      author={
        <>
          <span>{fullName(event.creator)}</span> comment on <EventSubject event={event} />
        </>
      }
    >
      {!editing && <CommentContent>{event.comment?.comment}</CommentContent>}
      {editing && event.comment && (
        <UpdateCommentForm comment={event.comment} onSaved={() => setEditing(false)} onCancel={() => setEditing(false)} />
      )}
    </EventItemWrapper>
  )
}
