import React, { useState, useEffect } from 'react'
import Spin, { SpinProps } from 'antd/lib/spin'
import styled from 'styled-components'
import { loaderDelayMilliseconds } from '@/constants'

const StyledSpin = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
`

export default (props: SpinProps & { noDelay?: boolean }) => {
  const { noDelay, ...spinnerProps } = props
  const [show, setShow] = useState(!!noDelay)

  useEffect(() => {
    let unloaded = false
    setTimeout(() => {
      if (!unloaded) {
        setShow(true)
      }
    }, loaderDelayMilliseconds)
    return () => {
      unloaded = true
    }
  }, [setShow])

  return show ? <StyledSpin {...spinnerProps} /> : null
}
