import React, { useMemo, FC } from 'react'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { ItemLink, InfiniteList } from '@/components/infinite_list/InfiniteList'
import { CategoryWithId } from '@/models/category'
import { SearchCategories, SearchCategoriesVariables } from '@/queries/_gen_/SearchCategories'
import { SearchCategoriesQuery } from '@/queries/categories.queries'
import List from 'antd/lib/list'
import styled from 'styled-components'
import { SideWithContent as L } from '@/components/layouts/CustomLayouts'
import { Navigate, Route, Routes } from 'react-router'
import { useParams } from 'react-router-dom'
import CategoriesIndexView from './CategoriesIndexView'
import { CreateCategoryView } from './CreateCategoryView'
import { UpdateCategoryView } from './UpdateCategoryView'
import { SortByName, useQueryFilterBuilder } from '@/hooks/searchHelperHooks'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { ClientWithId } from '@/models/client'
import { InfListQueryFilter } from '@/components/infinite_list/InfListQueryFilter'
import { InfListAddButton } from '@/components/infinite_list/InfListAddButton'
import { useSearchQuery } from '@/hooks/useSearchQuery'
import { stages, getStage } from '@/models/stage'

const GlobalLabel = styled.span`
  float: right;
  margin-right: 1em;
  color: #999;
  font-weight: bold;
  font-size: 0.8em;
`

const DefaultLabel = styled.span`
  float: right;
  margin-right: 1em;
  color: green;
  font-weight: bold;
  font-size: 0.8em;
`

const CategoryListItem: FC<{ category: CategoryWithId; currentClient: ClientWithId }> = ({ category, currentClient }) => (
  <ItemLink to={usePathWithParams(`/categories/${category.stage}/${category.id}`)}>
    <List.Item.Meta
      title={
        <>
          {category.name}
          {currentClient.category_id === category.id ? <DefaultLabel>DEFAULT</DefaultLabel> : null}
          {!category.client_id ? <GlobalLabel>GLOBAL</GlobalLabel> : null}
        </>
      }
    />
  </ItemLink>
)

const CategoryList: FC = () => {
  const _id = useParams().id!
  const selectedItemId = !_id || _id === 'new' ? undefined : _id
  const stage = getStage(useParams().stage)
  const currentClient = useCurrentClient()
  const extraVariables: Partial<SearchCategoriesVariables> = useMemo(
    () => ({
      where: {
        _or: [{ client_id: { _eq: currentClient.id } }, { client_id: { _is_null: true } }],
        ...(stage !== stages.all
          ? {
              stage: {
                _eq: `${stage}`
              }
            }
          : {})
      }
    }),
    [currentClient, stage]
  )

  const searchBag = useSearchQuery<CategoryWithId, SearchCategoriesVariables, SearchCategories>({
    query: SearchCategoriesQuery,
    extraVariables,
    defaultSort: SortByName,
    buildFilterVariables: useQueryFilterBuilder()
  })

  const newCategoryUrl = usePathWithParams(`/categories/${stage}/new`)

  return (
    <InfiniteList
      searchBag={searchBag}
      key={`debtor-ilist-${currentClient.id}`}
      selectedItemId={selectedItemId}
      renderItem={category => <CategoryListItem category={category} currentClient={currentClient} />}
      filtersComponent={InfListQueryFilter}
      footerComponent={() => <InfListAddButton label='New category' to={newCategoryUrl} />}
    />
  )
}

// @TODO make this a generic component
export const CategoriesView: FC = () => (
  <L.Wrapper>
    <L.Side width={300}>
      <Routes>
        <Route path={'/:id?'} element={<CategoryList />} />
      </Routes>
    </L.Side>
    <L.Content>
      <Routes>
        <Route path='/' element={<CategoriesIndexView />} />
        <Route path='/new' element={<CreateCategoryView />} />
        <Route path='/:id' element={<UpdateCategoryView />} />
        <Route path='*' element={<Navigate to='/categories' />} />
      </Routes>
    </L.Content>
  </L.Wrapper>
)
