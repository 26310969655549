export const DEFAULT_ID_LENGTH = 6

export function makeId(length?: number): string {
  let result             = ''
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for ( let i = 0; i < (length || DEFAULT_ID_LENGTH); i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}