import React, { FC } from 'react'
import styled from 'styled-components'
import { backgroundColor } from '@/style-vars'
import { RoutedTabPane, RoutedTabs } from '@/components/RoutedTabs'
import { HeaderWithContent } from './HeaderWithContent'

const Tabs = styled(RoutedTabs)`
  .ant-tabs-nav-container {
    background-color: ${backgroundColor};
  }

  .ant-tabs-bar {
    border-color: white;
  }

  .ant-tabs-tab {
    border-color: white !important;
  }
`
interface Props {
  header: string
  tabs: RoutedTabPane[]
  pathRoot: string
  fitToContainer?: boolean
}

export const HeaderWithTabs: FC<Props> = props => (
  <HeaderWithContent header={props.header} fitToContainer={props.fitToContainer}>
    <Tabs pathRoot={props.pathRoot} type='card' animated={false} panes={props.tabs} fitToContainer={props.fitToContainer} />
  </HeaderWithContent>
)
