import ApolloClient from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { split, Operation } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { OperationDefinitionNode } from 'graphql'
import { logger } from './logger'
import { makeId } from 'common/utils/id'
import { makeRequestHeaders, sessionId } from '@/context'
import { isUnauthorizedError } from './utils/apollo'
import { tracer } from './agent'
import { Context, context } from '@opentelemetry/api'

// @TODO this is where we will want to inject fake auth for tests

const fetchWithTrace: typeof fetch = (input, init) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headers: any = init?.headers
  if (headers && headers.traceContext) {
    const { operation, context }: { operation: Operation; context: Context } = headers.traceContext
    delete headers.traceContext
    return tracer.startActiveSpan(
      `graphql - ${operation.operationName}`,
      {
        attributes: {
          'operation.name': operation.operationName
        }
      },
      context,
      span =>
        fetch(input, init)
          .then(res => {
            span.end()
            return res
          })
          .catch(e => {
            span.end()
            return Promise.reject(e)
          })
    )
  }
  return fetch(input, init)
}

export const initApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  logger.debug('initializing apollo client...')

  const withHttpContext = setContext((operation, { headers }) => {
    const requestId = makeId()

    logger.info(`making graphql req, requestId ${requestId}, operation ${operation.operationName}`, { request_id: requestId })

    const extraHeaders: Record<string, string> = {
      ...makeRequestHeaders(requestId)
    }

    return {
      headers: {
        ...(headers || {}),
        ...extraHeaders,
        ['x-request-id']: requestId,
        ['x-hasura-session-id']: sessionId,
        traceContext: {
          context: context.active(),
          operation
        }
      }
    }
  })

  const withHttpError = onError(error => {
    if (isUnauthorizedError(error)) {
      logger.info('got 401')
      if (error.operation.operationName !== 'GetMe') {
        logger.info('reloading page because of unexpected 401') // hope this doesn't loop somehow
        // @TODO say something to user?
        window.location.reload()
      }
    } else {
      if (error.networkError) {
        logger.error('graphql network error: ', error.networkError)
      } else if (error.graphQLErrors && error.graphQLErrors.length) {
        logger.error('graphql error', error.graphQLErrors[0])
      }
    }
  })

  const httpLink = createHttpLink({
    uri: __CONFIG__.graphqlUri,
    credentials: 'include',
    fetch: fetchWithTrace
  })

  const wsLink = new WebSocketLink({
    uri: __CONFIG__.graphqlWsUri,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: () => {
        logger.info('getting ws connection params...')
        return {
          headers: {}
        }
      }
    }
  })

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    withHttpContext.concat(withHttpError).concat(httpLink)
  )

  const cache = new InMemoryCache({
    dataIdFromObject: object => (object.__typename && object.id ? `${object.__typename}:${object.id}` : undefined),
    cacheRedirects: {
      Query: {
        debtor_by_pk: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'debtor', id: args.id }),
        client_by_pk: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'client', id: args.id }),
        category_by_pk: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'category', id: args.id }),
        reminder_by_pk: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'reminder', id: args.id })
      }
    }
  })

  return new ApolloClient({
    link,
    cache
  })
}
