import React, { FC } from 'react'
import Container from '@/components/layouts/Container'
import { EventsHistory } from '@/components/history/EventsHistory'
import { DebtorWithId } from '@/models/debtor'

export interface DebtorEventsTabProps {
  debtor: DebtorWithId
}

export const DebtorEventsTab: FC<DebtorEventsTabProps> = ({ debtor }) => (
  <Container>
    <EventsHistory debtorId={debtor.id} />
  </Container>
)
