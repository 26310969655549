import tinymce, { Editor } from 'tinymce'
import { uploadFile } from '@/utils/fetch'
import { Folder } from 'common/models/files'
import { currentClientDANGER } from '@/context'
import { getContentWithMargins } from './MarginsPlugin'

export const INVOICE_PDF_PREVIEW_PLUGIN_ID = 'invoicepdfpreview'

function InvoicePDFPreviewPlugin(editor: Editor) {
  const preview = () => {
    const content: string = getContentWithMargins(editor)
    uploadFile(Folder.preview, 0, new Blob([content], { type: 'text/html' }), 'preview.html').then(res => {
      window.open(`${__CONFIG__.backendUri}/invoice/preview/${currentClientDANGER.id}/${res.filename.replace('.html', '.pdf')}`, '_blank')
    })
  }

  editor.ui.registry.addButton(INVOICE_PDF_PREVIEW_PLUGIN_ID, {
    text: 'Preview PDF',
    onAction() {
      preview()
    }
  })
}

tinymce.PluginManager.add(INVOICE_PDF_PREVIEW_PLUGIN_ID, InvoicePDFPreviewPlugin)
