import React, { ReactNode } from 'react'
import Card from 'antd/lib/card'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  width: 300px;

  .anticon {
    margin-right: 1em;
  }
`

interface Props {
  children: ReactNode
  className?: string
  title: ReactNode
  icon?: JSX.Element
  to: string
}

export default ({ children, title, icon, to, className }: Props) => {
  const titleElement = (
    <>
      {icon}
      {title}
    </>
  )

  return (
    <Link to={to} className={className}>
      <StyledCard title={titleElement}>{children}</StyledCard>
    </Link>
  )
}
