import React, { useCallback } from 'react'
import { UserWithPassword } from '@/models/user'
import ApolloClient from 'apollo-client'
import * as yup from 'yup'
import { userUniquenessValidator } from '../userValidators'
import { FC, useMemo } from 'react'
import { useApolloClient } from 'react-apollo'
import { Formik } from 'formik'
import Container from '@/components/layouts/Container'
import { Form } from '@/components/form/Form'
import { FormikField } from '@/components/form/FormikField'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { FormMutation } from '@/components/form/FormMutation'
import { UpdateProfileMutation } from '@/queries/users.queries'
import * as R from 'ramda'
import { passwordValidator } from './UserGeneralForm'
import { UpdateProfileVariables, UpdateProfile } from '@/queries/_gen_/UpdateProfile'
import { useProfile } from '@/hooks/useProfile'
import { GetMe_me_me_user } from '@/queries/_gen_/GetMe'

interface UserFormProps {
  user: UserWithPassword
  onSubmit: (values: UserWithPassword) => void
  loading: boolean
  btnLabel: string
}

const validationSchema = (apollo: ApolloClient<any>, userId?: string) =>
  yup.object().shape({
    first_name: yup.string().required('Required!'),
    last_name: yup.string().required('Required!'),
    email: yup
      .string()
      .email('Not a valid email.')
      .required('Required!')
      .test('email_unique', 'Another user with this email already exists.', userUniquenessValidator(apollo, 'email', userId)),
    password: passwordValidator
  })

const UserForm: FC<UserFormProps> = props => {
  const { user, loading, onSubmit, btnLabel } = props
  const apollo = useApolloClient()
  const vSchema = useMemo(() => validationSchema(apollo, user.id), [apollo, user.id])

  return (
    <Container $fitToContainer={true} $scrollable={true}>
      <Formik<UserWithPassword> initialValues={user} onSubmit={onSubmit} validationSchema={vSchema} enableReinitialize={true}>
        {({ dirty }) => (
          <Form className='ant-form ant-form-vertical'>
            <FormikField name='first_name' label='First name' />
            <FormikField name='last_name' label='Last name' />
            <FormikField name='email' label='Email' />
            <FormikField
              name='password'
              label='Password'
              type='password'
              disableAutocomplete={true}
              description='Leave blank to keep current password.'
            />
            <br />
            <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
              {btnLabel}
            </FormSubmitButton>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export const UserProfileForm: FC = () => {
  const user = useProfile() as GetMe_me_me_user

  const buildVariables = useCallback(
    (values: UserWithPassword): UpdateProfileVariables => {
      const { password, ...rest } = R.pick(['first_name', 'last_name', 'email', 'password'], values)

      return {
        payload: {
          ...rest,
          ...(password ? { password } : {})
        },
        id: user.id
      }
    },
    [user]
  )

  const vals = useMemo(
    () => ({
      ...user,
      password: ''
    }),
    [user]
  )

  return (
    <FormMutation<UserWithPassword, UpdateProfile, UpdateProfileVariables>
      buildVariables={buildVariables}
      successMessage={data => (data.result ? 'Profile updated.' : '???')}
      mutation={UpdateProfileMutation}
    >
      {({ onSubmit, result: { loading } }) => <UserForm user={vals} onSubmit={onSubmit} loading={loading} btnLabel='Update profile' />}
    </FormMutation>
  )
}
