import { PaymentRow } from './useParsePaymentsCSV'
import { useApolloClient } from 'react-apollo'
import { useCurrentClient } from '../useCurrentClient'
import { useCallback, useState } from 'react'
import { usePromise, UsePromiseValue } from '../usePromise'
import { uploadFile } from '@/utils/fetch'
import { logger } from '@/logger'
import { Folder } from 'common/models/files'
import { InsertImportFile, InsertImportFileVariables } from '@/queries/_gen_/InsertImportFile'
import { InsertImportFileMutation } from '@/queries/import.queries'
import { InsertPayments, InsertPaymentsVariables } from '@/queries/_gen_/InsertPayments'
import { INSERT_PAYMENTS_MUTATION } from '@/queries/invoicePayments.queries'
import { payment_insert_input } from '@/queries/_gen_global'

type ImportPaymentsFn = (payments: PaymentRow[], file: File) => void
type ReturnValue = { importFileId: number }

export function usePaymentsImport(): [ImportPaymentsFn, UsePromiseValue<ReturnValue, Error>] {
  const apolloClient = useApolloClient()
  const client = useCurrentClient()
  const [promise, setPromise] = useState<Promise<ReturnValue> | null>(null)

  const importfn: ImportPaymentsFn = useCallback(
    (payments: PaymentRow[], file: File): void => {
      logger.info(`importing ${payments.length} payments...`)
      const prom: Promise<ReturnValue> = uploadFile(Folder.importfiles, client.id, file, file.name)
        .then(uploadResult => {
          logger.info('import file uploaded, inserting import to db...')
          return apolloClient.mutate<InsertImportFile, InsertImportFileVariables>({
            mutation: InsertImportFileMutation,
            variables: {
              bucket_filename: uploadResult.filename,
              orig_filename: file.name,
              import_options: {},
              client_id: client.id,
              raw_data: payments,
              import_type: 'PAYMENTS'
            }
          })
        })
        .then(impFileRes => {
          const importFileId = impFileRes.data?.insert_import_file?.returning[0].id
          if (importFileId) {
            return apolloClient
              .mutate<InsertPayments, InsertPaymentsVariables>({
                mutation: INSERT_PAYMENTS_MUTATION,
                variables: {
                  input: payments.map(
                    (rawPayment: PaymentRow): payment_insert_input => ({
                      amount: rawPayment.amount,
                      client_id: client.id,
                      currency: rawPayment.currency,
                      iban: rawPayment.iban,
                      payment_date: rawPayment.date,
                      reason: rawPayment.reason,
                      payer_name: rawPayment.from,
                      import_file_id: importFileId
                    })
                  )
                }
              })
              .then(() => {
                logger.info('payment import successful!')
                return {
                  importFileId
                }
              })
          }
          throw new Error(`Failed to upload payments import file ${impFileRes.errors}`)
        })

      setPromise(prom)
    },
    [client, apolloClient]
  )

  return [importfn, usePromise(promise)]
}
