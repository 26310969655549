import React, { FC, useCallback } from 'react'
import {
  SearchInvoiceTemplates_items,
  SearchInvoiceTemplatesVariables,
  SearchInvoiceTemplates
} from '@/queries/_gen_/SearchInvoiceTemplates'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { GraphqlSelect } from './GraphqSelect'
import { SearchInvoiceTemplatesQuery } from '@/queries/invoicesTemplates.queries'
import { order_by } from '@/queries/_gen_global'
import { templateLanguageNames, TemplateLanguage } from '@/models/templates'

interface InvoiceTemplateSelectProps {
  value?: number
  onChange: (value: SearchInvoiceTemplates_items | null) => void
}

export const InvoiceTemplateSelect: FC<InvoiceTemplateSelectProps> = props => {
  const { value, onChange } = props

  const client = useCurrentClient()

  const buildVars = useCallback(
    (query: string): SearchInvoiceTemplatesVariables['where'] => ({
      client_id: { _eq: client.id },
      archived: { _eq: false },
      ...(query ? { name: { _ilike: `%${query}%` } } : {})
    }),
    [client]
  )

  return (
    <GraphqlSelect<SearchInvoiceTemplates_items, SearchInvoiceTemplates, SearchInvoiceTemplatesVariables>
      query={SearchInvoiceTemplatesQuery}
      renderLabel={t => `${t.name} (${templateLanguageNames[t.language as TemplateLanguage]})`}
      buildSearchVariables={buildVars}
      valueId={value}
      onChange={onChange}
      orderBy={[{ name: order_by.asc }]}
    />
  )
}
