import gql from 'graphql-tag'

export const InvoiceTemplateFieldsFragment = gql`
  fragment FInvoiceTemplateFields on invoice_template {
    client_id
    id
    language
    name
    content
    archived
  }
`

export const InvoiceTemplateOptionFieldsFragment = gql`
  fragment FInvoiceTemplateOptionFields on invoice_template {
    id
    name
    language
  }
`

export const SearchInvoiceTemplatesQuery = gql`
  query SearchInvoiceTemplates($limit: Int!, $offset: Int!, $where: invoice_template_bool_exp!, $order_by: [invoice_template_order_by!]) {
    items: invoice_template(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
      ...FInvoiceTemplateFields
    }
    total: invoice_template_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${InvoiceTemplateFieldsFragment}
`

export const GetTemplateOptionsQuery = gql`
  query GetInvoiceTemplateOptions($where: invoice_template_bool_exp!) {
    results: invoice_template(where: $where) {
      ...FInvoiceTemplateOptionFields
    }
  }
  ${InvoiceTemplateOptionFieldsFragment}
`

export const InsertInvoiceTemplateMutation = gql`
  mutation InsertInvoiceTemplate($payload: invoice_template_insert_input!) {
    result: insert_invoice_template(objects: [$payload]) {
      returning {
        ...FInvoiceTemplateFields
      }
    }
  }

  ${InvoiceTemplateFieldsFragment}
`

export const UpdateInvoiceTemplateMutation = gql`
  mutation UpdateInvoiceTemplate($id: bigint!, $payload: invoice_template_set_input!) {
    result: update_invoice_template(_set: $payload, where: { id: { _eq: $id } }) {
      returning {
        ...FInvoiceTemplateFields
      }
    }
  }

  ${InvoiceTemplateFieldsFragment}
`

export const GetInvoiceTemplateQuery = gql`
  query GetInvoiceTemplate($id: bigint!) {
    template: invoice_template_by_pk(id: $id) {
      ...FInvoiceTemplateFields
    }
  }
  ${InvoiceTemplateFieldsFragment}
`
