import React, { ReactNode, FC, useState, useCallback, useMemo, useEffect } from 'react'
import { SearchClients, SearchClientsVariables, SearchClients_items } from '@/queries/_gen_/SearchClients'
import { SearchClientsQuery } from '@/queries/clients.queries'
import { useQuery } from 'react-apollo'
import Loader from '../Loader'
import Container from '../layouts/Container'
import Alert from 'antd/lib/alert'
import { logger } from '@/logger'
import { currentClientDANGER } from '@/context'
import { useNavigate } from 'react-router'
import { order_by } from '@/queries/_gen_global'

type ClientModel = SearchClients_items

export interface CurrentClientContext {
  client: ClientModel
  clients: ClientModel[]
  setClient: (client: ClientModel) => void
}

// eslint-disable-next-line prettier/prettier
export const CurrentClientContext: React.Context<CurrentClientContext> = React.createContext({} as CurrentClientContext)

interface CurrentClientProviderProps {
  children: ReactNode
}

const CURRENT_CLIENT_ID_STORAGE_KEY = 'current_client_id'

const initialClientId = parseInt(window.localStorage.getItem(CURRENT_CLIENT_ID_STORAGE_KEY) || '-1', 10) // well, why not?
logger.setGlobalContext('client_id', String(initialClientId))
currentClientDANGER.id = initialClientId

export const CurrentClientProvider: FC<CurrentClientProviderProps> = ({ children }) => {
  const [clientId, setClientId] = useState(initialClientId)

  const nav = useNavigate()

  const setClient = useCallback(
    (client: ClientModel) => {
      setClientId(client.id)
      logger.info(`setting current client to ${client.name} : ${client.id}`)
      logger.setGlobalContext('client_id', String(client.id))
      currentClientDANGER.id = client.id
      window.localStorage.setItem(CURRENT_CLIENT_ID_STORAGE_KEY, String(client.id))
      nav('/')
    },
    [setClientId, nav]
  )

  const { data, error, loading } = useQuery<SearchClients, SearchClientsVariables>(SearchClientsQuery, {
    variables: { limit: 10000, offset: 0, where: {}, order_by: [{ name: order_by.asc }] }
  })

  const client = useMemo(() => {
    if (data && data.items) {
      return data.items.find(c => c.id === clientId)
    }
    return null
  }, [data, clientId])

  useEffect(() => {
    if (data && data.items && data.items.length && !client) {
      const newClient = data.items[0]
      logger.info(`client ${clientId} not found, setting client ${newClient.name}: ${newClient.id}`)
      setClient(newClient)
    }
  }, [data, clientId, client, setClient])

  if (error) {
    return (
      <Container>
        <Alert type='error' message={error.message} />
      </Container>
    )
  } else if (!loading) {
    if (data && data.items) {
      if (!data.items.length) {
        return (
          <Container>
            <Alert type='error' message='There are no clients assigned to you. What is going on! Tell your supervisor.' />
          </Container>
        )
      } else if (client) {
        return (
          <CurrentClientContext.Provider
            value={{
              client,
              clients: data.items,
              setClient
            }}
          >
            {children}
          </CurrentClientContext.Provider>
        )
      }
    }
  }
  return <Loader />
}
