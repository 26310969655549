import React, { FC } from 'react'
import Select, { SelectProps } from 'antd/lib/select'
import * as R from 'ramda'

const { Option } = Select

const formats = {
  enGB: 'English (UK)',
  enUS: 'English (US)',
  ru: 'Russian',
  eo: 'Esperanto',
  de: 'German',
  es: 'Spanish',
  nl: 'Dutch',
  nb: 'Norwegian',
  ca: 'Catalan',
  it: 'Italian',
  pl: 'Polish',
  pt: 'Portugese',
  sv: 'Swedish',
  fr: 'French',
  el: 'Greek',
  sk: 'Slovak',
  da: 'Danish',
  is: 'Icelandic',
  fi: 'Finnish',
  hr: 'Croatian',
  bg: 'Bulgarian',
  cs: 'Czech',
  mk: 'Makedonian',
  ro: 'Romanian',
  et: 'Estonian',
  lv: 'Latvian',
  lt: 'Lithuanian',
  hg: 'Hungarian',
  be: 'Belarussian',
  uk: 'Ukrainian'
}

const formatsSorted = R.sortBy(R.prop('label'))(Object.entries(formats).map(([format, label]) => ({ format, label })))

export const DateLocaleSelect: FC<SelectProps<string>> = ({ value, ...props }) => (
  <Select {...props} value={value || undefined} placeholder='Select a currency' showSearch={true}>
    {formatsSorted.map(({ format, label }) => (
      <Option key={format} value={format}>
        {label}
      </Option>
    ))}
  </Select>
)
