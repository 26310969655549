import gql from 'graphql-tag'

export const Fragments = {
  CreditorFields: gql`
    fragment FCreditorFields on creditor {
      id
      name
      category_id
    }
  `
}

export const SearchCreditorsQuery = gql`
  query SearchCreditors($limit: Int!, $offset: Int!, $where: creditor_bool_exp!, $order_by: [creditor_order_by!]) {
    items: creditor(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      ...FCreditorFields
    }
    total: creditor_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }

  ${Fragments.CreditorFields}
`
export const GetCreditorQuery = gql`
  query GetCreditor($id: Int!) {
    creditor: creditor_by_pk(id: $id) {
      ...FCreditorFields
    }
  }
  ${Fragments.CreditorFields}
`

export const CountCreditorsQuery = gql`
  query CountCreditors($where: creditor_bool_exp) {
    result: creditor_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const DeleteCreditorMutation = gql`
  mutation DeleteCreditor($id: Int) {
    delete_creditor(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`
export const InsertCreditorMutation = gql`
  mutation InsertCreditor($payload: creditor_insert_input!) {
    result: insert_creditor(objects: [$payload]) {
      returning {
        ...FCreditorFields
      }
    }
  }

  ${Fragments.CreditorFields}
`

export const UpdateCreditorMutation = gql`
  mutation UpdateCreditor($id: Int!, $payload: creditor_set_input!) {
    result: update_creditor(_set: $payload, where: { id: { _eq: $id } }) {
      returning {
        ...FCreditorFields
      }
    }
  }

  ${Fragments.CreditorFields}
`
