import React, { useState, useMemo } from 'react'
import Table, { TableProps } from 'antd/lib/table'
import styled from 'styled-components'
import { useElementDimensions } from '@/hooks/useElementDimensions'

const WrapperDiv = styled.div`
  height: 100%;

  .ant-table-column-sorters {
    white-space: nowrap;
  }
`

interface ScrollableContentTableProps<RecordType = Record<string, unknown>> extends TableProps<RecordType> {
  staticHeight?: number
}

export function ScrollableContentTable<RecordType = Record<string, unknown>>(props: ScrollableContentTableProps<RecordType>): JSX.Element {
  const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
  const { staticHeight, ...tableProps } = props

  const dims = useElementDimensions(wrapper)

  const scroll = useMemo(() => {
    if (dims && staticHeight) {
      return {
        y: dims.height - staticHeight
      }
    }
    return undefined
  }, [dims, staticHeight])

  return (
    <WrapperDiv ref={setWrapper}>
      <Table<any> {...tableProps} scroll={scroll} />
    </WrapperDiv>
  )
}
