export const REPORT_LANGUAGES = [
  { 
    id: 'en',
    label: 'English',
  },
  {
    id: 'lt',
    label: 'Lithuanian'
  }
]

export const REPORT_LANGUAGE_IDS = REPORT_LANGUAGES.map(l => l.id)